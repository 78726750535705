<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0697 2.93032C16.2307 2.09135 15.2801 1.43308 14.2662 0.954346C14.504 1.68827 14.6388 2.48911 14.682 3.33751C15.1131 3.50748 15.5232 3.77102 15.8761 4.12394C16.229 4.47681 16.4925 4.88691 16.6625 5.31799C17.5109 5.36118 18.3117 5.49601 19.0456 5.73384C18.567 4.71993 17.9086 3.76929 17.0697 2.93032ZM13.2704 0.548365C10.1707 -0.523199 6.65138 -0.0242501 3.94269 2.04478C6.81001 1.71987 9.52874 2.38519 11.7999 3.73956C12.3715 3.23671 13.1213 3.04342 13.8739 3.13201C13.8021 2.17432 13.6044 1.3012 13.2704 0.548365H13.2704ZM19.4516 6.72957C18.6987 6.39557 17.8257 6.19782 16.868 6.12601C16.9566 6.87868 16.7632 7.62844 16.2604 8.20006C17.6148 10.4712 18.2801 13.19 17.9552 16.0573C20.0243 13.3486 20.5232 9.82927 19.4516 6.72957ZM15.3252 4.67478C14.3714 3.72099 12.9906 3.64295 12.2341 4.39934C11.4777 5.15573 11.5557 6.53658 12.5095 7.49038C13.4634 8.44421 14.8441 8.52221 15.6006 7.76582C16.357 7.00947 16.279 5.62858 15.3252 4.67478ZM11.3073 4.33273C8.88748 2.92876 5.93568 2.35494 2.85152 3.01117C0.361624 5.55673 -0.497816 9.09621 0.273763 12.328C3.23019 9.49613 7.15397 7.27639 10.9281 6.13813C10.9449 6.13307 10.9617 6.12831 10.9785 6.12324C10.887 5.49488 10.9912 4.86418 11.3073 4.33268L11.3073 4.33273ZM15.6673 8.69269C15.1358 9.00882 14.5051 9.11296 13.8767 9.0215C13.8717 9.0383 13.8669 9.05509 13.8619 9.07188C12.7236 12.846 10.5039 16.7698 7.67196 19.7262C10.9038 20.4978 14.4434 19.6384 16.9888 17.1485C17.6451 14.0642 17.0712 11.1125 15.6673 8.69269ZM11.183 6.87522C11.173 6.87824 11.163 6.88097 11.153 6.884C7.37751 8.02261 3.39908 10.3106 0.517395 13.1787C0.993318 14.6002 1.79723 15.9367 2.93025 17.0697C4.06336 18.2029 5.39977 19.0067 6.82126 19.4827C9.6894 16.6009 11.9774 12.6224 13.116 8.84712C13.119 8.83704 13.1217 8.82704 13.1247 8.817C12.7039 8.64669 12.3039 8.38661 11.9586 8.04139C11.6134 7.69609 11.3533 7.29608 11.183 6.87526V6.87522Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
<style></style>
