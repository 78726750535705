<template>
  <div class="col-start-1 col-end-13 p-3 rounded-lg">
    <div class="flex flex-row items-center">
      <img class="h-10 w-10 rounded-full" :src="player" alt="userPic" />

      <div
        class="relative ml-3 text-sm bg-[#EAECF0] py-2 px-4 shadow rounded-xl rounded-tl-none break-words lg:max-w-[500px]"
      >
        <div>{{ message }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import player from "@/assets/images/player.png";

export default {
  props: ["message"],
  setup() {
    return {
      player,
    };
  },
};
</script>
<style></style>
