import { defineStore } from "pinia";
import axios from "axios";
export const useModeratorsStore = defineStore("moderatorsStore", {
  state: () => ({
    moderators: [],
    moderatorsCount: 0,
    itemsPerPage: 10,
    currentPage: 1,
    showAddModal: false,
    showDeleteModal: false,
    showEditModal: false,
    showUnPauseModal: false,
    showPauseModal: false,
    isLoadingModal: false,
    isFailModal: false,
    pauseError: false,
    isShowEditor: false,
    addError: false,
    addSuccess: false,
    editedModerator: null,
    isSuccess: false,
    isFail: false,
    newModerator: {
      first_name: "",
      last_name: "",
      email_moderator: "",
      password: "",
      phone: "",
      permissions: "",
      profile_pic: null,
    },
  }),

  actions: {
    async getModerators() {
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/moderators/${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.moderators = data.moderators.results;
        this.totalPages = data.moderators.totalPages;
        this.currentPage = data.moderators.currentPage;
        console.log(this.moderators);
      } catch (err) {
        console.log(err);
      }
    },
    async updateModerator(moderator) {
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.put(
          `${process.env.VUE_APP_API_BASE}admin/moderators/`,
          moderator,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.editedModerator = null;
        this.showEditModal = false;
        console.log(res);
      } catch (err) {
        this.isFail = true;
        this.isLoadingModal = false;
        console.log(err);
      }
    },
    async addModerator() {
      this.isFail = false;
      this.isLoadingModal = true;
      console.log(this.newModerator);
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.post(
          `${process.env.VUE_APP_API_BASE}admin/moderators/`,
          this.newModerator,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.showAddModal = false;
        await this.getModerators();
      } catch (err) {
        this.isFail = true;
        this.isLoadingModal = false;
        console.log(err);
      }
    },
    async pauseModerator(moderatorId) {
      this.isFail = false;
      this.isLoadingModal = true;
      console.log(moderatorId);
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.put(
          `${process.env.VUE_APP_API_BASE}admin/moderators/pause/`,
          { moderatorId: moderatorId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.showPauseModal = false;
        await this.getModerators();
      } catch (err) {
        this.isFail = true;
        this.showPauseModal = false;
        this.isLoadingModal = false;
        console.log(err);
      }
    },
    async unPauseModerator(moderatorId) {
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.put(
          `${process.env.VUE_APP_API_BASE}admin/moderators/unpause/`,
          { moderatorId: moderatorId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.showUnPauseModal = false;
        await this.getModerators();
      } catch (err) {
        this.isFail = true;
        this.showUnPauseModal = false;
        this.isLoadingModal = false;
        console.log(err);
      }
    },
    async deleteModerator(moderatorId) {
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.delete(
          `${process.env.VUE_APP_API_BASE}admin/moderators/${moderatorId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        this.showDeleteModal = false;
        await this.getModerators();
      } catch (err) {
        this.isFail = true;
        this.isLoadingModal = false;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
});
