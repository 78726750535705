<template>
  <div></div>
</template>
<script>
import router from "@/router";
import { usePlansStore } from "../../../store/plans";
export default {
  setup() {
    const plansStore = usePlansStore();
    const { isFail } = usePlansStore();
    const { isSuccess } = usePlansStore();
    return {
      plansStore,
      isFail,
      isSuccess,
    };
  },
  mounted() {
    if (this.plansStore.isSuccess) {
      this.$swal({
        icon: "success",
        title: "Abonnement modifié avec succés",
        showConfirmButton: false,
        timer: 1500,
        willClose: () => {
          this.plansStore.isFail = false;
          this.plansStore.isSuccess = false;
        },
      });
      setTimeout(() => {
        router.push("/admin/abonnement/");
      }, 1600);
    }
  },
};
</script>
