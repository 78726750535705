import { defineStore } from "pinia";
import axios from "axios";
export const usePlansStore = defineStore("plansStore", {
  state: () => ({
    isLoading: false,
    isFail: false,
    isSuccess: false,
    editedPlan: null,
    selectedPlan: "player",
    plans: [],
  }),

  actions: {
    async editPlan(plan) {
      this.error = null;
      this.isFail = false;
      this.isSuccess = false;
      this.isLoading = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/plans`,
          {
            planId: plan.planId,
            price: plan.price,
            description: plan.description,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isSuccess = true;
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isFail = true;
      }
    },
    async getPlans() {
      this.error = null;
      this.isFail = false;
      this.isSuccess = false;
      this.isLoading = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/plans?planRole=${this.selectedPlan}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.plans = response.data.plans;
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isFail = true;
      }
    },
    async setPlan(plan) {
      switch (plan) {
        case "player":
          this.selectedPlan = "player";
          await this.getPlans();
          break;
        case "agent":
          this.selectedPlan = "agent";
          await this.getPlans();
          break;
        case "other":
          this.selectedPlan = "other";
          await this.getPlans();
          break;
        default:
          this.selectedPlan = "player";
          await this.getPlans();
          break;
      }
    },
  },
});
