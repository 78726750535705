<template>
  <div></div>
</template>
<script>
import { usePlansStore } from "@/store/plans";
export default {
  setup() {
    const plansStore = usePlansStore();
    const { isError, isSuccess } = usePlansStore();
    return {
      plansStore,
      isError,
      isSuccess,
    };
  },
  mounted() {
    if (this.plansStore.isFail) {
      this.$swal({
        icon: "error",
        title: "quelque chose s'est mal passé",
        showConfirmButton: true,
        timer: 1500,
        willClose: () => {
          this.plansStore.isFail = false;
          this.plansStore.isSuccess = false;
        },
      });
    }
  },
};
</script>
