<template>
  <!-- Navbar -->
  <nav
    class="absolute top-0 left-0 w-full bg-white md:flex-row md:flex-nowrap md:justify-start flex items-center p-4"
    :class="$route.path == '/admin/dashboard' ? ' z-10' : ''"
  >
    <div
      class="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4"
    >
      <!-- Brand -->
      <form
        class="md:flex hidden flex-row flex-wrap items-center mr-3 xl:w-4/12"
      >
        <div
          class="relative flex w-full flex-wrap items-stretch border rounded-md focus:outline-none"
        >
          <span
            class="z-10 h-full leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-2 py-3"
          >
            <svg
              class="mr-2"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <ellipse
                cx="12.6014"
                cy="10.8849"
                rx="9.10146"
                ry="8.88476"
                stroke="#D9D9D9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9316 17.5258L22.4999 21.0001"
                stroke="#D9D9D9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <input
            type="text"
            placeholder="Chercher..."
            class="border-0 px-3 py-3 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
          />
        </div>
      </form>

      <!-- User -->
      <div class="flex items-center">
        <div>
          <img
            @click="$router.push('/admin/notifications')"
            :src="notifications"
            v-if="!isNewNotifications"
            alt="notifications"
            class="cursor-pointer"
          />
          <img
            @click="$router.push('/admin/notifications')"
            :src="notificationActive"
            v-if="isNewNotifications"
            alt="notifications"
            class="cursor-pointer"
          />
        </div>
        <div class="mx-6"><img :src="adminLogo" alt="adminLogo" /></div>
        <div class="mx-6" v-if="authStore?.user?.user?.profile_pic">
          <img
            class="rounded-full h-10 w-10"
            :src="config.apiBase + authStore?.user?.user?.profile_pic"
            alt="adminLogo"
          />
        </div>

        <div>
          <div class="font-semibold">
            {{
              authStore?.user?.user?.first_name
                ? authStore.user.user.first_name
                : "..."
            }}
          </div>
          <div class="text-black opacity-60">Administrateur</div>
        </div>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
import adminLogo from "@/assets/icons/navbar/adminLogo.svg";
import notifications from "@/assets/icons/navbar/notification.svg";
import notificationActive from "@/assets/icons/navbar/notificationActive.svg";
import { useAuthStore } from "@/store/auth";
import { useNotificationsStore } from "@/store/notifications";
import config from "../../../config";
export default {
  components: {},
  data() {
    return { config, isNewNotifications: false };
  },
  async mounted() {
    await this.notificationsStore.getNotifications();
    const isNewNotification = this.notificationsStore.notifications.filter(
      (notif) => {
        return !notif.is_read;
      }
    );

    if (isNewNotification.length > 0) {
      this.isNewNotifications = true;
    }
  },
  setup() {
    const notificationsStore = useNotificationsStore();
    const authStore = useAuthStore();
    return {
      authStore,
      notifications,
      adminLogo,
      notificationsStore,
      notificationActive,
    };
  },
};
</script>
