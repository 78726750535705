import { defineStore } from "pinia";
import axios from "axios";

export const useNewsStore = defineStore("newsStore", {
  state: () => ({
    news: [],
    lastestNews: [],
    newsCount: 0,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    isLoadingModal: false,
    isFailModal: false,
    pauseError: false,
    isEditSuccess: false,
    isEditFail: false,
    isShowEditor: false,
    addError: false,
    addSuccess: false,
    editedNews: null,
  }),
  actions: {
    async addNews(news) {
      this.addError = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.post(`${process.env.VUE_APP_API_BASE}admin/news/`, news, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        this.isLoadingModal = false;
        this.addSuccess = true;
      } catch (err) {
        this.addError = true;
        this.isLoadingModal = false;
        console.log(err);
      }
    },
    async getNews() {
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/news/${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.news = data.news.results;
        this.totalPages = data.news.totalPages;
        this.currentPage = data.news.currentPage;
      } catch (err) {
        this.isLoadingModal = false;
      }
    },
    async pauseNews(newsId) {
      this.pauseError = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/news/pause`,
          {
            newsId: newsId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;

        await this.getNews();
      } catch (err) {
        this.isLoadingModal = false;
        this.pauseError = true;
        console.log(err);
      }
    },
    async activateNews(newsId) {
      this.isLoadingModal = true;
      this.pauseError = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/news/unpause`,
          {
            newsId: newsId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getNews();
      } catch (err) {
        this.isLoadingModal = false;
        this.pauseError = true;
        console.log(err);
      }
    },
    async deleteNews(newsId) {
      this.isLoadingModal = true;
      this.pauseError = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.delete(
          `${process.env.VUE_APP_API_BASE}admin/news/${newsId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getNews();
      } catch (err) {
        this.isLoadingModal = false;
        this.pauseError = true;
        console.log(err);
      }
    },
    async updateNews(news, newsId) {
      this.isLoadingModal = true;
      this.isEditFail = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/news/update/${newsId}`,
          news,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isEditSuccess = true;
      } catch (err) {
        this.isLoadingModal = false;
        this.isEditFail = true;
        console.log(err);
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getNews();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getNews();
      }
    },
  },
});
