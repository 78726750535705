<template>
  <div class="pt-40">
    <EditNewsFail v-if="newsStore.isEditFail" />
    <EditNewsSuccess v-if="newsStore.isEditSuccess" />
    <LoaderModal v-if="newsStore.isLoadingModal" />

    <form @submit.prevent="saveNews" enctype="multipart/form-data">
      <h1 class="text-black text-2xl mb-1 font-medium">Modifier article</h1>
      <p class="text-[#7C7C7C] mb-1">Titre de l’article</p>
      <input
        type="text"
        class="w-full mb-2 focus:outline-none border px-2 py-2 text-xl border-[#CACACA] rounded-md"
        id="title"
        v-model="title"
      />
      <p class="text-red-500" v-if="v$.title.$error">
        le titre doit comporter au moins 6 caractères
      </p>
      <p class="text-[#7C7C7C] mb-1">Couverture</p>

      <div class="border p-4 text-xl border-[#CACACA] rounded-md">
        <label class="cursor-pointer w-fit flex items-center" for="inputTag">
          <div
            v-if="!cover"
            class="flex flex-col upload-block justify-center items-center p-2 h-40 w-40 rounded-md border-dashed"
          >
            <img :src="imageUploader" alt="imageUploader" />

            <p class="text-[#7C7C7C] mb-1 text-center text-base mt-2">
              Ajouter une photo
            </p>

            <input
              class="hidden"
              hidden
              id="inputTag"
              @change="handleCoverChange"
              type="file"
              accept="image/*"
            />
          </div>
          <img class="h-40 w-60" ref="output" />
          <img
            @click="removeImage"
            :src="trash"
            class="ml-2"
            v-if="cover"
            width="40"
          />
        </label>
      </div>
      <p class="text-red-500" v-if="v$.cover.$error">
        La photo de couverture est obligatoire
      </p>
      <p class="text-[#7C7C7C] mb-1 mt-4">Corps</p>
      <div id="toolbar">
        <!-- Add font family dropdown -->
        <select class="ql-font">
          <option selected value="sans-serif"></option>
          <option value="serif"></option>
          <option value="monospace"></option>
        </select>
        <!-- Add font size dropdown -->
        <select class="ql-size">
          <option value="small"></option>
          <option selected></option>
          <option value="large"></option>
          <option value="huge"></option>
        </select>
        <!-- Add a bold button -->
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <!-- Add lists -->
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <!-- Add new image  -->
        <button class="ql-image" value="sub"></button>
      </div>
      <QuillEditor
        ref="myQuillEditor"
        content-type="html"
        @text-change="showContent"
        v-model:content="editorContent"
        toolbar="#toolbar"
      />

      <h1 class="text-xl mt-10">{{ title }}</h1>
      <p class="text-red-500" v-if="v$.editorContent.$error">
        Le corps de l'article est obligatoire
      </p>
      <div class="content ql-editor" v-html="editorContent"></div>

      <button class="btn my-5" type="submit">Enregistrer</button>
    </form>
  </div>
</template>
<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import imageUploader from "@/assets/icons/cards/imageUploader.svg";
import trash from "@/assets/icons/cards/trash.svg";
import { useAuthStore } from "@/store/auth";
import { useNewsStore } from "@/store/news";
import EditNewsFail from "@/components/modals/news/EditNewsFail.vue";
import EditNewsSuccess from "@/components/modals/news/EditNewsSuccess.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import router from "@/router";
export default {
  components: {
    QuillEditor,
    LoaderModal,
    EditNewsFail,
    EditNewsSuccess,
  },
  data() {
    return {
      title: this.newsStore?.editedNews?.title
        ? this.newsStore?.editedNews?.title
        : "",
      cover: this.newsStore?.editedNews?.cover
        ? this.newsStore?.editedNews?.cover
        : "",
      editorContent: this.newsStore?.editedNews?.content
        ? this.newsStore?.editedNews?.content
        : "",
      output: "",
    };
  },
  setup() {
    const authStore = useAuthStore();
    const newsStore = useNewsStore();
    return { v$: useVuelidate(), imageUploader, trash, authStore, newsStore };
  },
  mounted() {
    if (!this.newsStore.editedNews) {
      router.push("/admin/news");
    } else {
      this.$refs.output.width = 200;
      this.$refs.output.src =
        process.env.VUE_APP_API_BASE + this.newsStore?.editedNews?.cover;
      this.downloadImage();
    }
  },
  methods: {
    showContent() {
      console.log(this.output);
    },
    removeImage() {
      this.cover = null;
      this.$refs.output.src = "";
    },
    async downloadImage() {
      var xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        process.env.VUE_APP_API_BASE + this.newsStore?.editedNews?.cover,
        true
      );
      xhr.responseType = "blob";
      xhr.onload = () => {
        console.log("good");
        if (xhr.status == 200) {
          var file = new File([xhr.response], "image.jpg", {
            type: "image/jpeg",
          });
          this.cover = file;
        }
      };
      xhr.send();
    },

    handleCoverChange(event) {
      console.log("image  changed");
      this.cover = event.target.files[0];
      let output = this.$refs.output;
      output.src = URL.createObjectURL(this.cover);
      output.width = 200;
    },
    async saveNews() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("content", this.editorContent);
      formData.append("image", this.cover);
      console.log(this.cover); // Display the key/value pairs
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      await this.newsStore.updateNews(formData, this.$route.params.id);
      this.removeImage();
      this.title = "";
      var element = document.getElementsByClassName("ql-editor");
      element[0].innerHTML = "";
    },
  },
  validations() {
    return {
      title: { required, minLength: minLength(6) },
      editorContent: { required, minLength: minLength(6) },
      cover: { required },
    };
  },
};
</script>
<style scoped>
.ql-image {
  display: none !important;
}
.upload-block {
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A0A0A0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
