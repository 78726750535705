<template>
  <div></div>
</template>
<script>
import { useNewsStore } from "@/store/news";
export default {
  setup() {
    const newsStore = useNewsStore();
    const { addError, addSuccess } = useNewsStore();
    return {
      newsStore,
      addError,
      addSuccess,
    };
  },
  mounted() {
    if (this.newsStore.addError) {
      this.$swal({
        icon: "error",
        title: "quelque chose s'est mal passé",
        showConfirmButton: true,
        timer: 1500,
        willClose: () => {
          this.newsStore.addError = false;
          this.newsStore.addSuccess = false;
        },
      });
    }
  },
};
</script>
