<template>
  <div>
    <div class="flex flex-wrap mt-4" v-if="othersStore.singleOther">
      <div class="w-full px-4">
        <div class="my-6 flex flex-row-reverse mt-24"></div>
        <div>
          <div class="flex font-medium text-xl">
            <router-link to="/admin/autres">Autres</router-link>
            <img class="mx-2" :src="separator" alt="separtor" />
            <router-link :to="`/admin/autres/${othersStore.singleOther.id}`">{{
              othersStore.singleOther.first_name +
              " " +
              othersStore.singleOther.last_name
            }}</router-link>
          </div>

          <div>
            <img :src="othersStore.singleOther.profile_picture
                  ? `${api}` + othersStore.singleOther.profile_picture
                  : playerPic
                " alt="playerPic" width="250" height="250" class="rouneded-lg my-6" />
          </div>
          <div class="flex my-6 font-medium text-black player-card py-4 px-2 rounded-md">
            <img :src="proInfo" alt="sport" class="mx-2" /> Domain :
            <span class="font-normal text-[#000] opacity-40 ml-1">{{
              othersStore.singleOther.domain
            }}</span>
          </div>
          <div class="my-6 font-medium text-black player-card py-4 px-2 rounded-md">
            <div class="flex border-b w-fit py-3">
              <img :src="profile" alt="sport" class="mx-2" /> Informations
              personnelles :
            </div>

            <div class="px-2 py-5">
              <p class="my-2">
                Nom complet:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  othersStore.singleOther.first_name +
                  " " +
                  othersStore.singleOther.last_name
                }}</span>
              </p>
              <p class="my-2">
                Nationnalité:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  othersStore.singleOther.nationality
                }}</span>
              </p>
              <p class="my-2">
                Localisation:
                <span class="font-normal text-[#000] opacity-40 ml-1">
                  <a :href="`https://www.google.com/maps?q=${othersStore.singleOther.latitude},${othersStore.singleOther.longitude}`"
                    target="_blank" class="font-semibold">Lien</a>
                </span>
              </p>
              <p class="my-2">
                Téléphone:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  othersStore.singleOther.phone_number
                }}</span>
              </p>
              <p class="my-2">
                Sexe:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  othersStore.singleOther.gender == "M" ? "Homme" : "Femme"
                }}</span>
              </p>
              <p class="my-2">
                Date de naissance:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{ othersStore.singleOther.birthday }}
                </span>
              </p>
            </div>
          </div>
          <div v-if="othersStore.singleOther.otherCareer"
            class="my-6 font-medium text-black player-card py-4 px-2 rounded-md">
            <div class="flex border-b w-fit py-3">
              <img :src="proInfo" alt="sport" class="mx-2" />Carrière :
            </div>

            <div class="px-2 py-5">
              <p class="my-2">
                Poste:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  othersStore.singleOther.otherCareer.position
                }}</span>
              </p>
              <p class="my-2">
                Experience:
              <ul>
                <li class="my-1" v-for="exp in othersStore.singleOther.otherCareer.experience" :key="exp">
                  • {{ exp }}
                </li>
              </ul>
              </p>
            </div>
          </div>

          <div v-if="othersStore.singleOther?.otherCareer?.cv"
            class="my-6 font-medium text-black player-card py-4 px-2 rounded-md">
            <div class="flex border-b w-fit py-3 ">
              <img :src="cv" alt="cv" class="mx-2" />
              CV :
            </div>

            <a :href="api + othersStore.singleOther.otherCareer.cv" target="_blank" class="px-2 py-5 flex">
              <div><img :src="pdf" alt="pdf" class="mr-3" /></div>
              <div>
                <div class="text-black">
                  {{ othersStore.singleOther.first_name + " " + othersStore.singleOther.last_name }} - CV - {{
                    othersStore.singleOther.domain }}
                </div>

              </div>
            </a>
          </div>
        </div>
        <div><button class="btn my-4 mb-10" @click="handleContact">Contacter</button></div>
      </div>
    </div>
  </div>
</template>
<script>
import cv from "@/assets/icons/cards/cv.svg";
import career from "@/assets/icons/cards/career.svg";
import proInfo from "@/assets/icons/cards/proInfo.svg";
import profile from "@/assets/icons/cards/profile.svg";
import sportTitle from "@/assets/icons/cards/sportTitle.svg";
import pdf from "@/assets/icons/cards/pdf.svg";
import separator from "@/assets/icons/cards/separator.svg";
import playerPic from "@/assets/images/player.png";
import { useOthersStore } from "@/store/others";
import { useChatStore } from "@/store/chat";
import router from "@/router";
export default {
  name: "dashboard-page",
  async mounted() {
    await this.othersStore.getOneOther(this.$route.params.id);
  },
   methods: {
    async handleContact() {
      await this.chatStore.getChats();
      const result = this.chatStore.chats.find((chat) => { return chat.receiver.id == this.othersStore.singleOther.id && chat.receiverRole == "other" })
      if (result) {
        this.chatStore.setCurrentChat({
          ...result
        });
      } else {
        await this.chatStore.createChat(this.othersStore.singleOther.id, "other")
        this.chatStore.setCurrentChat(
          this.chatStore.newChat
        );
      }
      router.push("/admin/messagerie")
    }
  },
  setup() {
    const othersStore = useOthersStore();
    const chatStore = useChatStore();

    const api = process.env.VUE_APP_API_BASE;
    return {
      othersStore,
      proInfo,
      cv,
      career,
      profile,
      sportTitle,
      pdf,
      playerPic,
      separator,
      api,
      chatStore
    };
  },
  data() {
    return {};
  },


};
</script>
<style>
.player-card {
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 1px 15px rgba(0, 0, 0, 0.1));
}
</style>
