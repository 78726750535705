import { defineStore } from "pinia";
import axios from "axios";

export const useChatStore = defineStore("chatStore", {
  state: () => ({
    chats: [],
    currentChat: null,
    messages: [],
    message: null,
    latestMessageSent: null,
    newChat: null,
    fullMessage: null, // whether it's with file or not
    fileSent: null,
    isLoadingMessages: false,
    isLoadingChats: false,
    isMessageSent: false,
    //pagination
    totalPages: 0,
    currentPage: 1,
  }),
  getters: {
    getChatNow() {
      return this.currentPage;
    },
  },
  actions: {
    async getChats() {
      this.isLoadingChats = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}chat`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.chats = data.filter((item) => {
          return item.latestMessage !== null;
        });
        if (!this.currentChat) {
          this.currentChat = this.chats[0];
        }
        this.isLoadingChats = false;
      } catch (err) {
        this.isLoadingChats = false;

        console.error(err);
      }
    },
    async getMessages() {
      await this.getChats();
      this.isLoadingMessages = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}message/${this.currentChat.id}?page=${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const adminPhone = JSON.parse(admin).user.phone;
        let newData = data.messages.results;
        newData = newData.reverse();
        this.totalPages = data.messages.totalPages;
        let discussion = newData.map((message) => {
          if (message.sender.phone == adminPhone) {
            return { ...message, role: "sender" };
          } else return { ...message, role: "receiver" };
        });
        if (this.currentPage != 1) {
          this.messages = this.messages.concat(discussion);
        } else {
          this.messages = discussion;
        }
        this.messages = this.messages.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
        this.removeDuplicates(this.messages, "id");
        this.isLoadingMessages = false;
      } catch (err) {
        this.isLoadingMessages = false;
        console.error(err);
      }
    },
    async setCurrentChat(chat) {
      this.currentPage = 1;
      this.currentChat = chat;
      this.messages = [];
      if (this.currentChat) {
        this.getMessages(this.currentChat.id);
      }
    },
    async sendMessage(message) {
      this.latestMessageSent = null;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        if (this.fileSent) {
          const formData = new FormData();
          formData.append("content", message);
          formData.append("role", "sender");
          formData.append("file", this.fileSent);
          formData.append("createdAt", new Date());
          formData.append("chatId", this.currentChat.id);
          this.fullMessage = formData;
        } else {
          this.fullMessage = {
            chatId: this.currentChat.id,
            content: message,
          };
        }
        const { data } = await axios.post(
          `${process.env.VUE_APP_API_BASE}message/`,
          {
            chatId: this.currentChat.id,
            content: message,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isMessageSent = true;
        const newMessage = {
          content: message,
          role: "sender",
          createdAt: new Date(),
        };
        this.messages.push(newMessage);
        this.message = null;
        if (this.messages.length <= 1) {
          await this.getChats();
        }
        this.fullMessage = null;
        this.latestMessageSent = data;
        console.log(this.latestMessageSent);

        console.log(data);
      } catch (err) {
        //to be removed when fixed id notification
        this.isMessageSent = true;
        const newMessage = {
          content: message,
          role: "sender",
          createdAt: new Date(),
        };
        this.messages.push(newMessage);
        this.message = null;
        if (this.messages.length <= 1) {
          await this.getChats();
        }
        //to be removed when fixed id notification
        this.fullMessage = null;
        this.latestMessageSent = null;

        console.error(err);
      }
    },
    async sendMessageWithFile(message) {
      this.latestMessageSent = null;

      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const formData = new FormData();
        formData.append("content", message ? message : "");
        formData.append("role", "sender");
        formData.append("file", this.fileSent);
        formData.append("chatId", this.currentChat.id);
        const { data } = await axios.post(
          `${process.env.VUE_APP_API_BASE}message/`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isMessageSent = true;
        const newMessage = {
          content: message,
          role: "sender",
          file: "true",
          createdAt: new Date(),
        };
        this.messages.push(newMessage);
        this.message = null;
        if (this.messages.length <= 1) {
          await this.getChats();
        }
        this.latestMessageSent = data;
      } catch (err) {
        //to be removed when fixed id notification
        this.isMessageSent = true;
        const newMessage = {
          content: message,
          role: "sender",
          createdAt: new Date(),
        };
        this.messages.push(newMessage);
        this.message = null;
        if (this.messages.length <= 1) {
          await this.getChats();
        }
        //to be removed when fixed id notification
        console.error(err);
        this.latestMessageSent = null;
      }
    },
    async createChat(receiverId, receiverRole) {
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.post(
          `${process.env.VUE_APP_API_BASE}chat/`,
          {
            receiverId,
            roleReceiver: receiverRole,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.newChat = {
          id: data.chat.id,
          receiver: data.receiver,
          receiverRole: receiverRole,
          createdAt: data.chat.createdAt,
        };
      } catch (err) {
        console.error(err);
      }
    },
    async removeDuplicates(array, property) {
      const uniqueObject = {};
      const uniqueArray = [];

      for (const obj of array) {
        const value = obj[property];
        if (!uniqueObject[value]) {
          uniqueObject[value] = true;
          uniqueArray.push(obj);
        }
      }
      this.messages = uniqueArray;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getMessages();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getMessages();
      }
    },
  },
});
