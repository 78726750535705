<template>
  <div class="permissions-filter">
    <div>
      <a
        class="text-blueGray-500 block"
        href="#"
        ref="popperElementRef"
        v-on:click="toggleDropdown($event)"
      >
      </a>
      <button
        class="border border-gray-500 p-2 rounded-xl"
        @click="toggleDropdown"
        v-if="!dropdownPopoverShow"
      >
        <img :src="filterModOpen" alt="filterModOpen" />
      </button>
      <button
        class="border border-gray-500 p-2 rounded-xl"
        @click="toggleDropdown"
        v-if="dropdownPopoverShow"
      >
        <img :src="filterModClosed" alt="filterModClosed" />
      </button>
    </div>

    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left list-none text-left rounded shadow-lg min-w-48 w-full"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <div
        @click="togglePermission(index)"
        v-for="(permission, index) in permissions"
        :key="index"
        :class="permission.hasPermission ? 'bg-blue-100' : ''"
        class="px-2 border-b py-3 cursor-pointer flex items-center"
      >
        <img
          v-if="permission.hasPermission"
          :src="permissionCheck"
          class="mr-2"
          alt="permissionCheck"
        />
        {{ permission.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import filterModClosed from "@/assets/icons/cards/filterModClosed.svg";
import filterModOpen from "@/assets/icons/cards/filterModOpen.svg";
import permissionCheck from "@/assets/icons/cards/permissionCheck.svg";
import { useModeratorsStore } from "@/store/moderators";
export default {
  data() {
    return {
      dropdownPopoverShow: false,
      permissions: [
        { name: "Rédaction d’articles", hasPermission: false },
        { name: "Création des détections", hasPermission: false },
        { name: "Actions sur joueurs", hasPermission: false },
        { name: "Actions sur clubs", hasPermission: false },
        { name: "Actions sur agents", hasPermission: false },
        { name: "Actions sur autres", hasPermission: false },
        { name: "Messagerie", hasPermission: false },
        { name: "Actions sur abonnement", hasPermission: false },
      ],
    };
  },
  setup() {
    const moderatorsStore = useModeratorsStore();
    return {
      moderatorsStore,
      filterModOpen,
      filterModClosed,
      permissionCheck,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      console.log(this.$refs.popoverDropdownRef);

      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".career-filter");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        console.log(this.$refs.popperElementRef);
        createPopper(
          this.$refs.popoverDropdownRef,
          this.$refs.popperElementRef,
          {
            placement: "bottom-end",
          }
        );
      }
    },
    togglePermission(index) {
      this.permissions[index].hasPermission =
        !this.permissions[index].hasPermission;
      const result = [];
      for (let index = 0; index < this.permissions.length; index++) {
        if (this.permissions[index].hasPermission) {
          result.push(index + 1);
        }
      }
      const formattedString = `[ ${result.join(",")} ]`;
      this.moderatorsStore.newModerator.permissions = formattedString;
      console.log(formattedString + "eeee");
    },
  },
};
</script>
