<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <loader-modal v-if="agentsStore.isLoadingModal" />
      <div class="w-full px-4">
        <div class="my-6 flex flex-row-reverse mt-24"></div>
        <agents-table />
      </div>
    </div>
  </div>
</template>
<script>
import AgentsTable from "@/components/tables/AgentsTable.vue";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { useAgentsStore } from "@/store/agents";

export default {
  name: "dashboard-page",
  components: {
    AgentsTable,
    LoaderModal,
  },
  data() {
    return {};
  },
  setup() {
    const agentsStore = useAgentsStore();
    return {
      agentsStore,
    };
  },
  methods: {},
};
</script>
