<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
  >
    <div class="flex justify-between">
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-medium text-lg">Autres</h3>
          </div>
        </div>
      </div>
      <div class="flex">
        <filter-others-date />
        <filter-others-nationality />
        <filter-others-abonmment />
        <filter-others-fonction />
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- News table -->
      <table
        v-if="othersStore.others.length > 0"
        class="items-center w-full bg-transparent border-collapse"
      >
        <thead>
          <tr class="border-b border-t">
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              <img
                src="/img/checkbox.e6903039.svg"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <th
              class="text-[#999999] px-6 flex items-center align-middle py-3 uppercase border-l-0 border-b-0 border-r-0 font-medium text-left"
            >
              ID <br />
              Utilisateur
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Utilisateur
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Téléphone
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Nationnalité
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Localisation
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Date d’inscription
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Abonnement
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Domain
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Status
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(other, index) in othersStore.others"
            :key="index"
            class="border-b"
          >
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              <img
                src="/img/checkbox.e6903039.svg"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <td class="align-middle border-l-0 border-r-0 p-4">
              #{{ other.id }}
            </td>
            <td class="align-middle border-l-0 border-r-0 p-4">
              <div class="flex items-center">
                <img
                  v-if="other.profile_picture"
                  :src="api + other.profile_picture"
                  alt="person"
                  class="mr-2 rounded-full w-10 h-10"
                />
                <img
                  v-if="!other.profile_picture"
                  :src="person"
                  alt="person"
                  class="mr-2"
                />
                {{ other.first_name + " " + other.last_name }}
              </div>
            </td>
            <td class="align-middle border-l-0 border-r-0 p-4">
              {{ other.phone_number }}
            </td>
            <td class="align-middle border-l-0 border-r-0 p-4">
              {{ other.nationality }}
            </td>
            <td class="align-middle border-l-0 border-r-0 p-4">
              <a
                :href="`https://www.google.com/maps?q=${other.latitude},${other.longitude}`"
                target="_blank"
                class="font-semibold"
                >Lien</a
              >
            </td>
            <td class="align-middle border-l-0 border-r-0 p-4">
              {{ other.createdAt.substring(0, 10) }}
            </td>

            <td class="align-middle border-l-0 border-r-0 p-4">
              {{ other.planName }}
            </td>
            <td class="align-middle border-l-0 border-r-0 p-4">
              {{ other.domain }}
            </td>
            <td
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              <div
                v-if="!other.is_blocked"
                class="rounded-xl text-center px-2 py-1 font-medium text-primary border-primary border"
              >
                ACTIF
              </div>
              <div
                v-if="other.is_blocked"
                class="rounded-xl text-center px-2 py-1 font-medium text-[#C79141] border-[#C79141] border"
              >
                BLOQUÉ
              </div>
            </td>

            <td class="align-middle border-l-0 border-r-0 text-right">
              <others-actions-dropdown
                :otherId="other.id"
                :is_blocked="other.is_blocked"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="flex flex-col justify-center items-center py-10">
        <img :src="noRecords" alt="noRecords" class="w-2/6" />
        <div class="text-xl text-[#747373]">Aucun record pour le moment !</div>
      </div>
    </div>
    <div
      v-if="othersStore.others.length > 0"
      class="flex justify-around w-full py-6"
    >
      <div>
        <button
          @click="othersStore.previousPage"
          :disabled="othersStore.currentPage === 1"
          class="border text-primary px-3 py-2 rounded-md"
        >
          Précédente
        </button>
      </div>
      <div class="text-primary flex justify-center items-center">
        Page {{ othersStore.currentPage }} sur
        {{ othersStore.totalPages }}
      </div>
      <div>
        <button
          @click="othersStore.nextPage"
          :disabled="othersStore.currentPage === othersStore.totalPages"
          class="border text-primary px-3 py-2 rounded-md"
        >
          Prochaine
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import OthersActionsDropdown from "@/components/dropdowns/OthersActionsDropdown.vue";
import checkbox from "@/assets/icons/cards/checkbox.svg";
import person from "@/assets/icons/cards/person.svg";
import FilterOthersNationality from "../dropdowns/filters/others/FilterOthersNationality.vue";
import FilterOthersAbonmment from "../dropdowns/filters/others/FilterOthersAbonmment.vue";
import FilterOthersFonction from "../dropdowns/filters/others/FilterOthersFonction.vue";
import FilterOthersDate from "../dropdowns/filters/others/FilterOthersDate.vue";
import { useOthersStore } from "@/store/others";
import noRecords from "@/assets/images/noRecords.svg";

export default {
  components: {
    OthersActionsDropdown,
    FilterOthersNationality,
    FilterOthersAbonmment,
    FilterOthersFonction,
    FilterOthersDate,
  },
  mounted() {
    this.othersStore.getOthers();
  },
  data() {
    return {};
  },
  setup() {
    const api = process.env.VUE_APP_API_BASE;
    const othersStore = useOthersStore();
    return {
      othersStore,
      checkbox,
      person,
      noRecords,
      api,
    };
  },
};
</script>
