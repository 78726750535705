<template>
  <div class="flex flex-wrap mt-4">
    <LoaderModal v-if="plansStore.isLoading" />

    <div class="my-6 mt-24">
      <div class="flex justify-between items-center font-medium w-full">
        <div>
          <router-link to="/admin/abonnement" class="text-xl"
            >Abonnement</router-link
          >
        </div>
        <PlansSwitcher />
      </div>
      <div v-if="plansStore.plans.length > 0" class="my-6 md:flex w-full">
        <AbonnementCard
          :price="plan.price"
          :id="plan.id"
          :description="plan.description"
          :name="plan.name"
          :type="plan.type"
          v-for="(plan, index) in plansStore.plans"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AbonnementCard from "@/components/cards/AbonnementCard.vue";
import separator from "@/assets/icons/cards/separator.svg";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import PlansSwitcher from "@/components/dropdowns/filters/plans/PlansSwitcher.vue";
import { usePlansStore } from "@/store/plans";
export default {
  components: { AbonnementCard, LoaderModal, PlansSwitcher },
  async mounted() {
    await this.plansStore.getPlans(this.plansStore.selectedPlan);
  },
  setup() {
    const plansStore = usePlansStore();
    return { plansStore, separator };
  },
};
</script>
<style></style>
