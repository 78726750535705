<template>
  <div>
    <p class="text-center opacity-40">
      Entrez l’adresse email que vous souhaitez recevoire le code de
      vérification.
    </p>
    <form
      @submit.prevent="sendVerificationCode"
      class="flex flex-col items-center my-8"
    >
      <div
        class="flex items-center border rounded-md py-3 password-container lg:min-w-[400px]"
        :class="[
          isEmailFocus ? 'border-primary border-[3px]' : '',
          v$.restoreEmail.$error ? 'border-red-500 border-[3px]' : '',
        ]"
      >
        <input
          class="text-gray-700 px-2 leading-tight focus:outline-none"
          type="text"
          @input="v$.$validate()"
          @focus="isEmailFocus = true"
          @blur="isEmailFocus = false"
          v-model="restoreEmail"
          name="email"
        />
      </div>
      <p v-if="v$.restoreEmail.$error" class="text-red-500 my-2 text-left">
        Email invalide
      </p>
      <p v-if="authStore.error" class="text-red-500 my-2 text-left">
        {{ authStore.error }}
      </p>

      <button type="submit" class="btn my-6">Envoyer le code</button>
    </form>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useAuthStore } from "@/store/auth";
export default {
  data() {
    return {
      restoreEmail: "",
      isEmailFocus: false,
    };
  },
  mounted() {
    this.authStore.error = null;
  },
  setup() {
    const authStore = useAuthStore();
    return { v$: useVuelidate(), authStore };
  },
  methods: {
    async sendVerificationCode() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      this.authStore.sendResetCode(this.restoreEmail);
    },
  },
  validations() {
    return {
      restoreEmail: { required, email },
    };
  },
};
</script>
