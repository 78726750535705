<template>
  <div class="flex h-full flex-col min-w-0 w-full mb-6 shadow-lg rounded">
    <div class="flex justify-between px-4 h-full">
      <p class="text-xl font-medium text-black py-3">Conversions</p>
      <div><FilterBarChart /></div>
    </div>
    <div class="p-4 flex-auto">
      <Bar id="my-chart-id" :options="chartOptions" :data="chartDataYearly" />
    </div>
  </div>
</template>
<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import FilterBarChart from "../dropdowns/filters/dashboard/FilterBarChart.vue";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar, FilterBarChart },
  data() {
    return {
      chartDataYearly: {
        labels: [
          "Jan.",
          "Fév.",
          "Mar.",
          "Avr.",
          "Mai",
          "Juin",
          "Jui.",
          "Août",
          "Sept.",
          "Oct.",
          "Nov.",
          "Déc.",
        ],
        datasets: [
          {
            backgroundColor: "#B9E1CE",
            data: [20, 30, 10, 15, 25, 10, 20, 25, 10, 20, 25, 10],
          },
          {
            backgroundColor: "#00E479",
            data: [20, 40, 40, 40, 40, 15, 30, 20, 40, 20, 25, 10],
          },
          {
            backgroundColor: "#3A795B",
            data: [10, 20, 30, 40, 50, 40, 50, 10, 20, 30, 40, 25],
          },
        ],
      },
      chartDataWeekly: {
        labels: ["Dim", "Lun", "Mar", "Jeu", "Ven", "Sam", "Dim"],
        datasets: [
          {
            backgroundColor: "#B9E1CE",
            data: [20, 30, 10, 15, 25, 10, 20],
          },
          {
            backgroundColor: "#00E479",
            data: [20, 40, 40, 40, 40, 15, 30],
          },
          {
            backgroundColor: "#3A795B",
            data: [10, 20, 30, 40, 50, 40, 50],
          },
        ],
      },
      chartDataMonthly: {
        labels: [
          "1ère semaine",
          "2ème semaine",
          "3ème semaine",
          "4sème semaine",
        ],
        datasets: [
          {
            backgroundColor: "#B9E1CE",
            data: [20, 30, 10, 15],
          },
          {
            backgroundColor: "#00E479",
            data: [20, 40, 40, 40],
          },
          {
            backgroundColor: "#3A795B",
            data: [10, 20, 30, 40],
          },
        ],
      },
      chartOptions: {
        plugins: { legend: { display: false } },
        responsive: true,
        barThickness: 10,
        borderRadius: 5,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
  },
};
</script>
