<template>
  <loader-modal v-if="authStore.isLoading" />
  <div
    class="flex login-page 3xl:justify-between justify-center items-center pt-20 md:pt-0 max-w-[1728px] lg:mx-auto"
  >
    <div class="lg:flex items-center 3xl:w-[60%]">
      <div class="px-4 md:w-[50vw] 2xl:w-[65vw]">
        <img :src="logo" alt="logo" class="mx-auto w-16 lg:w-[110px]" />
        <h1
          class="my-3 lg:my-6 text-[22px] lg:text-[33px] font-medium text-black text-center"
        >
          Panel Admin
        </h1>
        <router-view />
      </div>
    </div>

    <div
      class="hidden md:flex justify-center items-center bg-primary w-[50vw] 2x:w-[35vw] 3xl:w-[40%] h-screen"
    >
      <img :src="loginImage" class="h-screen 2xl:h-[auto]" alt="ilustration" />
    </div>
  </div>
</template>
<script>
import logo from "@/assets/images/logo.png";
import loginImage from "@/assets/images/loginImage.png";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { useAuthStore } from "@/store/auth";

export default {
  setup() {
    const authStore = useAuthStore();
    return { logo, loginImage, authStore };
  },
  components: { LoaderModal },
};
</script>
<style scoped>
.login-page {
  background-image: url("@/assets/images/loginBg.png");
  background-size: 30vh;
  background-repeat: no-repeat;
}
input::-webkit-input-placeholder {
  color: rgba(41, 44, 48, 0.8);
  font-weight: 400;
}
.socials-logo {
  height: 20px;
}
</style>
