<template>
  <div class="player-filter mx-2">
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="FilterPlayerAbonmment"
      v-on:click="toggleDropdown($event)"
    >
      <button class="btn-outline rounded-xl py-1 px-3 flex items-center">
        <img :src="sportFilter" alt="sportFilter" class="mr-2" />
        Sport
        <img
          :src="filterClosed"
          v-if="!dropdownPopoverShow"
          alt="filterClosed"
          class="ml-2"
        />
        <img
          :src="filterOpen"
          v-if="dropdownPopoverShow"
          alt="filterOpen"
          class="ml-2"
        />
      </button>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <div class="flex px-10 border-b py-3" @click="filterPlayers('football')">
        <img
          v-if="playersStore.selectedSport == 'football'"
          :src="check"
          class="mr-2"
          alt="check"
        />Football
      </div>
      <div
        class="flex px-10 border-b py-3"
        @click="filterPlayers('Basketball')"
      >
        <img
          v-if="playersStore.selectedSport == 'Basketball'"
          :src="check"
          class="mr-2"
          alt="check"
        />Basketball
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import filterClosed from "@/assets/icons/cards/filterClosed.svg";
import filterOpen from "@/assets/icons/cards/filterOpen.svg";
import sportFilter from "@/assets/icons/cards/sportFilter.svg";
import { usePlayersStore } from "@/store/players";
import check from "@/assets/icons/cards/permissionCheck.svg";
export default {
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  setup() {
    const playersStore = usePlayersStore();
    return { playersStore, sportFilter, filterOpen, filterClosed, check };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".player-filter");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-end",
        });
      }
    },
    filterPlayers(filter) {
      this.playersStore.selectedSport = filter;
      this.playersStore.getPlayers();
      this.dropdownPopoverShow = false;
    },
  },
};
</script>
