<template>
  <div class="flex mt-4" v-if="jobOffer">
    <div class="w-full px-4">
      <div class="my-6 flex mt-24">
        <h3 class="font-medium text-lg flex">
          <router-link to="/admin/clubs">Tous les clubs</router-link>
          <img class="mx-2" :src="separator" alt="separtor" />
          <p>{{ jobOffer.club.club_name }}</p>
          <img class="mx-2" :src="separator" alt="separtor" />
          <router-link to="/admin/clubs/sdf">Offres d’emploi</router-link>
          <img class="mx-2" :src="separator" alt="separtor" />
          <p>Offer #{{ jobOffer.id }}</p>
        </h3>
      </div>
    </div>
  </div>
  <div class="flex" v-if="jobOffer">
    <div class="px-4">
      <div class="my-8">
        <h1 class="text-black font-semibold mb-2">
          Le club Lorem recherche un
        </h1>
        <p class="opacity-40">{{ jobOffer.wanted_profile }}</p>
      </div>
      <div class="my-8">
        <h1 class="text-black font-semibold mb-2">Description</h1>

        <p class="opacity-40">
          {{ jobOffer.description }}
        </p>
      </div>

      <div class="my-8">
        <h1 class="text-black font-semibold mb-2">Exigences</h1>
        <ul class="opacity-40">
          <li class="my-1" v-for="exp in jobOffer.requirements" :key="exp">
            • {{ exp }}
          </li>
        </ul>
      </div>

      <h1 class="text-black font-semibold mb-2">Localisation</h1>
      <p class="text-[#524B6B]">
        <a
          :href="`https://www.google.com/maps?q=${jobOffer.club.latitude},${jobOffer.club.longitude}`"
          target="_blank"
          class="font-semibold"
          >Lien</a
        >
      </p>
      <div id="map"></div>

      <!-- prettier-ignore -->
    </div>
  </div>
</template>
<script>
import separator from "@/assets/icons/cards/separator.svg";
import { useClubsStore } from "@/store/clubs";
export default {
  setup() {
    const clubStore = useClubsStore();
    return {
      separator,
      clubStore,
    };
  },
  data() {
    return {
      jobOffer: null,
    };
  },
  async mounted() {
    this.jobOffer = await this.clubStore.getJobOffer(this.$route.params.id);
    console.log(this.jobOffer);
    if (!this.jobOffer) {
      this.$router.push("/admin/clubs");
    }
  },
};
</script>
