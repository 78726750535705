<template>
  <div
    v-if="authStore?.user?.user?.permissions?.includes('9')"
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white mt-6"
  >
    <AddModeratorModal
      v-if="moderatorsStore.showAddModal"
      @close="moderatorsStore.showAddModal = false"
    />

    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-medium text-lg">Modérateurs</h3>
        </div>
        <div>
          <button
            @click="moderatorsStore.showAddModal = true"
            class="btn flex items-center cursor-pointer shadow-none"
          >
            <img :src="addMod" alt="newArticle" class="mr-2 font-medium" />
            Nouveau modérateur
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto border rounded-lg border-b-0">
      <!-- News table -->
      <table
        v-if="moderatorsStore.moderators.length > 0"
        class="items-center w-full bg-transparent border-collapse"
      >
        <thead>
          <tr class="border-b border-t">
            <th
              class="text-[#999999] h-10 w-10 align-middle py-1 lg:py-3 uppercase whitespace-nowrap font-medium text-left"
            >
              <img
                src="/img/checkbox.e6903039.svg"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <th
              class="text-[#999999] px-6 flex items-center align-middle border border-solid py-3 uppercase border-l-0 border-b-0 border-r-0 font-medium text-left"
            >
              ID Modérateur
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Modérateur
            </th>

            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Téléphone
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Date d’ajout
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Permissions
            </th>

            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Status
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(moderator, index) in moderatorsStore.moderators"
            :key="index"
            class="border-b"
          >
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              <img
                src="/img/checkbox.e6903039.svg"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <td class="p-4">#{{ moderator.id }}</td>
            <td class="p-4">
              <div class="flex items-center">
                <img
                  v-if="moderator.profile_pic"
                  :src="api + moderator.profile_pic"
                  alt="person"
                  class="mr-2 rounded-full w-10 h-10"
                />
                <img
                  v-if="!moderator.profile_pic"
                  :src="person"
                  alt="person"
                  class="mr-2"
                />
                {{ moderator.first_name }} <br />
                {{ moderator.email }}
              </div>
            </td>
            <td class="p-4">{{ moderator.phone }}</td>
            <td class="p-4">{{ moderator.createdAt.substring(0, 10) }}</td>

            <td class="p-4">
              <div
                v-for="(item, index) in JSON.parse(moderator.permissions)"
                :key="index"
                class="text-black bg-[#CEFFE8] text-center px-2 py-1 rounded-md my-2"
              >
                <div v-if="item === 1">
                  <p>{{ permissionMapping[item - 1] }}</p>
                </div>
                <div v-if="item === 2">
                  <p>{{ permissionMapping[item - 1] }}</p>
                </div>
                <div v-if="item === 3">
                  <p>{{ permissionMapping[item - 1] }}</p>
                </div>
                <div v-if="item === 4">
                  <p>{{ permissionMapping[item - 1] }}</p>
                </div>
                <div v-if="item === 5">
                  <p>{{ permissionMapping[item - 1] }}</p>
                </div>
                <div v-if="item === 6">
                  <p>{{ permissionMapping[item - 1] }}</p>
                </div>
                <div v-if="item === 7">
                  <p>{{ permissionMapping[item - 1] }}</p>
                </div>
                <div v-if="item === 8">
                  <p>{{ permissionMapping[item - 1] }}</p>
                </div>
                <div v-if="item === 9">
                  <p>Super admin</p>
                </div>
              </div>
            </td>
            <td class="p-4">
              <div
                class="rounded-xl text-center px-2 py-1 font-medium text-primary border-primary border"
                v-if="!moderator.isPaused"
              >
                ACTIF
              </div>
              <div
                v-if="moderator.isPaused"
                class="rounded-xl text-center px-2 py-1 font-medium text-[#C79141] border-[#C79141] border"
              >
                PAUSE
              </div>
            </td>

            <td class="text-right">
              <moderators-actions-dropdown
                :moderatorId="moderator.id"
                :editedModerator="moderator"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="flex flex-col justify-center items-center py-10">
        <img :src="noRecords" alt="noRecords" class="w-2/6" />
        <div class="text-xl text-[#747373]">Aucun record pour le moment !</div>
      </div>
    </div>
    <div
      v-if="moderatorsStore.moderators.length > 0"
      class="flex justify-around w-full py-6"
    >
      <div>
        <button
          @click="moderatorsStore.previousPage"
          :disabled="moderatorsStore.currentPage === 1"
          class="border text-primary px-3 py-2 rounded-md"
        >
          Précédente
        </button>
      </div>
      <div class="text-primary flex justify-center items-center">
        Page {{ moderatorsStore.currentPage }} sur
        {{ moderatorsStore.totalPages }}
      </div>
      <div>
        <button
          @click="moderatorsStore.nextPage"
          :disabled="moderatorsStore.currentPage === moderatorsStore.totalPages"
          class="border text-primary px-3 py-2 rounded-md"
        >
          Prochaine
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ModeratorsActionsDropdown from "@/components/dropdowns/ModeratorsActionsDropdown.vue";
import checkbox from "@/assets/icons/cards/checkbox.svg";
import person from "@/assets/icons/cards/person.svg";
import addMod from "@/assets/icons/cards/addMod.svg";
import AddModeratorModal from "@/components/modals/AddModeratorModal.vue";
import { useModeratorsStore } from "@/store/moderators";
import { useAuthStore } from "@/store/auth";
import noRecords from "@/assets/images/noRecords.svg";

export default {
  components: {
    ModeratorsActionsDropdown,
    AddModeratorModal,
  },
  async mounted() {
    await this.moderatorsStore.getModerators();
  },
  data() {
    return {
      permissionMapping: [
        "Rédaction d’articles",
        "Création des détections",
        "Actions sur joueurs",
        "Actions sur clubs",
        "Actions sur agents",
        "Actions sur autres",
        "Messagerie",
        "Actions sur abonnement",
      ],
    };
  },

  setup() {
    const api = process.env.VUE_APP_API_BASE;
    const authStore = useAuthStore();
    const moderatorsStore = useModeratorsStore();
    return {
      moderatorsStore,
      checkbox,
      person,
      authStore,
      addMod,
      noRecords,
      api,
    };
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
