<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white rounded-md mb-6 xl:mb-0 shadow-lg"
  >
    <div class="flex-auto p-4">
      <div class="flex flex-wrap">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h5 class="text-black font-medium text-xl">
            {{ statTitle }}
          </h5>
          <span class="font-semibold text-blueGray-700 opacity-40">
            {{ statSubtitle }}
          </span>
        </div>
        <div class="relative w-auto pl-4 flex-initial">
          <div
            class="text-white text-center inline-flex items-center justify-center w-12 h-12 shadow-lg"
            :class="[statIconColor]"
          >
            <img :src="statIconName == 'up' ? up : down" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import up from "@/assets/icons/cards/up.svg";
import down from "@/assets/icons/cards/down.svg";
export default {
  name: "card-stats",
  setup() {
    return {
      up,
      down,
    };
  },
  props: {
    statSubtitle: {
      type: Number,
    },
    statTitle: {
      type: String,
      default: "350,897",
    },
    statArrow: {
      default: "up",
      validator: function (value) {
        // The value must match one of these strings
        return ["up", "down"].indexOf(value) !== -1;
      },
    },
    statPercent: {
      type: String,
      default: "3.48",
    },
    // can be any of the text color utilities
    // from tailwindcss
    statPercentColor: {
      type: String,
      default: "text-emerald-500",
    },
    statDescripiron: {
      type: String,
      default: "Since last month",
    },
    statIconName: {
      type: String,
      default: "up",
    },
    // can be any of the background color utilities
    // from tailwindcss
    statIconColor: {
      type: String,
      default: "bg-red-500",
    },
  },
};
</script>
