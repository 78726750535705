<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <loader-modal v-if="playersStore.isLoadingModal" />
      <div class="w-full px-4">
        <div class="my-6 flex flex-row-reverse mt-24"></div>
        <players-table />
      </div>
    </div>
  </div>
</template>
<script>
import PlayersTable from "@/components/tables/PlayersTable.vue";
import newDetection from "@/assets/icons/cards/newArticle.svg";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { usePlayersStore } from "@/store/players";
export default {
  name: "dashboard-page",
  components: {
    PlayersTable,
    LoaderModal,
  },
  setup() {
    const playersStore = usePlayersStore();
    return {
      playersStore,
      newDetection,
    };
  },
  data() {
    return {};
  },
};
</script>
