<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.796 2.24405C12.653 1.82605 14 3.42205 14 5.00005V19C14 20.578 12.653 22.174 10.796 21.7561C6.334 20.7521 3 16.7661 3 12.0001C3 7.23405 6.334 3.24805 10.796 2.24405ZM16.293 8.29305C16.4805 8.10558 16.7348 8.00026 17 8.00026C17.2652 8.00026 17.5195 8.10558 17.707 8.29305L20.707 11.2931C20.8945 11.4806 20.9998 11.7349 20.9998 12.0001C20.9998 12.2652 20.8945 12.5195 20.707 12.7071L17.707 15.7071C17.5184 15.8892 17.2658 15.99 17.0036 15.9877C16.7414 15.9854 16.4906 15.8803 16.3052 15.6949C16.1198 15.5095 16.0146 15.2586 16.0123 14.9965C16.01 14.7343 16.1108 14.4817 16.293 14.2931L17.586 13.0001H9C8.73478 13.0001 8.48043 12.8947 8.29289 12.7072C8.10536 12.5196 8 12.2653 8 12.0001C8 11.7348 8.10536 11.4805 8.29289 11.2929C8.48043 11.1054 8.73478 11.0001 9 11.0001H17.586L16.293 9.70705C16.1055 9.51952 16.0002 9.26521 16.0002 9.00005C16.0002 8.73489 16.1055 8.48058 16.293 8.29305Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
<style></style>
