import { createRouter, createWebHistory } from "vue-router";
// layouts
import AdminLayout from "../layouts/AdminLayout.vue";
import LoginLayout from "../layouts/LoginLayout.vue";
// views

///1-login
import LoginView from "../views/login/LoginView.vue";
import ForgotPassword from "../views/login/ForgotPaswordView.vue";
import NewPassword from "../views/login/NewPasswordView.vue";
import ConfirmCode from "../views/login/ConfirmCodeView.vue";
///2-dashboard
import DashboardView from "../views/dashboard/DashboardView.vue";
import NewsView from "../views/dashboard/NewsView.vue";
import EditNewsView from "../views/dashboard/EditNewsView.vue";

import DetectionView from "../views/dashboard/DetectionView.vue";
import EditDetectionView from "../views/dashboard/EditDetectionView.vue";
import PlayersView from "../views/dashboard/PlayersView.vue";
import SinglePlayerView from "../views/dashboard/SinglePlayerView.vue";
import ClubsView from "../views/dashboard/ClubsView.vue";
import ClubsJobsView from "../views/dashboard/ClubsJobsView.vue";
import SingleJobView from "../views/dashboard/SingleJobView.vue";
import AgentsView from "../views/dashboard/AgentsView.vue";
import OthersView from "../views/dashboard/OthersView.vue";
import SingleOtherUserView from "../views/dashboard/SingleOtherUserView.vue";
import MessagingView from "../views/dashboard/MessagingView.vue";
import ParametersView from "../views/dashboard/ParametersView.vue";
import AbonnementView from "../views/dashboard/AbonnementView.vue";
import EditAbonnement from "../views/dashboard/EditAbonnement.vue";
import NotificationsView from "../views/dashboard/NotificationsView.vue";
import { useAuthStore } from "@/store/auth";

// import { useAuthStore } from "@/store/auth";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/auth",
  },
  {
    path: "/auth",
    name: "auth",
    redirect: "/auth/login",
    component: LoginLayout,
    children: [
      {
        path: "/auth/login",
        name: "login",
        component: LoginView,
      },
      {
        path: "/auth/forgotPassword",
        name: "ForgotPassword",
        component: ForgotPassword,
      },
      {
        path: "/auth/confirmCode",
        name: "confirmCode",
        component: ConfirmCode,
      },
      {
        path: "/auth/newPassword",
        name: "NewPassword",
        component: NewPassword,
      },
    ],
  },

  {
    path: "/admin",
    redirect: "/admin/dashboard",
    meta: { requiresAuth: true },
    component: AdminLayout,
    children: [
      {
        path: "/admin/dashboard",
        component: DashboardView,
      },
      {
        path: "/admin/news",
        component: NewsView,
      },
      {
        path: "/admin/news/:id",
        component: EditNewsView,
      },
      {
        path: "/admin/detection",
        component: DetectionView,
      },
      {
        path: "/admin/detection/:id",
        component: EditDetectionView,
      },
      {
        path: "/admin/joueurs",
        component: PlayersView,
      },
      { path: "/admin/joueurs/:id", component: SinglePlayerView },
      { path: "/admin/clubs", component: ClubsView },
      { path: "/admin/clubs/:id", component: ClubsJobsView },
      { path: "/admin/clubs/offer/:id", component: SingleJobView },
      { path: "/admin/agents", component: AgentsView },
      { path: "/admin/autres", component: OthersView },
      { path: "/admin/autres/:id", component: SingleOtherUserView },
      { path: "/admin/messagerie", component: MessagingView },
      { path: "/admin/parameters", component: ParametersView },
      { path: "/admin/abonnement", component: AbonnementView },
      { path: "/admin/abonnement/:id", component: EditAbonnement },
      { path: "/admin/notifications", component: NotificationsView },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const checkPermissions = (permissions, route) => {
  if (
    route.startsWith("/auth/login") ||
    route.startsWith("/admin/parameters") ||
    route.startsWith("/admin/notifications") ||
    route.startsWith("/admin/dashboard")
  ) {
    return true;
  }
  if (route.startsWith("/admin/news")) {
    console.log(permissions.user.permissions.includes("1"));
    return permissions.user.permissions.includes("1");
  } else if (route.startsWith("/admin/detection")) {
    return permissions.user.permissions.includes("2");
  } else if (route.startsWith("/admin/joueurs")) {
    return permissions.user.permissions.includes("3");
  } else if (route.startsWith("/admin/clubs")) {
    return permissions.user.permissions.includes("4");
  } else if (route.startsWith("/admin/agents")) {
    return permissions.user.permissions.includes("5");
  } else if (route.startsWith("/admin/autres")) {
    return permissions.user.permissions.includes("6");
  } else if (route.startsWith("/admin/messagerie")) {
    return permissions.user.permissions.includes("7");
  } else if (route.startsWith("/admin/abonnement")) {
    return permissions.user.permissions.includes("8");
  } else {
    return false;
  }
};

router.beforeEach((to, from, next) => {
  const draftAdmin = localStorage.getItem("draft-admin");
  const authStore = useAuthStore();
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  const isAuthenticated = authStore.user || draftAdmin ? true : false;

  if (to.path.includes("includes") && isAuthenticated) {
    return next({ path: "/admin/dashboard" });
  }
  if (to.path.includes("auth")) {
    return next();
  }
  if (requiresAuth && !isAuthenticated) {
    next("/auth");
  } else {
    authStore.user = JSON.parse(draftAdmin);
    const hasPermission = checkPermissions(JSON.parse(draftAdmin), to.path);
    if (hasPermission) {
      return next();
    } else {
      return next({ path: "/admin/dashboard" });
    }
  }
});
export default router;
