<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <loader-modal v-if="othersStore.isLoadingModal" />

      <div class="w-full px-4">
        <div class="my-6 flex flex-row-reverse mt-24"></div>
        <others-table />
      </div>
    </div>
  </div>
</template>
<script>
import OthersTable from "@/components/tables/OthersTable.vue";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import { useOthersStore } from "@/store/others";

export default {
  name: "dashboard-page",
  components: {
    OthersTable,
    LoaderModal,
  },
  setup() {
    const othersStore = useOthersStore();
    return {
      othersStore,
    };
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
