<template>
  <svg
    width="23"
    height="16"
    viewBox="0 0 23 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 10.4916C23 11.0666 21.85 11.45 20.5083 11.6416C19.6458 10.0125 17.9208 8.76665 15.9083 7.90415C16.1 7.61665 16.2917 7.42498 16.4833 7.13748H17.25C20.2208 7.04165 23 8.86248 23 10.4916ZM6.51667 7.04165H5.75C2.77917 7.04165 0 8.86248 0 10.4916C0 11.0666 1.15 11.45 2.49167 11.6416C3.35417 10.0125 5.07917 8.76665 7.09167 7.90415L6.51667 7.04165ZM11.5 7.99998C13.6083 7.99998 15.3333 6.27498 15.3333 4.16665C15.3333 2.05831 13.6083 0.333313 11.5 0.333313C9.39167 0.333313 7.66667 2.05831 7.66667 4.16665C7.66667 6.27498 9.39167 7.99998 11.5 7.99998ZM11.5 8.95831C7.57083 8.95831 3.83333 11.45 3.83333 13.75C3.83333 15.6666 11.5 15.6666 11.5 15.6666C11.5 15.6666 19.1667 15.6666 19.1667 13.75C19.1667 11.45 15.4292 8.95831 11.5 8.95831ZM16.9625 6.08331H17.25C18.8792 6.08331 20.125 4.83748 20.125 3.20831C20.125 1.57915 18.8792 0.333313 17.25 0.333313C16.7708 0.333313 16.3875 0.429146 16.0042 0.620813C16.7708 1.57915 17.25 2.82498 17.25 4.16665C17.25 4.83748 17.1542 5.50831 16.9625 6.08331ZM5.75 6.08331H6.0375C5.84583 5.50831 5.75 4.83748 5.75 4.16665C5.75 2.82498 6.22917 1.57915 6.99583 0.620813C6.6125 0.429146 6.22917 0.333313 5.75 0.333313C4.12083 0.333313 2.875 1.57915 2.875 3.20831C2.875 4.83748 4.12083 6.08331 5.75 6.08331Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
<style></style>
