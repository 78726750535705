import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router";

export const useOthersStore = defineStore("othersStore", {
  state: () => ({
    others: [],
    itemsPerPage: 10,
    currentPage: 1,
    isLoadingModal: false,
    isSuccess: false,
    isFail: false,
    isShowEditor: false,
    addError: false,
    addSuccess: false,
    editedOther: null,
    singleOther: null,
    // theses are for filtering
    selectedPlan: null,
    selectedNationality: null,
    selectedDomain: null,
    selectedDate: null,
  }),
  actions: {
    async getOthers() {
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/others?page=${
            this.currentPage
          }&plan=${this.selectedPlan ? this.selectedPlan : ""}&nationality=${
            this.selectedNationality ? this.selectedNationality : ""
          }&domain=${
            this.selectedDomain ? this.selectedDomain : ""
          }&createdAt=${this.selectedDate ? this.selectedDate : ""}  `,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.others = data.others.results;
        this.totalPages = data.others.totalPages;
        this.currentPage = data.others.currentPage;
        this.isLoadingModal = false;
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    },
    async blockOther(otherId) {
      console.log(otherId);
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/others/block`,
          {
            otherId: otherId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        await this.getOthers();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    async unBlockOther(otherId) {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/others/unblock`,
          {
            otherId: otherId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getOthers();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    async getOneOther(otherId) {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/others/${otherId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.singleOther = data.other;
        console.log(data);
      } catch (err) {
        this.isLoadingModal = false;
        router.push("/admin/autres");
        console.log(err);
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getOther();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getOther();
      }
    },
  },
});
