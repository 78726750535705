<template>
  <div class="career-filter mx-2">
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="FilterCareerOther"
      v-on:click="toggleDropdown($event)"
    >
      <button class="btn-outline rounded-xl py-1 px-3 flex items-center">
        <img :src="career" alt="sportFilter" class="mr-2" />
        Fonction
        <img
          v-if="othersStore.selectedDomain == 'football'"
          :src="check"
          class="mr-2"
          alt="check"
        />
        <img
          :src="filterOpen"
          v-if="dropdownPopoverShow"
          alt="filterOpen"
          class="ml-2"
        />
      </button>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <div class="px-10 border-b py-3 flex" @click="filterOthers('Médical')">
        <img
          v-if="othersStore.selectedDomain == 'Médical'"
          :src="check"
          class="mr-2"
          alt="check"
        />Médical
      </div>
      <div
        class="px-10 border-b py-3 flex"
        @click="filterOthers('Marketing et communication')"
      >
        <img
          v-if="othersStore.selectedDomain == 'Marketing et communication'"
          :src="check"
          class="mr-2"
          alt="check"
        />Marketing et communication
      </div>
      <div class="px-10 border-b py-3 flex" @click="filterOthers('Légal')">
        <img
          v-if="othersStore.selectedDomain == 'Légal'"
          :src="check"
          class="mr-2"
          alt="check"
        />Légal
      </div>
      <div class="px-10 border-b py-3 flex" @click="filterOthers('Staff')">
        <img
          v-if="othersStore.selectedDomain == 'Staff'"
          :src="check"
          class="mr-2"
          alt="check"
        />Staff
      </div>
      <div class="px-10 border-b py-3 flex" @click="filterOthers('E-Sport')">
        <img
          v-if="othersStore.selectedDomain == 'E-Sport'"
          :src="check"
          class="mr-2"
          alt="check"
        />E-Sport
      </div>
      <div class="px-10 border-b py-3 flex" @click="filterOthers(null)">
        <img
          v-if="othersStore.selectedDomain == null"
          :src="check"
          class="mr-2"
          alt="check"
        />Autres
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import filterClosed from "@/assets/icons/cards/filterClosed.svg";
import filterOpen from "@/assets/icons/cards/filterOpen.svg";
import career from "@/assets/icons/cards/career.svg";
import { useOthersStore } from "@/store/others";
import check from "@/assets/icons/cards/permissionCheck.svg";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  setup() {
    const othersStore = useOthersStore();
    return {
      check,
      othersStore,
      career,
      filterOpen,
      filterClosed,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".career-filter");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-end",
        });
      }
    },
    filterOthers(filter) {
      this.othersStore.selectedDomain = filter;
      this.othersStore.getOthers();
      this.dropdownPopoverShow = false;
    },
  },
};
</script>
