<template>
  <div class="col-start-1 col-end-13 p-1 rounded-lg">
    <div class="flex items-center justify-start flex-row-reverse">
      <div
        class="relative mr-3 text-sm bg-primary py-2 px-4 shadow rounded-br-none rounded-xl text-white break-words lg:max-w-[500px]"
      >
        <div>{{ message }}</div>
        <div
          class="absolute text-xs bottom-0 right-0 -mb-5 mr-2 text-gray-500 invisible"
        >
          Seen
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import player2 from "@/assets/images/player2.png";

export default {
  props: ["message"],
  setup() {
    return {
      player2,
    };
  },
};
</script>
<style></style>
