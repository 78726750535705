<template>
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6746 7.34792C12.6746 8.82435 11.4761 10.0208 9.99841 10.0208C8.52231 10.0208 7.32581 8.82402 7.32581 7.34792C7.32581 5.87181 8.52231 4.67499 9.99841 4.67499C11.4761 4.67499 12.6746 5.87181 12.6746 7.34792Z"
      :fill="color"
    />
    <path
      d="M10.0026 0.852234C8.76559 0.852234 7.03774 1.34535 5.78617 2.59693L7.27264 5.30517C7.59746 4.89181 8.01202 4.55771 8.48497 4.32814C8.95792 4.09857 9.47687 3.97954 10.0026 3.98005C11.1233 3.98005 12.1205 4.50945 12.7559 5.33271L14.2138 2.53472C13.1307 1.35118 11.1884 0.852234 10.0026 0.852234ZM11.1042 2.84089H10.4121V3.53229H9.58075V2.84089H8.88871V2.00986H9.58075V1.31554H10.4121V2.00986H11.1042V2.84089ZM6.48599 14.825H7.17058L6.19277 18.1764H13.8328L12.8595 14.825H13.5448L14.5242 18.1764H16.7724L15.238 13.0988C14.9982 12.2606 13.9472 10.7848 12.1335 10.7401L7.96371 10.7366C6.10886 10.7456 5.0358 12.2503 4.79313 13.0985L3.2289 18.1761H5.50883L6.48599 14.825ZM0 19.2449H20V21.1477H0V19.2449Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
<style></style>
