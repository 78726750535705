import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router";

export const useClubsStore = defineStore("clubsStore", {
  state: () => ({
    clubs: [],
    itemsPerPage: 10,
    currentPage: 1,
    isLoadingModal: false,
    isSuccess: false,
    isFail: false,
    isShowEditor: false,
    addError: false,
    addSuccess: false,
    editedClub: null,
    singleClub: null,
    // theses are for filtering
    selectedPlan: null,
    selectedNationality: null,
    selectedSport: null,
    selectedDate: null,
  }),
  actions: {
    async getClubs() {
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/clubs?page=${
            this.currentPage
          }&nationality=${
            this.selectedNationality ? this.selectedNationality : ""
          }&sport=${this.selectedSport ? this.selectedSport : ""}&createdAt=${
            this.selectedDate ? this.selectedDate : ""
          }  `,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.clubs = data.clubs.results;
        this.totalPages = data.clubs.totalPages;
        this.currentPage = data.clubs.currentPage;
        this.isLoadingModal = false;
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    },
    async blockClub(clubId) {
      console.log(clubId);
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/clubs/block`,
          {
            clubId: clubId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        await this.getClubs();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    async unBlockClub(clubId) {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/clubs/unblock`,
          {
            clubId: clubId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getClubs();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    async getOneClub(clubId) {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/clubs/${clubId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.singleClub = data.club;
        console.log(data);
      } catch (err) {
        this.isLoadingModal = false;
        router.push("/admin/clubs");
        console.log(err);
      }
    },
    async pauseJobOffer(jobOfferId) {
      this.isFail = false;

      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        console.log(token);

        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/clubs/pause/${jobOfferId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
      } catch (err) {
        this.isFail = true;
        console.log(err);
      }
    },
    async unPauseJobOffer(jobOfferId) {
      this.isFail = false;

      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/clubs/unpause/${jobOfferId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        console.log(data);
      } catch (err) {
        this.isFail = true;
        console.log(err);
      }
    },
    async getJobOffer(jobOfferId) {
      this.isFail = false;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/clubs/offer/${jobOfferId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        return data.jobOffer;
      } catch (err) {
        this.isFail = true;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getClub();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getClub();
      }
    },
  },
});
