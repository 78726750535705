<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 10C20 15.523 15.523 20 10 20C8.40001 20 6.88801 19.624 5.54801 18.956C5.19404 18.7731 4.78542 18.7258 4.39901 18.823L2.17301 19.419C1.95268 19.4778 1.72077 19.4776 1.50054 19.4184C1.28031 19.3593 1.07952 19.2432 0.918327 19.0819C0.757131 18.9206 0.641199 18.7197 0.582168 18.4995C0.523136 18.2792 0.523083 18.0473 0.582013 17.827L1.17701 15.601C1.27379 15.2148 1.22613 14.8065 1.04301 14.453C0.354644 13.0697 -0.00243531 11.5451 1.25004e-05 10C1.25004e-05 4.477 4.47701 0 10 0C15.523 0 20 4.477 20 10ZM10 5.25C10.1989 5.25 10.3897 5.32902 10.5303 5.46967C10.671 5.61032 10.75 5.80109 10.75 6V6.01C11.839 6.285 12.75 7.143 12.75 8.333C12.75 8.53191 12.671 8.72268 12.5303 8.86333C12.3897 9.00398 12.1989 9.083 12 9.083C11.8011 9.083 11.6103 9.00398 11.4697 8.86333C11.329 8.72268 11.25 8.53191 11.25 8.333C11.25 7.949 10.824 7.417 10 7.417C9.17601 7.417 8.75001 7.949 8.75001 8.333C8.75001 8.717 9.17601 9.25 10 9.25C11.385 9.25 12.75 10.21 12.75 11.667C12.75 12.857 11.839 13.716 10.75 13.99V14C10.75 14.1989 10.671 14.3897 10.5303 14.5303C10.3897 14.671 10.1989 14.75 10 14.75C9.8011 14.75 9.61034 14.671 9.46968 14.5303C9.32903 14.3897 9.25001 14.1989 9.25001 14V13.99C8.16101 13.716 7.25001 12.857 7.25001 11.667C7.25001 11.4681 7.32903 11.2773 7.46968 11.1367C7.61033 10.996 7.8011 10.917 8.00001 10.917C8.19893 10.917 8.38969 10.996 8.53034 11.1367C8.67099 11.2773 8.75001 11.4681 8.75001 11.667C8.75001 12.051 9.17601 12.583 10 12.583C10.824 12.583 11.25 12.051 11.25 11.667C11.25 11.283 10.824 10.75 10 10.75C8.61501 10.75 7.25001 9.79 7.25001 8.333C7.25001 7.143 8.16101 6.285 9.25001 6.01V6C9.25001 5.80109 9.32903 5.61032 9.46968 5.46967C9.61034 5.32902 9.8011 5.25 10 5.25Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
<style></style>
