<template>
  <div>
    <div class="flex flex-wrap mt-4 text-black">
      <div class="w-full px-4">
        <div class="my-6 mt-24">
          <p class="text-xl font-medium">Notifications</p>
          <div class="flex justify-center">
            <ChatsListLoader v-if="notificationsStore.isLoading" />
          </div>
          <div
            v-if="
              notificationsStore.notifications.length > 0 &&
              !notificationsStore.isLoading
            "
          >
            <div
              v-for="(notification, index) in notificationsStore.notifications"
              :key="index"
            >
              <NotficationCard :key="index"   :notification="notification" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import exitBlack from "@/assets/icons/cards/exitBlack.svg";
import bell from "@/assets/icons/cards/bell.svg";
import NotficationCard from "@/components/cards/NotificationCard.vue";
import { useNotificationsStore } from "@/store/notifications";
import ChatsListLoader from "@/components/loaders/ChatsListLoader.vue";
export default {
  components: {
    NotficationCard,
    ChatsListLoader,
  },
  async mounted() {
    await this.notificationsStore.getNotifications();
  },
  setup() {
    const notificationsStore = useNotificationsStore();
    return { bell, exitBlack, notificationsStore };
  },
};
</script>
<style></style>
