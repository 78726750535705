<template>
  <div></div>
</template>
<script>
import { useNewsStore } from "../../../store/news";
export default {
  setup() {
    const newsStore = useNewsStore();
    const { addError } = useNewsStore();
    const { addSuccess } = useNewsStore();
    return {
      newsStore,
      addError,
      addSuccess,
    };
  },
  mounted() {
    if (this.newsStore.addSuccess) {
      this.$swal({
        icon: "success",
        title: "Article ajouté avec succés",
        showConfirmButton: false,
        timer: 1500,
        willClose: () => {
          this.newsStore.addError = false;
          this.newsStore.addSuccess = false;
          this.newsStore.isShowEditor = false;
        },
      });
    }
  },
};
</script>
