<template>
  <div class="news-action">
    <DeleteArticleModal
      :newsId="newsId"
      v-if="showDeleteModal"
      @close="showDeleteModal = false"
    >
    </DeleteArticleModal>
    <PauseArticleModal
      :newsId="newsId"
      v-if="showPauseModal"
      @close="showPauseModal = false"
    />
    <UnPauseArticleModal
      :newsId="newsId"
      v-if="showUnPauseModal"
      @close="showUnPauseModal = false"
    />
    <a
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
      v-on:close="closeDropDown"
    >
      <div class="items-center flex justify-center">
        <img :src="actions" alt="actions" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
      }"
    >
      <router-link
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
        :to="`/admin/news/${newsId}`"
      >
        <img :src="edit" class="mr-2" alt="edit" />Modifier</router-link
      >
      <a
        href="javascript:void(0);"
        v-if="!isPaused"
        @click="showPauseModal = true"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="pause" class="mr-2" alt="pause" />

        Mettre en pause </a
      ><a
        href="javascript:void(0);"
        v-if="isPaused"
        @click="showUnPauseModal = true"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="play" class="mr-2" alt="play" />

        Activer l'article </a
      ><a
        href="javascript:void(0);"
        @click="showDeleteModal = true"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="deleteIcon" class="mr-2" alt="delete" />

        Supprimer
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import actions from "@/assets/icons/cards/threeDots.svg";
import edit from "@/assets/icons/cards/edit.svg";
import pause from "@/assets/icons/cards/pause.svg";
import play from "@/assets/icons/cards/play.svg";
import deleteIcon from "@/assets/icons/cards/delete.svg";
import DeleteArticleModal from "../modals/news/DeleteArticleModal.vue";
import PauseArticleModal from "../modals/news/PauseArticleModal.vue";
import UnPauseArticleModal from "../modals/news/UnPauseArticleModal.vue";
import { useNewsStore } from "@/store/news";
export default {
  props: ["newsId", "isPaused", "editedNews"],
  mounted() {
    this.newsStore.editedNews = this.editedNews;
  },
  components: {
    DeleteArticleModal,
    PauseArticleModal,
    UnPauseArticleModal,
  },
  data() {
    return {
      dropdownPopoverShow: false,
      showDeleteModal: false,
      showPauseModal: false,
      showUnPauseModal: false,
    };
  },
  setup() {
    const newsStore = useNewsStore();
    return {
      newsStore,
      actions,
      pause,
      edit,
      play,
      deleteIcon,
    };
  },
  methods: {
    closeDropDown() {
      this.dropdownPopoverShow = false;
    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".news-action");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
