<template>
  <div></div>
</template>
<script>
import { useNewsStore } from "@/store/news";
export default {
  setup() {
    const newsStore = useNewsStore();
    const { isEditFail, isEditSuccess } = useNewsStore();
    return {
      newsStore,
      isEditFail,
      isEditSuccess,
    };
  },
  mounted() {
    if (this.newsStore.isEditFail) {
      this.$swal({
        icon: "error",
        title: "quelque chose s'est mal passé",
        showConfirmButton: true,
        timer: 1500,
        willClose: () => {
          this.newsStore.isEditFail = false;
          this.newsStore.isEditSuccess = false;
        },
      });
    }
  },
};
</script>
