<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <AddNewsSuccess v-if="newsStore.addSuccess" />
      <loader-modal v-if="newsStore.isLoadingModal" />
      <div class="w-full mb-12 xl:mb-0 px-4">
        <div class="my-6 flex flex-row-reverse">
          <button
            @click="toggleTextEditor"
            class="btn flex items-center cursor-pointer"
            v-if="!newsStore.isShowEditor"
          >
            <img :src="newArticle" alt="newArticle" class="mr-2 font-medium" />
            Nouvel article</button
          ><button
            @click="toggleTextEditor"
            v-if="newsStore.isShowEditor"
            class="btn flex items-center cursor-pointer"
          >
            Retourner au liste
          </button>
        </div>
        <card-news-table v-if="!newsStore.isShowEditor" />
        <text-editor-card v-if="newsStore.isShowEditor" />
      </div>
    </div>
  </div>
</template>
<script>
import CardNewsTable from "@/components/tables/NewsTable.vue";
import TextEditorCard from "@/components/cards/TextEditorCard.vue";
import newArticle from "@/assets/icons/cards/newArticle.svg";
import { useNewsStore } from "@/store/news";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import AddNewsSuccess from "@/components/modals/news/AddNewsSuccess.vue";

export default {
  name: "news-page",

  components: {
    CardNewsTable,
    TextEditorCard,
    LoaderModal,
    AddNewsSuccess,
  },
  methods: {
    toggleTextEditor() {
      this.newsStore.isShowEditor = !this.newsStore.isShowEditor;
    },
  },
  setup() {
    const newsStore = useNewsStore();
    return {
      newsStore,
      newArticle,
    };
  },
};
</script>
<style>
.ql-container {
  min-height: 500px !important;
}
</style>
