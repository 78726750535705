<template>
  <div>
    <div
      :class="chat.id == chatStore.currentChat.id ? 'bg-[#EDF1FE]' : ''"
      class="flex flex-row items-center p-4 relative cursor-pointer"
    >
      <div v-if="chat.receiver.profile_picture" class="h-10 w-10">
        <img
          :src="api + chat.receiver.profile_picture"
          class="mr-2 rounded-full"
          alt="player2"
        />
      </div>

      <img
        v-if="!chat.receiver.profile_picture"
        :src="person"
        class="mr-2"
        alt="player2"
      />
      <div class="flex flex-col flex-grow ml-3">
        <div class="text-sm font-medium text-primary mb-3">
          {{ chat.receiver.first_name }}
          {{ chat.receiver.last_name }}
        </div>
        <div class="text-xs truncate w-40">
          {{ chat.latestMessage }}
        </div>
      </div>
      <div>
        <div class="text-xs text-gray-500 mb-3">
          {{ "Il y a " + getTimeAgo(chat.updatedAt) }}
        </div>

        <div>
          <span
            :class="
              chat.id == chatStore.currentChat.id ? 'bg-[#EDF1FE]' : 'bg-white'
            "
            class="flex ml-auto items-center justify-center h-5 w-5 text-white text-xs rounded-full"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import person from "@/assets/icons/cards/person.svg";
import { useChatStore } from "@/store/chat";
export default {
  props: ["chat"],

  setup() {
    const api = process.env.VUE_APP_API_BASE;
    const chatStore = useChatStore();
    return {
      chatStore,
      person,
      api,
    };
  },
  methods: {
    getTimeAgo(timestamp) {
      const now = new Date();
      const messageTime = new Date(timestamp);
      const timeDifference = now - messageTime;

      const millisecondsInMinute = 60 * 1000;
      const millisecondsInHour = 60 * 60 * 1000;
      const millisecondsInDay = 24 * millisecondsInHour;

      if (timeDifference < millisecondsInHour) {
        const minutesAgo = Math.floor(timeDifference / millisecondsInMinute);
        return `${minutesAgo} ${minutesAgo === 1 ? "minute" : "minutes"} `;
      } else if (timeDifference < millisecondsInDay) {
        const hoursAgo = Math.floor(timeDifference / millisecondsInHour);
        return `${hoursAgo} ${hoursAgo === 1 ? "heure" : "heures"} `;
      } else {
        const daysAgo = Math.floor(timeDifference / millisecondsInDay);
        return `${daysAgo} ${daysAgo === 1 ? "jour" : "jours"}`;
      }
    },
  },
};
</script>
<style></style>
