<template>
  <AddDetectionSuccess v-if="detectionStore.addSuccess" />
  <AddDetectionFail v-if="detectionStore.addError" />
  <LoaderModal v-if="detectionStore.isLoadingModal" />

  <div>
    <h1 class="text-3xl font-medium">Créer une nouvelle détection</h1>
    <p class="text-[#7c7c7c] mb-2 mt-6">Titre de la détection</p>
    <input
      class="border rounded-md py-3 px-2 focus:outline-none w-full"
      type="text"
      v-model="detection.title"
    />
    <p class="text-red-500" v-if="v$.detection.title.$error">
      Le titre doit comporter au moins 6 caractères
    </p>
    <p class="text-[#7c7c7c] mb-2 mt-6">Description</p>

    <textarea
      class="border rounded-md py-3 px-2 focus:outline-none w-full"
      name=""
      id=""
      cols="20"
      rows="7"
      v-model="detection.description"
    ></textarea>
    <p class="text-red-500" v-if="v$.detection.description.$error">
      La description doit comporter au moins 6 caractères
    </p>
    <p class="text-[#7c7c7c] mb-2 mt-6">Date de la détection</p>
    <VueDatePicker
      v-model="detection.date"
      :min-date="getTomorrow()"
      model-type="yyyy/MM/dd"
      :enable-time-picker="false"
    />
    <p class="text-red-500" v-if="v$.detection.date.$error">Date est réquise</p>
    <p class="text-[#7c7c7c] mb-2 mt-6">Heure de la détection</p>
    <VueDatePicker v-model="detection.time" time-picker />
    <p class="text-red-500" v-if="timeError && !detection.time">
      Heure est requise
    </p>

    <p class="text-[#7c7c7c] mb-2 mt-6">Lien de lieu d'événement sur la map</p>
    <input
      class="border rounded-md py-3 px-2 focus:outline-none w-full"
      type="text"
      placeholder="lien maps"
      v-model="detection.placeUrl"
    />
    <p class="text-red-500" v-if="v$.detection.placeUrl.$error">
      Lien de lieu d'événement est réquis et doit être une URL valide au format
      Google Maps.
    </p>

    <p class="text-[#7c7c7c] mb-2 mt-6">Nom de lieu</p>
    <input
      class="border rounded-md py-3 px-2 focus:outline-none w-full"
      type="text"
      placeholder="lien maps"
      v-model="detection.place"
    />
    <p class="text-red-500" v-if="v$.detection.place.$error">
      Nom du Lieu de l'événement est réquis
    </p>
    <div>
      <button class="btn my-6 disabled:bg-gray-300" @click="addDetection">
        Publier
      </button>
    </div>
  </div>
</template>
<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useDetectionStore } from "@/store/detection";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, url } from "@vuelidate/validators";
import AddDetectionSuccess from "../modals/detections/AddDetectionSuccess.vue";
import AddDetectionFail from "../modals/detections/AddDetectionFail.vue";
import LoaderModal from "../modals/LoaderModal.vue";
export default {
  components: {
    VueDatePicker,
    AddDetectionSuccess,
    AddDetectionFail,
    LoaderModal,
  },
  setup() {
    const detectionStore = useDetectionStore();
    return { v$: useVuelidate(), detectionStore };
  },

  data() {
    return {
      detection: {
        title: "",
        description: "",
        place: "",
        date: "",
        placeUrl: "",
        time: null,
      },
      timeError: false,
    };
  },
  methods: {
    async addDetection() {
      const result = await this.v$.$validate();
      if (!this.detection.time) {
        this.timeError = true;
        return;
      }
      if (!result) {
        return;
      }
      const regex = /@([-0-9.]+),([-0-9.]+)/;

      // Use the regular expression to match and extract lat/lng
      const match = this.detection.placeUrl.match(regex);
      console.log(match);
      if (match) {
        const latitude = match[1];
        const longitude = match[2];
        this.detection = {
          ...this.detection,
          latitude: latitude,
          longitude: longitude,
        };
      } else {
        return console.log("Latitude and Longitude not found in the URL.");
      }
      this.detectionStore.addDetection(this.detection);
      this.detection.title = "";
      this.detection.description = "";
      this.detection.date = "";
      this.detection.time = null;
      this.detection.place = "";
      this.detection.placeUrl = "";
    },
    getTomorrow() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow;
    },
  },
  validations() {
    // Define a custom regex pattern for a Google Maps URL
    const googleMapsUrlPattern =
      /^https?:\/\/www\.google\.com\/maps\/@[-0-9.]+,[-0-9.]+,\d+z\?entry=ttu$/;

    // Create a custom validation rule for a Google Maps URL
    const googleMapsUrl = (value) => {
      return googleMapsUrlPattern.test(value);
    };
    return {
      detection: {
        title: { required, minLength: minLength(6) },
        description: { required, minLength: minLength(6) },
        place: { required, minLength: minLength(6) },
        placeUrl: { required, minLength: minLength(6), url, googleMapsUrl },
        date: { required, minLength: minLength(6) },
      },
    };
  },
};
</script>
<style>
/*
.dp__input_icon {
  right: 0 !important;
}*/
</style>
