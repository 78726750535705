<template>
  <div class="user-actions">
    <BlockClubModal
      :clubId="clubId"
      v-if="showBlockModal"
      @close="showBlockModal = false"
    />
    <UnBlockClubModal
      :clubId="clubId"
      v-if="showUnBlockModal"
      @close="showUnBlockModal = false"
    />
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex justify-center">
        <img :src="actions" alt="actions" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
        :to="'/admin/clubs/' + clubId"
      >
        <img :src="consult" class="mr-2" alt="consult" /> Consulter</router-link
      >
      <a
        href="javascript:void(0);"
        v-if="!is_blocked"
        @click="showBlockModal = true"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="block" class="mr-2" alt="block" />

        Bloquer </a
      ><a
        href="javascript:void(0);"
        v-if="is_blocked"
        @click="showUnBlockModal = true"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="block" class="mr-2" alt="block" />

        Débloquer
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import actions from "@/assets/icons/cards/threeDots.svg";
import consult from "@/assets/icons/cards/consult.svg";
import block from "@/assets/icons/cards/block.svg";
import BlockClubModal from "../modals/clubs/BlockClubModal.vue";
import UnBlockClubModal from "../modals/clubs/UnBlockClubModal.vue";

export default {
  props: ["clubId", "is_blocked"],
  components: { BlockClubModal, UnBlockClubModal },
  data() {
    return {
      dropdownPopoverShow: false,
      showBlockModal: false,
      showUnBlockModal: false,
    };
  },
  setup() {
    return {
      actions,
      block,
      consult,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".user-actions");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
