<template>
  <div class="mod-toast"></div>
</template>
<script>
import { useModeratorsStore } from "@/store/moderators";
export default {
  setup() {
    const modsStore = useModeratorsStore();
    return { modsStore };
  },
  mounted() {
    if (this.modsStore.isFail) {
      this.$swal({
        icon: "error",
        title: "Quelque chose s'est mal passé",
        showConfirmButton: false,
        timer: 1500,
        willClose: () => {
          this.modsStore.isFail = false;
          this.modsStore.isSuccess = false;
        },
      });
    }
  },
};
</script>
<style scoped>
.swal2-container {
  z-index: 100000000000 !important;
}
.swal-overlay {
  z-index: 100000000000 !important;
}
</style>
