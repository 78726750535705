<template>
  <nav
    v-if="authStore.user?.user?.permissions"
    class="md:left-0 shadow-2xl md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-4"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
      >
        <img :src="menuBurger" alt="menuBurger" />
      </button>
      <!-- Brand -->
      <router-link
        class="hidden md:flex justify-center items-center text-left md:pb-2 text-blueGray-600 mr-0 whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
        to="/"
      >
        <img :src="logo" class="inline" alt="logo" />
      </router-link>
      <hr class="my-4 md:min-w-full" />
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        v-bind:class="collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                to="/"
              >
                Draft
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="toggleCollapseShow('hidden')"
              >
                <img :src="closeMenu" alt="closeMenu" />
              </button>
            </div>
          </div>
        </div>

        <!-- Navigation -->

        <ul
          class="md:flex-col md:min-w-full mt-10 flex flex-col list-none text-[#999999]"
        >
          <li class="items-center">
            <router-link
              to="/admin/dashboard"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex"
                :class="[
                  isActive || $route.path.includes('dashboard')
                    ? 'text-white bg-primary font-semibold rounded-md'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <dashboard-icon
                  class="mx-2"
                  :color="
                    isActive || $route.path.includes('dashboard')
                      ? 'white'
                      : 'rgba(0, 0, 0, 0.4) '
                  "
                />
                Dashboard
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.user?.permissions.includes('1')"
            class="items-center"
          >
            <router-link to="/admin/news" v-slot="{ href, navigate, isActive }">
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex"
                :class="[
                  isActive || $route.path.includes('news')
                    ? 'text-white bg-primary font-semibold rounded-md'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <news-icon
                  class="mx-2"
                  :color="
                    isActive || $route.path.includes('news')
                      ? 'white'
                      : 'rgba(0, 0, 0, 0.4) '
                  "
                />
                News
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.user?.permissions.includes('2')"
            class="items-center"
          >
            <router-link
              to="/admin/detection"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex"
                :class="[
                  isActive || $route.path.includes('detection')
                    ? 'text-white bg-primary font-semibold rounded-md'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <detection-icon
                  class="mx-2"
                  :color="
                    isActive || $route.path.includes('detection')
                      ? 'white'
                      : 'rgba(0, 0, 0, 0.4) '
                  "
                />
                Détection
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.user?.permissions.includes('3')"
            class="items-center"
          >
            <router-link
              to="/admin/joueurs"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex"
                :class="[
                  isActive || $route.path.includes('joueurs')
                    ? 'text-white bg-primary font-semibold rounded-md'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <joueurs-icon
                  class="mx-2"
                  :color="
                    isActive || $route.path.includes('joueurs')
                      ? 'white'
                      : 'rgba(0, 0, 0, 0.4) '
                  "
                />
                Joueurs
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.user?.permissions.includes('4')"
            class="items-center"
          >
            <router-link
              to="/admin/clubs"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex"
                :class="[
                  isActive || $route.path.includes('clubs')
                    ? 'text-white bg-primary font-semibold rounded-md'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <clubs-icon
                  class="mx-2"
                  :color="
                    isActive || $route.path.includes('clubs')
                      ? 'white'
                      : 'rgba(0, 0, 0, 0.4) '
                  "
                />
                Clubs
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.user?.permissions.includes('5')"
            class="items-center"
          >
            <router-link
              to="/admin/agents"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex"
                :class="[
                  isActive || $route.path.includes('agents')
                    ? 'text-white bg-primary font-semibold rounded-md'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <agent-icon
                  class="mx-2"
                  :color="
                    isActive || $route.path.includes('agents')
                      ? 'white'
                      : 'rgba(0, 0, 0, 0.4) '
                  "
                />
                Agents
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.user?.permissions.includes('6')"
            class="items-center"
          >
            <router-link
              to="/admin/autres"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex"
                :class="[
                  isActive || $route.path.includes('autres')
                    ? 'text-white bg-primary font-semibold rounded-md'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <autres-icon
                  class="mx-2"
                  :color="
                    isActive || $route.path.includes('autres')
                      ? 'white'
                      : 'rgba(0, 0, 0, 0.4) '
                  "
                />
                Autres
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.user?.permissions.includes('7')"
            class="items-center"
          >
            <router-link
              to="/admin/messagerie"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex"
                :class="[
                  isActive || $route.path.includes('messagerie')
                    ? 'text-white bg-primary font-semibold rounded-md'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <messagerie-icon
                  class="mx-2"
                  :color="
                    isActive || $route.path.includes('messagerie')
                      ? 'white'
                      : 'rgba(0, 0, 0, 0.4) '
                  "
                />
                Messagerie
              </a>
            </router-link>
          </li>
          <li
            v-if="authStore.user?.user?.permissions.includes('8')"
            class="items-center"
          >
            <router-link
              to="/admin/abonnement"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex"
                :class="[
                  isActive || $route.path.includes('abonnement')
                    ? 'text-white bg-primary font-semibold rounded-md'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <abonnement-icon
                  class="mx-2"
                  :color="
                    isActive || $route.path.includes('abonnement')
                      ? 'white'
                      : 'rgba(0, 0, 0, 0.4) '
                  "
                />
                Abonnement
              </a>
            </router-link>
          </li>
          <li class="items-center">
            <router-link
              to="/admin/parameters"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="uppercase py-3 font-bold flex"
                :class="[
                  isActive || $route.path.includes('parameters')
                    ? 'text-white bg-primary font-semibold rounded-md'
                    : ' hover:text-blueGray-500',
                ]"
              >
                <parameters-icon
                  class="mx-2"
                  :color="
                    isActive || $route.path.includes('parameters')
                      ? 'white'
                      : 'rgba(0, 0, 0, 0.4) '
                  "
                />
                Paramètres
              </a>
            </router-link>
          </li>
          <li
            @click="authStore.disconnect"
            class="items-center my-10 uppercase py-3 font-bold flex cursor-pointer"
          >
            <disconnect-icon
              class="mx-2"
              :color="isActive ? 'white' : 'rgba(0, 0, 0, 0.4) '"
            />
            Se déconnecter
          </li>
        </ul>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
      </div>
    </div>
  </nav>
</template>

<script>
import logo from "@/assets/images/logo.png";
import DashboardIcon from "@/assets/icons/sidebar/DashboardIcon.vue";
import DetectionIcon from "@/assets/icons/sidebar/DetectionIcon.vue";
import JoueursIcon from "@/assets/icons/sidebar/JoueursIcon.vue";
import AbonnementIcon from "@/assets/icons/sidebar/AbonnementIcon.vue";
import ParametersIcon from "@/assets/icons/sidebar/ParametersIcon.vue";
import ClubsIcon from "@/assets/icons/sidebar/ClubsIcon.vue";
import AgentIcon from "@/assets/icons/sidebar/AgentIcon.vue";
import AutresIcon from "@/assets/icons/sidebar/AutresIcon.vue";
import MessagerieIcon from "@/assets/icons/sidebar/MessagerieIcon.vue";
import DisconnectIcon from "@/assets/icons/sidebar/DisconnectIcon.vue";
import NewsIcon from "@/assets/icons/sidebar/NewsIcon.vue";
import menuBurger from "@/assets/icons/sidebar/menuBurger.svg";
import closeMenu from "@/assets/icons/sidebar/closeMenu.svg";
import { useAuthStore } from "@/store/auth";
export default {
  components: {
    NewsIcon,
    DashboardIcon,
    DetectionIcon,
    JoueursIcon,
    AbonnementIcon,
    ParametersIcon,
    ClubsIcon,
    AgentIcon,
    AutresIcon,
    MessagerieIcon,
    DisconnectIcon,
  },
  data() {
    return {
      collapseShow: "hidden",
    };
  },
  setup() {
    const authStore = useAuthStore();
    return {
      logo,
      menuBurger,
      authStore,
      closeMenu,
    };
  },
  methods: {
    toggleCollapseShow: function (classes) {
      this.collapseShow = classes;
    },
  },
};
</script>
<style scoped>
.svg-active {
  fill: white !important;
}
.svg-inactive {
  fill: rgba(0, 0, 0, 0.4) !important;
}
.box-example2 {
  box-shadow: inset 0px 7px 9px -7px rgb(0 0 0 / 40%);
  height: 80px;
  border: 1px dashed grey;
  position: relative;
  margin-top: 8px;
}
nav::after {
  box-shadow: inset -7px 0px 090px -7px rgb(0 0 0 / 40%) !important;
}
</style>
