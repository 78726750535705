<template>
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7301 12.37C18.536 12.07 18.26 11.77 17.9023 11.58C17.6162 11.44 17.4322 11.21 17.2687 10.94C16.7475 10.08 17.0541 8.95 17.9228 8.44C18.9447 7.87 19.2718 6.6 18.679 5.61L17.9943 4.43C17.4118 3.44 16.1344 3.09 15.1226 3.67C14.2233 4.15 13.0685 3.83 12.5473 2.98C12.3838 2.7 12.2918 2.4 12.3122 2.1C12.3429 1.71 12.2203 1.34 12.0363 1.04C11.6582 0.42 10.9735 0 10.2172 0H8.77627C8.03024 0.02 7.34553 0.42 6.9674 1.04C6.77323 1.34 6.66081 1.71 6.68125 2.1C6.70169 2.4 6.60972 2.7 6.4462 2.98C5.925 3.83 4.77019 4.15 3.88109 3.67C2.85913 3.09 1.59191 3.44 0.99917 4.43L0.314459 5.61C-0.268057 6.6 0.0589696 7.87 1.07071 8.44C1.93937 8.95 2.24596 10.08 1.73498 10.94C1.56125 11.21 1.37729 11.44 1.09115 11.58C0.743681 11.77 0.437094 12.07 0.273581 12.37C-0.104544 12.99 -0.0841044 13.77 0.29402 14.42L0.99917 15.62C1.37729 16.26 2.08245 16.66 2.81825 16.66C3.16572 16.66 3.5745 16.56 3.90153 16.36C4.15702 16.19 4.46361 16.13 4.80085 16.13C5.81259 16.13 6.66081 16.96 6.68125 17.95C6.68125 19.1 7.62145 20 8.80692 20H10.1968C11.372 20 12.3122 19.1 12.3122 17.95C12.3429 16.96 13.1911 16.13 14.2029 16.13C14.5299 16.13 14.8365 16.19 15.1022 16.36C15.4292 16.56 15.8278 16.66 16.1855 16.66C16.911 16.66 17.6162 16.26 17.9943 15.62L18.7097 14.42C19.0776 13.75 19.1083 12.99 18.7301 12.37Z"
      :fill="color"
    />
    <path
      d="M9.57791 13C8.18853 13 7.06464 11.8938 7.06464 10.5044C7.06464 9.11504 8.18853 8 9.57791 8C10.9673 8 12.0646 9.11504 12.0646 10.5044C12.0646 11.8938 10.9673 13 9.57791 13Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
<style></style>
