<template>
  <div>
    <p class="text-center opacity-40" v-if="!this.authStore.successReset">
      Changez votre mot de passe.
    </p>
    <form
      @click.prevent="changePassword"
      class="flex flex-col items-center my-8"
    >
      <div v-if="!this.authStore.successReset">
        <label class="opacity-40" for="password">Nouveau mot de passe</label
        ><br />
        <input
          class="border rounded-md mb-4 mt-2 text-gray-700 py-3 px-2 leading-tight focus:outline-primary mx-auto lg:min-w-[400px]"
          type="password"
          v-model="password"
          name="password"
        />
        <div v-if="v$.password.$error" class="text-red-500">
          Password must be at least 6 characters long
        </div>
      </div>
      <div v-if="!this.authStore.successReset">
        <label class="opacity-40" for="passwordRepeat"
          >Réécrire le mot de passe</label
        ><br />
        <input
          class="border rounded-md mb-4 mt-2 text-gray-700 py-3 px-2 leading-tight focus:outline-primary mx-auto lg:min-w-[400px]"
          type="password"
          v-model="passwordRepeat"
          name="passwordRepeat"
        />
        <div v-if="v$.passwordRepeat.$error" class="text-red-500">
          Passwords do not match
        </div>
      </div>
      <p v-if="this.authStore.successReset">
        Votre mot de pass a été changé avec succés ! veuillez
        <span
          @click="redirectLogin"
          class="text-primary font-semibold cursor-pointer"
          >connecter</span
        >
        maintenant
      </p>
      <button
        type="submit"
        v-if="!this.authStore.successReset"
        class="btn my-6"
      >
        Changer mot de pass
      </button>
    </form>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { sameAs, minLength, required } from "@vuelidate/validators";
import { useAuthStore } from "@/store/auth";
import router from "@/router";
export default {
  data() {
    return {
      password: "",
      passwordRepeat: "",
    };
  },
  setup() {
    const authStore = useAuthStore();
    return { v$: useVuelidate(), authStore };
  },
  mounted() {
    this.authStore.error = null;
    if (!this.authStore.email || !this.authStore.resetCode) {
      router.push("/auth/login");
    }
  },
  methods: {
    async changePassword() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      this.authStore.resetPassword(this.password);
    },
    redirectLogin() {
      this.authStore.successReset = false;
      router.push("/auth");
    },
  },
  validations() {
    return {
      passwordRepeat: { sameAsPassword: sameAs(this.password), required },
      password: { minLength: minLength(6), required },
    };
  },
};
</script>
