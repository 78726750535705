<template>
  <div class=""></div>
</template>
<script>
import loading from "@/assets/icons/cards/loading.gif";
export default {
  setup() {
    return {
      loading,
    };
  },
  mounted() {
    this.showSweetAlert();
  },
  methods: {
    showSweetAlert() {
      this.$swal({
        html: "<img width='100' class='mx-auto' height='100' src='/img/loading.a9a09ae4.gif'>",
        buttons: false,
        closeOnClickOutside: false,
        showConfirmButton: false,
      });
    },
    closeSweetAlert() {
      this.$swal.close();
    },
  },
  beforeUnmount() {
    this.closeSweetAlert();
  },
};
</script>
