import { defineStore } from "pinia";
import axios from "axios";

export const useDetectionStore = defineStore("detectionStore", {
  state: () => ({
    detections: [],
    lastestDetection: [],
    detectionCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    currentPage: 1,
    isLoadingModal: false,
    isFailModal: false,
    pauseError: false,
    addError: false,
    addSuccess: false,
    isUpdateError: false,
    isUpdateSuccess: false,
    isShowEditor: false,
    errorMessage: "",
    editedDetection: null,
  }),
  actions: {
    async addDetection(detection) {
      this.errorMessage = null;
      this.addError = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}admin/detections/`,
          {
            title: detection.title,
            description: detection.description,
            date: detection.date,
            latitude: detection.latitude,
            longitude: detection.longitude,
            timeHour: detection.time.hours.toString(),
            timeMinute: detection.time.minutes.toString(),
            location: detection.place,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Set the content type to application/json
            },
          }
        );
        this.isLoadingModal = false;
        this.addSuccess = true;
        this.getDetection();
        console.log(res);
      } catch (err) {
        this.addError = true;
        this.isLoadingModal = false;
        this.errorMessage = err.response.data.error;
        console.log(err.response.data.error);
      }
    },

    async getDetection() {
      this.errorMessage = null;
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/detections/${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.detections = data.detections.results;
        this.totalPages = data.detections.totalPages;
        this.currentPage = data.detections.currentPage;
        this.isLoadingModal = false;
        console.log(data);
      } catch (err) {
        this.isLoadingModal = false;
        this.errorMessage = err.response.data.error;
        console.log(err);
      }
    },
    async pauseDetection(detectionId) {
      this.errorMessage = null;
      this.pauseError = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        console.log(token);
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/detections/pause/${detectionId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getDetection();
      } catch (err) {
        this.isLoadingModal = false;
        this.pauseError = true;
        console.log(err);
        console.log(this.detections);
      }
    },
    async activateDetection(detectionId) {
      this.errorMessage = null;
      this.isLoadingModal = true;
      this.pauseError = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/detections/unpause/${detectionId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getDetection();
      } catch (err) {
        this.isLoadingModal = false;
        this.pauseError = true;
        console.log(this.detections);

        console.log(err);
      }
    },
    async deleteDetection(detectionId) {
      this.errorMessage = null;
      this.isLoadingModal = true;
      this.pauseError = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.delete(
          `${process.env.VUE_APP_API_BASE}admin/detections/${detectionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getDetection();
      } catch (err) {
        this.isLoadingModal = false;
        this.pauseError = true;
      }
    },
    async updateDetection(detection, detectionId) {
      this.isLoadingModal = true;
      this.isUpdateError = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const res = await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/detections/${detectionId}`,
          {
            title: detection.title,
            description: detection.description,
            date: detection.date,
            longitude: detection.longitude,
            latitude: detection.latitude,
            timeHour: detection.time.hours.toString(),
            timeMinute: detection.time.minutes.toString(),
            location: detection.place,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(res);
        this.isLoadingModal = false;
        this.isUpdateSuccess = true;
      } catch (err) {
        this.isLoadingModal = false;
        this.isUpdateError = true;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getDetection();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getDetection();
      }
    },
  },
});
