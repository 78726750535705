<script setup></script>
<template>
  <router-view />
</template>

<style>
* {
  font-family: "Inter", sans-serif !important;
}
</style>
