<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full px-4">
        <div class="my-6 flex flex-row-reverse mt-24"></div>
        <!-- component -->
        <MessagingContainer />
      </div>
    </div>
  </div>
</template>
<script>
import MessagingContainer from "@/components/messaging/MessagingContainer.vue";

export default {
  name: "messaging-page",
  components: { MessagingContainer },
  data() {
    return {};
  },
  mounted() {
    this.scrollToBottom();
  },
  methods: {
    scrollToBottom() {
      window.scroll({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    },
  },
};
</script>
