<template>
  <div></div>
</template>
<script>
import { useAuthStore } from "@/store/auth";
export default {
  setup() {
    const authStore = useAuthStore();
    const { isSuccessEdit, isFailEdit } = useAuthStore();

    return {
      authStore,
      isSuccessEdit,
      isFailEdit,
    };
  },
  mounted() {
    if (this.authStore.isSuccessEdit) {
      this.$swal({
        icon: "success",
        title: "Profile modifié avec succés",
        showConfirmButton: true,
        timer: 1500,
        willClose: () => {
          this.authStore.isFailEdit = false;
          this.authStore.isSuccessEdit = false;
        },
      });
    }
    setTimeout(() => {
      location.reload();
    }, 1500);
  },
};
</script>
