import { defineStore } from "pinia";
import axios from "axios";

export const useNotificationsStore = defineStore("notificationsStore", {
  state: () => ({
    notifications: [],
    isLoading: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
  }),
  actions: {
    async getNotifications() {
      try {
        this.isLoading = true;
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}notifications/getUserNotifications`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.notifications = data.userNotifications;
        this.isLoading = false;
        console.log(this.notifications);
      } catch (err) {
        this.isLoading = false;
      }
    },

    async updateNotification(notificationId) {
      this.isLoading = true;
      this.pauseError = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}notifications/updateNotificationStatus`,
          {
            notificationId: notificationId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoading = false;
        await this.getNotifications();
      } catch (err) {
        this.isLoading = false;
        this.pauseError = true;
        console.log(err);
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
});
