<template>
  <div></div>
</template>
<script>
import { useDetectionStore } from "../../../store/detection";
export default {
  setup() {
    const detectionStore = useDetectionStore();
    const { addError, addSuccess, isShowEditor } = useDetectionStore();
    return {
      detectionStore,
      addError,
      addSuccess,
      isShowEditor,
    };
  },
  mounted() {
    if (this.detectionStore.addSuccess) {
      this.$swal({
        icon: "success",
        title: "Article ajouté avec succés",
        showConfirmButton: false,
        timer: 4500,
        willClose: () => {
          this.detectionStore.addError = false;
          this.detectionStore.addSuccess = false;
        },
      });
      setTimeout(() => {
        this.detectionStore.isShowEditor = false;
      }, 1600);
    }
  },
};
</script>
