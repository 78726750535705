<template>
  <div>
    <p class="text-center opacity-40">
      Entrez le code envoyé à l’adresse email que vous avez saisie (
      {{ authStore.email }} )
    </p>
    <form @submit.prevent="confirmCode" class="flex flex-col items-center my-8">
      <div>
        <OtpCode
          :validationError="v$.otpValue.$error"
          :digit-count="4"
          @update:otp="otpValue = $event"
        ></OtpCode>
      </div>
      <p v-if="v$.otpValue.$error" class="text-red-500 my-2 text-left">
        code réquis
      </p>
      <p v-if="authStore.error" class="text-red-500 my-2 text-left">
        {{ authStore.error }}
      </p>
      <button type="submit" class="btn my-6">Verifier le code</button>
    </form>
  </div>
</template>
<script>
import OtpCode from "@/components/login/OtpCode.vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useAuthStore } from "@/store/auth";
import router from "@/router";

export default {
  components: { OtpCode },
  data() {
    return {
      otpValue: "",
    };
  },
  setup() {
    const authStore = useAuthStore();
    return { v$: useVuelidate(), authStore };
  },
  validations() {
    return {
      otpValue: { required },
    };
  },
  mounted() {
    this.authStore.error = null;
    if (!this.authStore.email) {
      router.push("/auth/login");
    }
    this.OTPInput();
  },
  methods: {
    async confirmCode() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }

      this.authStore.verifyResetCode();
    },
    OTPInput() {
      const inputs = document.querySelectorAll("#otp > *[id]");
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].addEventListener("keydown", function (event) {
          if (event.key === "Backspace") {
            inputs[i].value = "";
            if (i !== 0) inputs[i - 1].focus();
          } else {
            if (i === inputs.length - 1 && inputs[i].value !== "") {
              return true;
            } else if (event.keyCode > 47 && event.keyCode < 58) {
              inputs[i].value = event.key;
              if (i !== inputs.length - 1) inputs[i + 1].focus();
              console.log(inputs[i]);
              event.preventDefault();
            } else if (event.keyCode > 64 && event.keyCode < 91) {
              inputs[i].value = String.fromCharCode(event.keyCode);
              if (i !== inputs.length - 1) inputs[i + 1].focus();
              console.log(inputs[i].value);
              event.preventDefault();
            }
          }
        });
      }
    },
  },
};
</script>
