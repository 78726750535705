import { defineStore } from "pinia";
import axios from "axios";

export const useAgentsStore = defineStore("agentsStore", {
  state: () => ({
    agents: [],
    itemsPerPage: 10,
    currentPage: 1,
    isLoadingModal: false,
    isSuccess: false,
    isFail: false,
    isShowEditor: false,
    addError: false,
    addSuccess: false,
    editedAgent: null,
    // theses are for filtering
    selectedPlan: null,
    selectedNationality: null,
    selectedSport: null,
    selectedDate: null,
  }),
  actions: {
    async getAgents() {
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/agents?page=${
            this.currentPage
          }&plan=${this.selectedPlan ? this.selectedPlan : ""}&nationality=${
            this.selectedNationality ? this.selectedNationality : ""
          }&sport=${this.selectedSport ? this.selectedSport : ""}&createdAt=${
            this.selectedDate ? this.selectedDate : ""
          }  `,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.agents = data.agents.results;
        this.totalPages = data.agents.totalPages;
        this.currentPage = data.agents.currentPage;
        this.isLoadingModal = false;
      } catch (err) {
        console.log(err);
      }
    },
    async blockAgent(agentId) {
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/agents/block`,
          {
            agentId: agentId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        await this.getAgents();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    async unBlockAgent(agentId) {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/agents/unblock`,
          {
            agentId: agentId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getAgents();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getAgent();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getAgent();
      }
    },
  },
});
