<template>
  <div
    v-if="!notification.is_read"
    class="flex items-center justify-between rounded-lg border w-full p-2 md:p-4 my-3 cursor-pointer"
  >
    <div class="flex items-center">
      <div
        class="bg-[#ccfae4] rounded-md h-10 w-10 flex items-center justify-center mr-4"
      >
        <img :src="bell" alt="bell" />
      </div>
      <div>
        <div>
          Vous avez un nouveau message de
          <b>
            {{ notification?.sourceUser?.first_name
            }}{{ " " + notification?.sourceUser?.last_name }}</b
          >
        </div>
        <div class="text-sm">
          {{ notification?.chat?.latestMessage }}
        </div>
      </div>
    </div>

    <div>
      <img
        @click="removeNotification(notification.id)"
        :src="exitBlack"
        alt="exit"
        class="cursor-pointer"
      />
    </div>
  </div>
</template>
<script>
import exitBlack from "@/assets/icons/cards/exitBlack.svg";
import bell from "@/assets/icons/cards/bell.svg";
import { useNotificationsStore } from "@/store/notifications";
export default {
  props: ["notification"],
  setup() {
    const notificationsStore = useNotificationsStore();
    return { notificationsStore, bell, exitBlack };
  },
  mounted() {
    console.log(this.notification);
  },
  methods: {
    async removeNotification(notificationId) {
      await this.notificationsStore.updateNotification(notificationId);
    },
  },
};
</script>
<style></style>
