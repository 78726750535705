<template>
  <div class="col-start-1 col-end-8 p-3 rounded-lg">
    <div class="flex items-center justify-start">
      <img class="h-10 w-10 rounded-full mr-2" :src="player" alt="userPic" />

      <div
        class="relative mr-3 text-sm bg-[#EAECF0] py-2 px-4 shadow rounded-xl text-black"
      >
        <div class="flex items-center">
          <div
            class="flex justify-center items-center bg-[#bbebd8] text-primary rounded-sm p-1 text-sm"
          >
            PDF
          </div>
          <a class="flex" target="_blank" :href="api + link" download
            ><div class="mx-2">FICHIER.pdf</div>
            <img :src="fileIcon" alt="fileIcon"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fileIcon from "@/assets/icons/messaging/fileIcon.svg";
import player from "@/assets/images/player.png";
export default {
  props: ["link"],
  setup() {
    const api = process.env.VUE_APP_API_BASE;
    return {
      fileIcon,
      api,
      player,
    };
  },
};
</script>
<style></style>
