<template>
  <div v-if="editedPlan" class="flex flex-wrap mt-4">
    <LoaderModal v-if="plansStore.isLoading" />
    <EditPlanFail v-if="plansStore.isFail" />
    <EditPlanSuccess v-if="plansStore.isSuccess" />
    <div class="my-6 mt-24 w-full">
      <div class="flex font-medium">
        <router-link to="/admin/abonnement" class="mx-2"
          >Abonnement</router-link
        >
        >
        {{ editedPlan.name }}
      </div>
      <form @submit.prevent="savePlan()">
        <div class="my-6">
          <p class="text-[#7c7c7c] mb-2 mt-6">Prix de l’abonnement</p>
          <div
            class="flex items-center justify-between border rounded-md py-3 password-container"
          >
            <input
              class="text-gray-700 px-2 leading-tight focus:outline-none password-input w-full"
              type="number"
              v-model="editedPlan.price"
              name="password"
            />
            <p class="mx-3 text-black">€</p>
          </div>
          <p class="text-red-500" v-if="priceError">Prix réquis</p>
        </div>

        <p class="text-[#7c7c7c] mb-2 mt-6">Description</p>
        <textarea
          v-for="(description, index) in editedPlan.description"
          :key="index"
          class="border rounded-md py-3 px-2 focus:outline-none w-full inputs"
          name=""
          cols="20"
          rows="2"
          :value="description"
          @input="updateDescription(index, $event.target.value)"
        ></textarea>

        <p class="text-red-500" v-if="descriptionError">
          Veuillez remplir tous les champs
        </p>

        <div>
          <button class="btn my-6 disabled:bg-gray-300" type="submit">
            Enregistrer
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import separator from "@/assets/icons/cards/separator.svg";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import EditPlanFail from "@/components/modals/plans/EditPlanFail.vue";
import EditPlanSuccess from "@/components/modals/plans/EditPlanSuccess.vue";
import { usePlansStore } from "@/store/plans";
import router from "@/router";
export default {
  components: { LoaderModal, EditPlanSuccess, EditPlanFail },
  async mounted() {
    if (!this.editedPlan) {
      router.push("/admin/abonnement");
    }
    /* if (this.editedPlan?.description) {
      this.editedPlan.description.forEach((element) => {
        this.description.push(element);
      });
    }*/
  },

  methods: {
    async savePlan() {
      let descriptionError = [];
      let priceError = [];
      this.editedPlan.description.forEach((element) => {
        if (element === null || element.match(/^ *$/) !== null) {
          descriptionError.push(true);
        }
      });

      if (descriptionError.length > 0) {
        this.descriptionError = true;
      } else {
        this.descriptionError = false;
      }
      if (this.editedPlan.price.length == 0) {
        priceError.push(true);
        this.priceError = true;
      } else {
        this.priceError = false;
      }

      if (!this.priceError && !this.descriptionError) {
        await this.plansStore.editPlan(this.editedPlan);
      }
    },
    updateDescription(index, newValue) {
      this.editedPlan.description[index] = newValue;
    },
  },
  setup() {
    const plansStore = usePlansStore();
    const { editedPlan } = usePlansStore();
    return { plansStore, separator, editedPlan };
  },
  data() {
    return {
      descriptionError: false,
      priceError: false,
    };
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
