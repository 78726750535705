<template>
  <div
    class="text-black text-center p-4 shadow-md mx-auto my-6 md:my-0 md:mx-6 w-full flex flex-col justify-between break-words max-w-xs"
  >
    <div class="font-normal text-4xl">
      {{ price }}€
      <span v-if="type !== 'free'">
        <span v-if="type == 'week'">/Semaine</span>
        <span v-if="type == 'month'">/Mois</span>
        <span v-if="type == 'year'">/Année</span>
      </span>
    </div>
    <div class="my-3">{{ name }}</div>
    <div v-for="(item, index) in description" :key="index" class="my-3">
      {{ item }}
    </div>
    <div class="my-3">
      <button @click="editPlan()" class="btn shadow-none">Modifier</button>
    </div>
  </div>
</template>
<script>
import { usePlansStore } from "@/store/plans";

export default {
  setup() {
    const plansStore = usePlansStore();
    return { plansStore };
  },
  mounted() {
    console.log(this.description);
  },
  props: ["price", "id", "description", "name", "type"],
  methods: {
    editPlan() {
      const plan = {
        planId: this.id,
        price: this.price,
        /* description: {
          description1: this.description[0] || "",
          description2: this.description[1] || "",
          description3: this.description[2] || "",
        },*/
        description: this.description,
        name: this.name,
      };

      this.plansStore.editedPlan = plan;
      this.$router.push(`/admin/abonnement/${this.id}`);
    },
  },
};
</script>
<style></style>
