import { defineStore } from "pinia";
import axios from "axios";

export const useStatsStore = defineStore("statsStore", {
  state: () => ({
    playerCountCurrentWeek: 0,
    agentCountCurrentWeek: 0,
    clubCountCurrentWeek: 0,
    otherCountCurrentWeek: 0,
    totalCountCurrentWeek: 0,
    totalSubscriptionCount: 0,
    playerIncreased: false,
    agentIncreased: false,
    clubIncreased: false,
    otherIncreased: false,
    totalIncreased: false,
    subscriptionIncreased: false,
    //lastestUsers:
    totalPages: 0,
    lastestUsers: [],
    currentPage: 1,
    //blocking
    isFail: false,
    isSuccess: false,
    isLoadingModal: false,
    //revenues
    chartKey: 1,
    data: {
      datasets: [
        {
          label: "Joueurs",
          data: [300], //totalPlayerRevenue*100
          backgroundColor: "#B9E1CE",
          circumference: 300, //totalPlayerRevenue*100
          borderWidth: 10,
          cutout: 110,
        },
        {
          label: "Agents",
          data: [300], //totalAgentRevenue*100
          backgroundColor: "#00E479",
          circumference: 300, //totalAgentRevenue*100
          cutout: 110,
          borderWidth: 10,
        },
        {
          label: "Autres",
          data: [300], //totalOtherRevenue*100
          backgroundColor: "#3A795B",
          circumference: 300, //totalOtherRevenue*100
          borderWidth: 10,
          cutout: 110,
        },
      ],
    },
    totalRevenue: 0,
    selectedTime: { name: "Cette semaine", value: 1 },
    revenueByTime: {
      totalRevenue: 0,
      totalPlayerRevenue: 0,
      totalAgentRevenue: 0,
      totalOtherRevenue: 0,
    },
  }),
  actions: {
    async fetchStats() {
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/stats/week`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.playerCountCurrentWeek = data.stats.playerCountCurrentWeek;
        this.agentCountCurrentWeek = data.stats.agentCountCurrentWeek;
        this.clubCountCurrentWeek = data.stats.clubCountCurrentWeek;
        this.otherCountCurrentWeek = data.stats.otherCountCurrentWeek;
        this.totalCountCurrentWeek = data.stats.totalCountCurrentWeek;
        this.playerIncreased = data.stats.playerIncreased;
        this.agentIncreased = data.stats.agentIncreased;
        this.clubIncreased = data.stats.clubIncreased;
        this.otherIncreased = data.stats.otherIncreased;
        this.totalIncreased = data.stats.totalIncreased;
        this.totalSubscriptionCount =
          data.stats.totalSubscriptionCountCurrentWeek;
        this.subscriptionIncreased = data.stats.subscriptionIncreased;
      } catch (err) {
        console.error(err);
      }
    },
    async getLastestUsers() {
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/stats/users/${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.lastestUsers = data.records;
        this.totalPages = data.totalPages;
      } catch (err) {
        console.error(err);
      }
    },
    async getTotalRevenue() {
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/stats/revenue`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.totalRevenue = data.totalRevenue;
      } catch (err) {
        console.error(err);
      }
    },
    async getRevenueByTime() {
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        let time;
        switch (this.selectedTime.value) {
          case 1:
            time = "week";
            break;
          case 2:
            time = "month";
            break;
          case 3:
            time = "year";
            break;
          default:
            time = "week";
            break;
        }
        console.log(time);

        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/stats/revenueByTime?time=${time}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(data);
        this.revenueByTime = data.stats;
        this.data.datasets[0].circumference =
          this.revenueByTime.totalPlayerRevenue + 0;
        this.data.datasets[0].data[0] =
          this.revenueByTime.totalPlayerRevenue + 0;
        this.data.datasets[1].circumference =
          this.revenueByTime.totalAgentRevenue + 0;
        this.data.datasets[1].data[0] =
          this.revenueByTime.totalAgentRevenue + 0;
        this.data.datasets[2].circumference =
          this.revenueByTime.totalOtherRevenue + 0;
        this.data.datasets[2].data[0] =
          this.revenueByTime.totalOtherRevenue + 0;

        this.chartKey += 1;
      } catch (err) {
        console.error(err);
      }
    },
    async blockUser(userId, userRole) {
      this.isFail = false;
      this.isLoadingModal = true;
      console.log(userRole);

      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;

        switch (userRole) {
          case "joueur":
            await axios.patch(
              `${process.env.VUE_APP_API_BASE}admin/players/block`,
              {
                playerId: userId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            break;
          case "club":
            await axios.patch(
              `${process.env.VUE_APP_API_BASE}admin/clubs/block`,
              {
                clubId: userId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            break;
          case "agent":
            await axios.patch(
              `${process.env.VUE_APP_API_BASE}admin/agents/block`,
              {
                playerId: userId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            break;
          case "other":
            await axios.patch(
              `${process.env.VUE_APP_API_BASE}admin/others/block`,
              {
                otherId: userId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            break;
          default:
            throw Error;
        }

        this.isLoadingModal = false;
        this.isSuccess = true;
        await this.getLastestUsers();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    async unBlockUser(userId, userRole) {
      this.isFail = false;
      this.isLoadingModal = true;
      console.log(userRole);

      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;

        switch (userRole) {
          case "joueur":
            await axios.patch(
              `${process.env.VUE_APP_API_BASE}admin/players/unblock`,
              {
                playerId: userId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            break;
          case "club":
            await axios.patch(
              `${process.env.VUE_APP_API_BASE}admin/clubs/unblock`,
              {
                clubId: userId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            break;
          case "agent":
            await axios.patch(
              `${process.env.VUE_APP_API_BASE}admin/agents/unblock`,
              {
                playerId: userId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            break;
          case "other":
            await axios.patch(
              `${process.env.VUE_APP_API_BASE}admin/others/unblock`,
              {
                otherId: userId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            break;
          default:
            throw Error;
        }

        this.isLoadingModal = false;
        this.isSuccess = true;
        await this.getLastestUsers();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
  },
});
