<template>
  <div>
    <div class="flex flex-wrap relative">
      <div class="w-full xl:w-[40%] px-4">
        <card-line-chart />
      </div>
      <div class="w-full xl:w-[30%] px-4">
        <card-bar-chart />
      </div>

      <div
        class="w-full xl:absolute top-0 bottom-0 right-0 h-full xl:w-[30%] px-4"
      >
        <card-income :totalRevenue="'4 455 665'" />
      </div>
    </div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 xl:mb-0 px-4">
        <new-users-table />
      </div>
    </div>
  </div>
</template>
<script>
import CardBarChart from "@/components/cards/CardBarChart.vue";
import NewUsersTable from "@/components/tables/NewUsersTable.vue";
import CardIncome from "@/components/cards/CardIncome.vue";
import CardLineChart from "@/components/cards/CardLineChart.vue";

export default {
  name: "dashboard-page",

  components: {
    CardBarChart,
    NewUsersTable,
    CardIncome,
    CardLineChart,
  },
};
</script>
