import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router";

export const usePlayersStore = defineStore("playersStore", {
  state: () => ({
    players: [],
    itemsPerPage: 10,
    currentPage: 1,
    isLoadingModal: false,
    isSuccess: false,
    isFail: false,
    isShowEditor: false,
    addError: false,
    addSuccess: false,
    editedPlayer: null,
    // theses are for filtering
    selectedPlan: null,
    selectedNationality: null,
    selectedSport: null,
    selectedDate: null,
    singlePlayer: null,
  }),
  actions: {
    async getPlayers() {
      try {
        this.isLoadingModal = true;
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/players?page=${
            this.currentPage
          }&plan=${this.selectedPlan ? this.selectedPlan : ""}&nationality=${
            this.selectedNationality ? this.selectedNationality : ""
          }&sport=${this.selectedSport ? this.selectedSport : ""}&createdAt=${
            this.selectedDate ? this.selectedDate : ""
          }  `,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.players = data.players.results;
        this.totalPages = data.players.totalPages;
        this.currentPage = data.players.currentPage;
        this.isLoadingModal = false;
      } catch (err) {
        console.log(err);
      }
    },
    async blockPlayer(playerId) {
      this.isFail = false;
      this.isLoadingModal = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/players/block`,
          {
            playerId: playerId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.isSuccess = true;
        await this.getPlayers();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    async unBlockPlayer(playerId) {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        await axios.patch(
          `${process.env.VUE_APP_API_BASE}admin/players/unblock`,
          {
            playerId: playerId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        await this.getPlayers();
      } catch (err) {
        this.isLoadingModal = false;
        this.isFail = true;
        console.log(err);
      }
    },
    async getOnePlayer(playerId) {
      this.isLoadingModal = true;
      this.isFail = false;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_BASE}admin/players/${playerId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoadingModal = false;
        this.singlePlayer = data.player;
        console.log(data);
      } catch (err) {
        this.isLoadingModal = false;
        router.push("/admin/joueurs");
        console.log(err);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getPlayer();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getPlayer();
      }
    },
  },
});
