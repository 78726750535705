<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.36802 8.42061C9.69331 8.42061 11.5783 6.53559 11.5783 4.2103C11.5783 1.88502 9.69331 0 7.36802 0C5.04273 0 3.15771 1.88502 3.15771 4.2103C3.15771 6.53559 5.04273 8.42061 7.36802 8.42061Z"
      :fill="color"
    />
    <path
      d="M7.36803 20C11.4373 20 14.7361 18.1149 14.7361 15.7896C14.7361 13.4644 11.4373 11.5793 7.36803 11.5793C3.29878 11.5793 0 13.4644 0 15.7896C0 18.1149 3.29878 20 7.36803 20Z"
      :fill="color"
    />
    <path
      d="M19.9979 15.7887C19.9979 17.5328 17.8548 18.9464 15.2392 18.9464C16.0097 18.1043 16.5401 17.0465 16.5401 15.7908C16.5401 14.5329 16.0086 13.4751 15.236 12.632C17.8527 12.6309 19.9979 14.0456 19.9979 15.7887ZM16.8401 4.21033C16.8405 4.71843 16.7182 5.2191 16.4837 5.66984C16.2492 6.12058 15.9094 6.50808 15.4931 6.79943C15.0769 7.09079 14.5964 7.27739 14.0926 7.3434C13.5889 7.40941 13.0766 7.35287 12.5993 7.1786C13.1135 6.27395 13.3831 5.25091 13.3814 4.21033C13.3814 3.13144 13.0972 2.11886 12.6004 1.24312C13.0776 1.06906 13.5897 1.01269 14.0934 1.0788C14.597 1.14491 15.0773 1.33155 15.4934 1.62288C15.9095 1.91421 16.2492 2.30162 16.4837 2.75225C16.7181 3.20288 16.8404 3.70236 16.8401 4.21033Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
<style></style>
