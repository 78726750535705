<template>
  <div class="flex flex-row w-96 flex-shrink-0 p-4 shadow-xl">
    <div class="flex flex-col w-full h-full">
      <div class="h-full overflow-hidden overflow-y-scroll relative pt-2">
        <div class="flex flex-col items-center divide-y h-full">
          <MessageListItem
            v-for="(chat, index) in chatStore.chats"
            :key="index"
            :chat="chat"
            @click="setCurrentChat(chat)"
          />
          <ChatsListLoader v-if="chatStore.isLoadingChats" />
          <ChatsListLoader v-if="chatStore.isLoadingChats" />
          <ChatsListLoader v-if="chatStore.isLoadingChats" />
          <ChatsListLoader v-if="chatStore.isLoadingChats" />
          <ChatsListLoader v-if="chatStore.isLoadingChats" />
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageListItem from "./MessageListItem.vue";
import ChatsListLoader from "@/components/loaders/ChatsListLoader.vue";
import { useChatStore } from "@/store/chat";
export default {
  components: { MessageListItem, ChatsListLoader },
  setup() {
    const chatStore = useChatStore();
    return {
      chatStore,
    };
  },

  async mounted() {
    await this.chatStore.getChats();
    if (!this.chatStore.currentChat) {
      this.chatStore.setCurrentChat(this.chatStore.chats[0]);
    } else {
      console.log(this.chatStore.currentChat);
    }
  },
  methods: {
    async setCurrentChat(chat) {
      await this.chatStore.setCurrentChat(chat);
    },
  },
};
</script>

<style scoped>
* {
  scrollbar-width: auto;
  scrollbar-color: #ababab #ededed;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #ededed;
}

*::-webkit-scrollbar-thumb {
  background-color: #ababab;
  border-radius: 10px;
  border: 0px solid #ffffff;
}
</style>
