<template>
  <div></div>
</template>
<script>
import { useAuthStore } from "@/store/auth";
export default {
  setup() {
    const authStore = useAuthStore();
    const { isSuccessEdit, isFailEdit } = useAuthStore();
    return {
      authStore,
      isSuccessEdit,
      isFailEdit,
    };
  },
  mounted() {
    if (this.authStore.isFailEdit) {
      this.$swal({
        icon: "error",
        title: this.authStore.errorMessage,
        showConfirmButton: true,
        timer: 2000,
        willClose: () => {
          this.authStore.isFailEdit = false;
          this.authStore.isSuccessEdit = false;
        },
      });
    }
  },
};
</script>
