<template>
  <div class="user-actions">
    <PauseJobOfferModal
      v-if="showPauseModal"
      :jobOfferId="jobOfferId"
      @close="showPauseModal = false"
    />
    <UnpauseJobofferModal
      v-if="showUnPauseModal"
      :jobOfferId="jobOfferId"
      @close="showUnPauseModal = false"
    />
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex justify-center">
        <img :src="actions" alt="actions" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
        :to="`/admin/clubs/offer/` + jobOfferId"
      >
        <img :src="consult" class="mr-2" alt="consult" /> Consulter</router-link
      >
      <a
        href="javascript:void(0);"
        @click="showPauseModal = true"
        v-if="status == 'ACTIF'"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="pause" class="mr-2" alt="pause" />

        Mettre en pause
      </a>
      <a
        href="javascript:void(0);"
        @click="showUnPauseModal = true"
        v-if="status == 'PAUSED'"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="pause" class="mr-2" alt="pause" />

        Activer offer d'emploi
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import actions from "@/assets/icons/cards/threeDots.svg";
import consult from "@/assets/icons/cards/consult.svg";
import pause from "@/assets/icons/cards/pause.svg";
import PauseJobOfferModal from "../modals/clubs/PauseJobOfferModal.vue";
import UnpauseJobofferModal from "../modals/clubs/UnpauseJobofferModal.vue";

export default {
  props: ["jobOfferId", "status"],
  components: { PauseJobOfferModal, UnpauseJobofferModal },
  data() {
    return {
      dropdownPopoverShow: false,
      showPauseModal: false,
      showUnPauseModal: false,
    };
  },
  setup() {
    return {
      actions,
      pause,
      consult,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".user-actions");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
