<template>
  <div
    class="relative py-10 xl:py-0 h-full flex flex-col items-center justify-center min-w-0 w-full mb-6 shadow-lg rounded-md bg-white"
  >
    <div class="text-5xl font-medium">{{ statsStore.totalRevenue }} €</div>
    <div class="text-primary text-xl my-8">Total revenus de la plateforme</div>
    <div>
      <button class="btn">
        <router-link to="/admin/abonnement">
          Voir les plans <br />
          d’abonnement</router-link
        >
      </button>
    </div>
  </div>
</template>
<script>
import { useStatsStore } from "@/store/stats";
export default {
  name: "card-stats",
  setup() {
    const statsStore = useStatsStore();
    return {
      statsStore,
    };
  },
  async mounted() {
    await this.statsStore.getTotalRevenue();
  },
};
</script>
<style></style>
