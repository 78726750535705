<template>
  <div
    class="h-full flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="flex h-full justify-between px-4">
      <p class="text-xl font-medium text-black py-3">Revenus</p>
      <div><FilterDonughtChart /></div>
    </div>
    <div class="p-4 flex">
      <Doughnut
        class="w-6/12"
        :key="statsStore.chartKey"
        v-if="chartData"
        :data="chartData"
        :options="options"
      />
    </div>
  </div>
</template>
<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";
import FilterDonughtChart from "../dropdowns/filters/dashboard/FilterDonughtChart.vue";
import { useStatsStore } from "@/store/stats";
ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  name: "App",
  components: {
    Doughnut,
    FilterDonughtChart,
  },
  async mounted() {
    await this.statsStore.getRevenueByTime();
    console.log(this.statsStore.data);
    this.chartData = this.statsStore.data;
  },
  setup() {
    const statsStore = useStatsStore();
    return { statsStore };
  },
  data() {
    return {
      options: {
        plugins: {
          legend: {
            display: true,
            position: "right",
            generateLabels: function () {
              var data = this.statsStore.data;
              if (data.labels.length && data.datasets.length) {
                return data.datasets[0].data.map(function (value, index) {
                  return {
                    text: data.labels[index] + ": " + value,
                    fillStyle: data.datasets[0].backgroundColor,
                    strokeStyle: data.datasets[0].borderColor,
                    lineWidth: data.datasets[0].borderWidth,
                    hidden: isNaN(value),
                    index: index,
                  };
                });
              }
              return [];
            },
          },
        },
        scales: {
          x: {
            type: "linear",
          },
          y: {
            type: "linear",
          },
        },
      },
      chartData: null,
    };
  },
};
</script>
