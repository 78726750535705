<template>
  <div class="mods-action">
    <AddModeratorModal
      v-if="showDeleteModal"
      @close="showDeleteModal = false"
    />
    <EditModeratorModal
      v-if="moderatorsStore.showEditModal"
      @close="moderatorsStore.showEditModal = false"
      :editedModerator="editedModerator"
    />
    <DeleteModeratorModal
      :moderatorId="moderatorId"
      v-if="moderatorsStore.showDeleteModal"
      @close="moderatorsStore.showDeleteModal = false"
    />
    <PauseModeratorModal
      :moderatorId="moderatorId"
      v-if="moderatorsStore.showPauseModal"
      @close="moderatorsStore.showPauseModal = false"
    />
    <UnpauseModerator
      :moderatorId="moderatorId"
      v-if="moderatorsStore.showUnPauseModal"
      @close="moderatorsStore.showUnPauseModal = false"
    />
    <a
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
      v-on:close="closeDropDown"
    >
      <div class="items-center flex justify-center">
        <img :src="actions" alt="actions" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
      }"
    >
      <a
        @click="handleEdit"
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
      >
        <img :src="edit" class="mr-2" alt="edit" />

        Modifier </a
      ><a
        @click="handlePause"
        v-if="!editedModerator.isPaused"
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="pause" class="mr-2" alt="pause" />

        Mettre en pause </a
      ><a
        @click="handleUnPause"
        v-if="editedModerator.isPaused"
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="pause" class="mr-2" alt="pause" />

        Activer </a
      ><a
        href="javascript:void(0);"
        @click="handleDelete"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="deleteIcon" class="mr-2" alt="delete" />

        Supprimer
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import actions from "@/assets/icons/cards/threeDots.svg";
import edit from "@/assets/icons/cards/edit.svg";
import pause from "@/assets/icons/cards/pause.svg";
import deleteIcon from "@/assets/icons/cards/delete.svg";
import AddModeratorModal from "../modals/AddModeratorModal.vue";
import EditModeratorModal from "../modals/mods/EditModeratorModal.vue";
import { useModeratorsStore } from "@/store/moderators";
import DeleteModeratorModal from "../modals/mods/deleteModeratorModal.vue";
import PauseModeratorModal from "../modals/mods/PauseModeratorModal.vue";
import UnpauseModerator from "../modals/mods/UnpauseModerator.vue";

export default {
  props: ["editedModerator", "moderatorId"],
  components: {
    AddModeratorModal,
    EditModeratorModal,
    DeleteModeratorModal,
    PauseModeratorModal,
    UnpauseModerator,
  },
  data() {
    return {
      dropdownPopoverShow: false,
      showDeleteModal: false,
    };
  },
  setup() {
    const moderatorsStore = useModeratorsStore();
    return {
      actions,
      pause,
      edit,
      deleteIcon,
      moderatorsStore,
    };
  },
  methods: {
    handleEdit() {
      this.dropdownPopoverShow = false;
      this.moderatorsStore.showEditModal = true;
    },
    handleDelete() {
      this.dropdownPopoverShow = false;
      this.moderatorsStore.showDeleteModal = true;
    },
    handlePause() {
      this.dropdownPopoverShow = false;
      this.moderatorsStore.showPauseModal = true;
    },
    handleUnPause() {
      this.dropdownPopoverShow = false;
      this.moderatorsStore.showUnPauseModal = true;
    },

    closeDropDown() {
      this.dropdownPopoverShow = false;
    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".mods-action");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
