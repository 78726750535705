import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router";
export const useAuthStore = defineStore("authStore", {
  state: () => ({
    error: null,
    user: null,
    email: null,
    errorMessage: "",
    isLoading: false,
    isFailEdit: false,
    isSuccessEdit: false,
    resetCode: "",
    successReset: false,
    countries: [],
  }),

  actions: {
    async authenticate(email, password) {
      this.error = null;
      this.isLoading = true;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE}admin/login`,
          { email: email, password: password }
        );
        const countries = await axios.get(
          `${process.env.VUE_APP_API_BASE}shared/countries`
        );
        localStorage.setItem("draft-countries", JSON.stringify(countries.data));
        this.countries = countries.data;
        this.user = { user: response.data.admin, token: response.data.token };
        this.isLoading = false;

        localStorage.setItem("draft-admin", JSON.stringify(this.user));
        router.push("/admin");
      } catch (err) {
        this.isLoading = false;
        this.error = "Invalide email ou mot de passe";
      }
    },
    async sendResetCode(email) {
      this.error = null;
      this.isLoading = true;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE}admin/sendResetCode`,
          {
            email: email,
          }
        );
        this.isLoading = false;
        this.email = email;
        console.log(response);
        router.push("/auth/confirmcode");
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        this.error = "Ce mail n'existe pas";
      }
    },
    async verifyResetCode() {
      this.error = null;
      this.isLoading = true;
      try {
        await axios.post(`${process.env.VUE_APP_API_BASE}admin/verifyReset`, {
          email: this.email,
          code: this.resetCode,
        });
        this.isLoading = false;
        router.push("/auth/newPassword");
      } catch (err) {
        this.isLoading = false;
        this.error = "Code incorrect";
      }
    },
    async resetPassword(newPassword) {
      this.error = null;
      this.isLoading = true;
      try {
        await axios.post(`${process.env.VUE_APP_API_BASE}admin/resetPassword`, {
          email: this.email,
          code: this.resetCode,
          newPassword: newPassword,
        });
        this.successReset = true;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.error = "Code incorrect";
      }
    },
    async updateProfile(newProfile) {
      this.error = null;
      this.isLoading = true;
      try {
        const admin = localStorage.getItem("draft-admin");
        const token = JSON.parse(admin).token;
        const response = await axios.put(
          `${process.env.VUE_APP_API_BASE}admin/updateProfile`,
          newProfile,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoading = false;
        this.isSuccessEdit = true;
        this.user = response.data.user;
        let newAdminData = JSON.parse(admin);
        newAdminData.user = response.data.user;
        localStorage.setItem("draft-admin", JSON.stringify(newAdminData));
      } catch (err) {
        if (err.response.status == 401) {
          this.errorMessage = "Incorrect ancien mot de passe";
        }
        console.log(err);
        this.isLoading = false;
        this.isFailEdit = true;
      }
    },

    disconnect() {
      this.user = null;
      localStorage.removeItem("draft-admin");
      router.push("/auth");
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
});
