<template>
  <div></div>
</template>
<script>
import router from "@/router";
import { useNewsStore } from "../../../store/news";
export default {
  setup() {
    const newsStore = useNewsStore();
    const { isEditFail } = useNewsStore();
    const { isEditSuccess } = useNewsStore();
    return {
      newsStore,
      isEditFail,
      isEditSuccess,
    };
  },
  mounted() {
    if (this.newsStore.isEditSuccess) {
      this.$swal({
        icon: "success",
        title: "Article modifié avec succés",
        showConfirmButton: false,
        timer: 1500,
        willClose: () => {
          this.newsStore.isEditFail = false;
          this.newsStore.isEditSuccess = false;
        },
      });
      setTimeout(() => {
        router.push("/admin/news");
        this.newsStore.editedNews = null;
      }, 1600);
    }
  },
};
</script>
