<template>
  <div
    class="flex flex-row items-center justify-center"
    v-if="chatStore.currentChat"
  >
    <div class="flex items-center justify-center h-10 w-10 rounded-full mr-4">
      <img
        v-if="chatStore.currentChat.receiver.profile_picture"
        :src="api + chatStore.currentChat.receiver.profile_picture"
        class="mr-2"
        alt="player2"
      />
      <img
        v-if="!chatStore.currentChat.receiver.profile_picture"
        :src="person"
        class="mr-2"
        alt="player2"
      />
    </div>
    <div class="flex flex-col">
      <div class="font-semibold text-xl">
        {{ chatStore.currentChat.receiver.first_name }}
        {{ chatStore.currentChat.receiver.last_name }}
      </div>
    </div>
    <img :src="onlineDot" class="ml-2 invisible" alt="onlineDot" />
  </div>

  <div class="h-full overflow-hidden py-4">
    <div ref="chatContainer" class="h-full overflow-y-auto">
      <div class="flex justify-center">
        <button
          class="text-center"
          @click="getNewMessages"
          v-if="chatStore.currentPage < chatStore.totalPages"
        >
          Charger plus des messages
        </button>
      </div>

      <MessagesLoader v-if="chatStore.isLoadingMessages" />

      <div
        class="grid grid-cols-12 gap-y-2"
        v-for="(message, index) in chatStore.messages"
        :key="index"
      >
        <div class="col-span-12">
          <div
            class="separator text-gray-400 my-4 mx-auto"
            v-if="shouldShowDateDivider(index)"
          >
            {{ formatDate(message.createdAt) }}
          </div>
        </div>
        <MessageRecieved
          :message="message.content ? message.content : ''"
          v-if="message.role == 'receiver' && !message.file"
        />
        <MessageSent
          :message="message.content ? message.content : ''"
          v-if="message.role == 'sender' && !message.file"
        />

        <MessageFileRecieved
          :message="message.content ? message.content : ''"
          v-if="message.role == 'receiver' && message.file"
          :link="message.file"
        />
        <MessageFileSent
          :message="message?.content ? message.content : ''"
          v-if="message.role == 'sender' && message.file"
          :link="message.file"
        />
      </div>
    </div>
  </div>
  <div v-if="chatStore.fileSent" class="m-4 relative">
    <img
      width="20"
      height="20"
      @click="removeFile"
      :src="removeFileIcon"
      alt="removeFileIcon"
      class="absolute left-[50px] top-[-20px] cursor-pointer"
    />
    <img width="50" height="50" :src="pdfIcon" alt="pdfIcon" />
  </div>
  <div class="flex flex-row items-center">
    <div
      class="flex flex-row items-center w-full border rounded-3xl bg-[#EAECF0] h-12 px-2 relative"
    >
      <label>
        <div
          v-if="!chatStore.fileSent"
          class="flex flex-col upload-block justify-center items-center p-2 rounded-md border-dashed"
        >
          <svg
            class="w-5 h-5 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
            ></path>
          </svg>

          <input
            class="hidden"
            hidden
            id="inputTag"
            @change="handleFileChange"
            type="file"
          />
        </div>
        <img ref="output" />
        <img
          @click="removeImage"
          :src="trash"
          class="ml-2"
          v-if="chatStore.fileSent"
          width="40"
        />
      </label>
      <div class="w-full">
        <input
          type="text"
          @keypress.enter="sendMessage"
          v-model="chatStore.message"
          class="border border-transparent w-full focus:outline-none text-sm h-10 flex items-center bg-[#EAECF0]"
          placeholder="Ecrivez quelque chose...."
        />
      </div>
      <button
        :disabled="!chatStore.message"
        @click="sendMessage"
        :class="{
          'bg-gray-300': !chatStore.message,
          'bg-primary': chatStore.message,
        }"
        class="flex shadow-lg items-center justify-center h-14 w-14 pt-2 rounded-full absolute right-0"
      >
        <img :src="send" alt="send" />
      </button>
    </div>
  </div>
</template>
<script>
import player2 from "@/assets/images/player2.png";
import onlineDot from "@/assets/icons/messaging/onlineDot.svg";
import send from "@/assets/icons/messaging/send.svg";
import person from "@/assets/icons/cards/person.svg";
import MessageRecieved from "./MessageRecieved.vue";
import MessageSent from "./MessageSent.vue";
import MessageFileRecieved from "./MessageFileRecieved.vue";
import MessagesLoader from "../loaders/MessagesLoader.vue";
import { useChatStore } from "@/store/chat";
import { useVuelidate } from "@vuelidate/core";
import MessageFileSent from "./MessageFileSent.vue";
import pdfIcon from "../../assets/icons/messaging/pdfFile.png";
import removeFileIcon from "../../assets/icons/messaging/removeFileIcon.png";
import { io } from "socket.io-client";
import { useAuthStore } from "@/store/auth";

export default {
  async created() {
    this.socket = io(process.env.VUE_APP_API_BASE);
    console.log(this.chatStore.currentChat);
    this.socket.on("connect", () => {
      this.socket.emit("setup", {
        userId: this.authStore.user.user.id,
        role: "admin",
      });
      this.socket.on("message received", (message) => {
        console.log("message  received de created" + message);
        if (message.file) {
          const newMessage = {
            content: message.content,
            role: "receiver",
            file: "true",
            createdAt: new Date(),
          };
          this.chatStore.messages.push(newMessage);
        } else {
          const newMessage = {
            content: message.content,
            role: "receiver",
            createdAt: new Date(),
          };
          this.chatStore.messages.push(newMessage);
        }
      });
      console.log("listening to co nnect ...", this.authStore.user.user.id);
    });
  },
  setup() {
    const chatStore = useChatStore();
    const authStore = useAuthStore();
    const api = process.env.VUE_APP_API_BASE;
    const socket = io(process.env.VUE_APP_API_BASE);

    return {
      socket,
      chatStore,
      authStore,
      removeFileIcon,
      pdfIcon,
      player2,
      onlineDot,
      send,
      person,
      api,
      v$: useVuelidate(),
    };
  },
  updated() {
    console.log("updated");
    this.socket.emit("join chat", this.chatStore.currentChat);
    this.$nextTick(() => {
      const chatContainer = this.$refs.chatContainer;
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: "smooth",
      });
    });
  },
  methods: {
    shouldShowDateDivider(index) {
      if (index === 0) {
        return true; // Always show the first date divider
      }
      const currentDate = new Date(this.chatStore.messages[index].createdAt);
      const prevDate = new Date(this.chatStore.messages[index - 1].createdAt);
      return currentDate.toDateString() !== prevDate.toDateString();
    },
    formatDate(createdAt) {
      const date = new Date(createdAt);
      const options = { year: "numeric", month: "long", day: "numeric" };
      return date.toLocaleDateString("fr-FR", options);
    },
    async sendMessage() {
      if (this.chatStore.fileSent) {
        await this.chatStore.sendMessageWithFile(this.chatStore.message);
      } else {
        if (!this.chatStore.message || this.chatStore.message?.length <= 0) {
          return;
        }
        await this.chatStore.sendMessage(this.chatStore.message);
        if (this.chatStore.latestMessageSent) {
          const conversation = {
            message: {
              id: this.chatStore.latestMessageSent.id,
              content: this.chatStore.latestMessageSent.content,
              sender: {
                id: this.chatStore.currentChat.sender.id,
                role: "admin",
              },
            },
            chat: {
              id: this.chatStore.currentChat.id,
              sender: {
                id: this.chatStore.currentChat.sender.id,
                role: "admin",
              },
              receiver: {
                id: this.chatStore.currentChat.receiver.id,
                role: this.chatStore.currentChat.receiverRole,
              },
            },
          };
          this.socket.emit("new message", conversation);
        }
      }

      if (this.chatStore.isMessageSent) {
        this.$nextTick(() => {
          const chatContainer = this.$refs.chatContainer;
          chatContainer.scrollTo({
            top: chatContainer.scrollHeight,
            behavior: "smooth",
          });
        });
      }
      if (this.chatStore.fileSent) {
        this.removeFile();
      }
    },
    removeFile() {
      this.chatStore.fileSent = null;
    },
    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile && selectedFile.type === "application/pdf") {
        this.chatStore.fileSent = selectedFile;
      } else {
        console.log("only pdf okease");
        this.$swal({
          icon: "info",
          title: "only pdf",
          showConfirmButton: false,
          timer: 800,
        });
      }
    },
    getNewMessages() {
      this.chatStore.nextPage();
      const chatContainer = this.$refs.chatContainer;
      chatContainer.scroll({ top: 0, behavior: "smooth" });
    },
  },
  components: {
    MessageRecieved,
    MessageSent,
    MessageFileRecieved,
    MessagesLoader,
    MessageFileSent,
  },
};
</script>
<style scoped>
* {
  scrollbar-width: auto;
  scrollbar-color: #ababab #ededed;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #ededed;
}

*::-webkit-scrollbar-thumb {
  background-color: #ababab;
  border-radius: 10px;
  border: 0px solid #ffffff;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.separator:not(:empty)::before {
  margin-right: 1rem;
}
.h-full.overflow-y-auto {
  overflow-y: auto;
}
.separator:not(:empty)::after {
  margin-left: 1rem;
}
</style>
