<template>
  <div class="col-start-1 col-end-13 p-3 rounded-lg">
    <div class="flex items-center justify-start flex-row-reverse">
      <div
        class="relative text-sm bg-primary py-2 px-4 shadow rounded-xl text-white"
      >
        <div>{{ message }}</div>
        <div class="flex items-center">
          <div
            class="flex justify-center items-center bg-[#bbebd8] text-black rounded-sm p-1 text-sm"
          >
            PDF
          </div>
          <a class="flex" target="_blank" :href="api + link" download
            ><div class="mx-2">FICHIER.pdf</div>
            <img :src="fileIconSent" alt="fileIcon"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fileIconSent from "@/assets/icons/messaging/fileIconSent.svg";
import player2 from "@/assets/images/player2.png";
export default {
  props: ["link", "message"],
  setup() {
    const api = process.env.VUE_APP_API_BASE;

    return {
      api,
      fileIconSent,
      player2,
    };
  },
};
</script>
<style></style>
