<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
  >
    <div class="flex justify-between">
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-medium text-lg">Tous les clubs</h3>
          </div>
        </div>
      </div>
      <div class="flex">
        <filter-club-date />
        <filter-club-nationality />
        <filter-club-sport />
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!--clubs table -->
      <table
        v-if="clubsStore.clubs.length > 0"
        class="items-center w-full bg-transparent border-collapse"
      >
        <thead>
          <tr class="border-b border-t">
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              <img
                src="/img/checkbox.e6903039.svg"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <th
              class="text-[#999999] px-6 flex items-center align-middle py-3 uppercase font-medium text-left"
            >
              ID Club
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Nom du club
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Utilisateur
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Téléphone
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Nationnalité
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Localisation
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Date d’inscription
            </th>

            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Sport
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Offres d’emploi
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Status
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(club, index) in clubsStore.clubs"
            :key="index"
            class="border-b"
          >
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 border-t-0 whitespace-nowrap font-medium text-left"
            >
              <img
                src="/img/checkbox.e6903039.svg"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <td class="align-middle border-l-0 border-r-0 p-4">
              #{{ club.id }}
            </td>
            <td class="align-middle border-l-0 border-r-0 p-4">
              {{ club.club_name }}
            </td>

            <td class="align-middle border-l-0 border-r-0 p-4">
              <div class="flex items-center">
                <img
                  v-if="club.profile_picture"
                  :src="api + club.profile_picture"
                  alt="person"
                  class="mr-2 rounded-full w-10 h-10"
                />
                <img
                  v-if="!club.profile_picture"
                  :src="person"
                  alt="person"
                  class="mr-2"
                />
                {{ club.first_name + " " + club.last_name }}
              </div>
            </td>
            <td class="align-middle border-l-0 border-r-0 p-4">
              {{ club.phone_number }}
            </td>
            <td class="align-middle border-l-0 border-r-0 p-4">
              {{ club.nationality }}
            </td>

            <td class="align-middle border-l-0 border-r-0 p-4">
              <a
                :href="`https://www.google.com/maps?q=${club.latitude},${club.longitude}`"
                target="_blank"
                class="font-semibold"
                >Lien</a
              >
            </td>
            <td class="align-middle border-l-0 border-r-0 p-4">
              {{ club.createdAt.substring(0, 10) }}
            </td>

            <td class="align-middle border-l-0 border-r-0 p-4">
              {{ club.sport }}
            </td>

            <td class="align-middle border-l-0 border-r-0 p-4">
              {{ club.jobOffers.length }}
            </td>
            <td
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              <div
                v-if="!club.is_blocked"
                class="rounded-xl text-center px-2 py-1 font-medium text-primary border-primary border"
              >
                ACTIF
              </div>
              <div
                v-if="club.is_blocked"
                class="rounded-xl text-center px-2 py-1 font-medium text-[#C79141] border-[#C79141] border"
              >
                BLOQUÉ
              </div>
            </td>

            <td class="align-middle border-l-0 border-r-0 text-right">
              <clubs-actions-dropdown
                :clubId="club.id"
                :is_blocked="club.is_blocked"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="flex flex-col justify-center items-center py-10">
        <img :src="noRecords" alt="noRecords" class="w-2/6" />
        <div class="text-xl text-[#747373]">Aucun record pour le moment !</div>
      </div>
    </div>
    <div
      v-if="clubsStore.clubs.length > 0"
      class="flex justify-around w-full py-6"
    >
      <div>
        <button
          @click="clubsStore.previousPage"
          :disabled="clubsStore.currentPage === 1"
          class="border text-primary px-3 py-2 rounded-md"
        >
          Précédente
        </button>
      </div>
      <div class="text-primary flex justify-center items-center">
        Page {{ clubsStore.currentPage }} sur
        {{ clubsStore.totalPages }}
      </div>
      <div>
        <button
          @click="clubsStore.nextPage"
          :disabled="clubsStore.currentPage === clubsStore.totalPages"
          class="border text-primary px-3 py-2 rounded-md"
        >
          Prochaine
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ClubsActionsDropdown from "@/components/dropdowns/ClubsActionsDropdown.vue";
import checkbox from "@/assets/icons/cards/checkbox.svg";
import person from "@/assets/icons/cards/person.svg";
import FilterClubNationality from "../dropdowns/filters/clubs/FilterClubNationality.vue";
import FilterClubSport from "../dropdowns/filters/clubs/FilterClubSport.vue";
import FilterClubDate from "../dropdowns/filters/clubs/FilterClubDate.vue";
import { useClubsStore } from "@/store/clubs";
import noRecords from "@/assets/images/noRecords.svg";

export default {
  components: {
    ClubsActionsDropdown,
    FilterClubNationality,
    FilterClubSport,
    FilterClubDate,
  },
  mounted() {
    this.clubsStore.getClubs();
  },
  data() {
    return {};
  },
  setup() {
    const api = process.env.VUE_APP_API_BASE;

    const clubsStore = useClubsStore();
    return {
      checkbox,
      clubsStore,
      person,
      noRecords,
      api,
    };
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
