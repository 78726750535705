<template>
  <div class="detection-action">
    <DeleteDetectionModal
      :detectionId="detectionId"
      v-if="showDeleteModal"
      @close="showDeleteModal = false"
    >
    </DeleteDetectionModal>
    <PauseDetectionModal
      :detectionId="detectionId"
      v-if="showPauseModal"
      @close="showPauseModal = false"
    />
    <UnPauseDetectionModal
      :detectionId="detectionId"
      v-if="showUnPauseModal"
      @close="showUnPauseModal = false"
    />
    <a
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
      v-on:close="closeDropDown"
    >
      <div class="items-center flex justify-center">
        <img :src="actions" alt="actions" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
      }"
    >
      <router-link
        @click="detectionStore.editedDetection = editedDetecion"
        v-if="detectionStatus !== 'INACTIVE'"
        class="text-sm py-2 px-4 font-normal flex items-center w-full hover:bg-slate-200"
        :to="`/admin/detection/${detectionId}`"
      >
        <img :src="edit" class="mr-2" alt="edit" />Modifier
      </router-link>
      <a
        href="javascript:void(0);"
        v-if="detectionStatus == 'ACTIF'"
        @click="showPauseModal = true"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="pause" class="mr-2" alt="pause" />

        Mettre en pause </a
      ><a
        href="javascript:void(0);"
        v-if="detectionStatus == 'PAUSE'"
        @click="showUnPauseModal = true"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="play" class="mr-2" alt="play" />

        Activer la détection </a
      ><a
        href="javascript:void(0);"
        @click="showDeleteModal = true"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="deleteIcon" class="mr-2" alt="delete" />

        Supprimer
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import actions from "@/assets/icons/cards/threeDots.svg";
import edit from "@/assets/icons/cards/edit.svg";
import pause from "@/assets/icons/cards/pause.svg";
import play from "@/assets/icons/cards/play.svg";
import deleteIcon from "@/assets/icons/cards/delete.svg";
import DeleteDetectionModal from "../modals/detections/DeleteDetectionModal.vue";
import PauseDetectionModal from "../modals/detections/PauseDetectionModal.vue";
import UnPauseDetectionModal from "../modals/detections/UnPauseDetectionModal.vue";
import { useDetectionStore } from "@/store/detection";

export default {
  props: ["detectionId", "detectionStatus", "editedDetecion"],

  components: {
    DeleteDetectionModal,
    UnPauseDetectionModal,
    PauseDetectionModal,
  },
  data() {
    return {
      dropdownPopoverShow: false,
      showDeleteModal: false,
      showUnPauseModal: false,
      showPauseModal: false,
    };
  },
  setup() {
    const detectionStore = useDetectionStore();
    return {
      actions,
      detectionStore,
      pause,
      edit,
      deleteIcon,
      play,
    };
  },
  methods: {
    closeDropDown() {
      this.dropdownPopoverShow = false;
    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".detection-action");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
