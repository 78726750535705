<template>
  <div class="career-filter mx-2">
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="FilterPlayerAbonmment"
      v-on:click="toggleDropdown($event)"
    >
      <button class="btn-outline rounded-xl py-1 px-3 flex items-center">
        <img :src="abonmentFilter" alt="abonmentFilter" class="mr-2" />
        Abonnement
        <img
          :src="filterClosed"
          v-if="!dropdownPopoverShow"
          alt="filterClosed"
          class="ml-2"
        />
        <img
          :src="filterOpen"
          v-if="dropdownPopoverShow"
          alt="filterOpen"
          class="ml-2"
        />
      </button>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <div class="flex px-10 border-b py-3" @click="filterOthers('FREE')">
        <img
          v-if="othersStore.selectedPlan == 'FREE'"
          :src="check"
          class="mr-2"
          alt="check"
        />Free
      </div>
      <div class="flex px-10 border-b py-3" @click="filterOthers('PLUS')">
        <img
          v-if="othersStore.selectedPlan == 'PLUS'"
          :src="check"
          class="mr-2"
          alt="check"
        />Plus
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import filterClosed from "@/assets/icons/cards/filterClosed.svg";
import filterOpen from "@/assets/icons/cards/filterOpen.svg";
import planCheck from "@/assets/icons/cards/permissionCheck.svg";
import abonmentFilter from "@/assets/icons/cards/abonmentFilter.svg";
import { useOthersStore } from "@/store/others";
import check from "@/assets/icons/cards/permissionCheck.svg";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      plans: [
        { name: "Free", isSelected: false },
        { name: "Plus", isSelected: false },
      ],
    };
  },
  setup() {
    const othersStore = useOthersStore();
    return {
      othersStore,
      abonmentFilter,
      filterOpen,
      filterClosed,
      planCheck,
      check,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".career-filter");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-end",
        });
      }
    },
    filterOthers(filter) {
      this.othersStore.selectedPlan = filter;
      this.othersStore.getOthers();
      this.dropdownPopoverShow = false;
    },
    togglePlan(plan) {
      switch (plan.name) {
        case "Free":
          if (plan.isSelected) {
            this.plans[0].isSelected = false;
          } else {
            this.plans[0].isSelected = true;
            this.plans[1].isSelected = false;
          }
          break;
        case "Plus":
          if (plan.isSelected) {
            this.plans[1].isSelected = false;
          } else {
            this.plans[1].isSelected = true;
            this.plans[0].isSelected = false;
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
