<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-2 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-medium text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Archives des détections
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- detection table -->
      <table
        v-if="detecionStore.detections.length > 0"
        class="items-center w-full bg-transparent border-collapse"
      >
        <thead>
          <tr class="border-b">
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              <img
                :src="checkbox"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>

            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              ID Détection
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Titre de <br />
              la détection
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Date
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Heure
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Lieu
            </th>

            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Nombre de <br />postulations
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Auteur
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Date de <br />
              publication
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Status
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(detection, index) in detecionStore.detections"
            :key="index"
            class="border-b"
          >
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              <img
                :src="checkbox"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>

            <td
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ detection.id }}
            </td>
            <td
              class="border-t-0 max-w-[100px] px-4 align-middle border-l-0 border-r-0 p-4"
            >
              {{ detection.title }}
            </td>
            <td
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ detection.date }}
            </td>
            <td
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ detection.timeHour }}:{{ detection.timeMinute }}
            </td>
            <td
              class="border-t-0 max-w-[100px] px-4 align-middle border-l-0 border-r-0 p-4"
            >
              {{ detection.location }}
            </td>
            <td
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ detection.applicationCount }}
            </td>
            <td
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ !detection.clubId ? "Draft" : detection.club.club_name }}
            </td>
            <td
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ detection.createdAt.substring(0, 10) }}
            </td>
            <td
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              <div
                v-if="detection.status == 'ACTIF'"
                class="rounded-xl text-center px-2 py-1 font-medium text-primary border-primary border"
              >
                ACTIF
              </div>
              <div
                v-if="detection.status == 'PAUSE'"
                class="rounded-xl text-center px-2 py-1 font-medium text-[#C79141] border-[#C79141] border"
              >
                PAUSE
              </div>
              <div
                v-if="detection.status == 'INACTIVE'"
                class="rounded-xl text-center px-2 py-1 font-medium text-gray-400 border-gray-400 border"
              >
                INACTIVE
              </div>
            </td>

            <td
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-right"
            >
              <detections-actions-dropdown
                :editedDetecion="detection"
                :detectionId="detection.id"
                :detectionStatus="detection.status"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="flex flex-col justify-center items-center py-10">
        <img :src="noRecords" alt="noRecords" class="w-2/6" />
        <div class="text-xl text-[#747373]">Aucun record pour le moment !</div>
      </div>
    </div>
    <div
      v-if="detecionStore.detections.length > 0"
      class="flex justify-around w-full py-6"
    >
      <div>
        <button
          @click="detecionStore.previousPage"
          :disabled="detecionStore.currentPage === 1"
          class="border text-primary px-3 py-2 rounded-md"
        >
          Précédente
        </button>
      </div>
      <div class="text-primary flex justify-center items-center">
        Page {{ detecionStore.currentPage }} sur
        {{ detecionStore.totalPages }}
      </div>
      <div>
        <button
          @click="detecionStore.nextPage"
          :disabled="detecionStore.currentPage === detecionStore.totalPages"
          class="border text-primary px-3 py-2 rounded-md"
        >
          Prochaine
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import DetectionsActionsDropdown from "@/components/dropdowns/DetectionsActionsDropdown.vue";
import checkbox from "@/assets/icons/cards/checkbox.svg";
import noRecords from "@/assets/images/noRecords.svg";
import { useDetectionStore } from "@/store/detection";
export default {
  data() {
    return {};
  },
  async mounted() {
    await this.detecionStore.getDetection();
  },
  setup() {
    const detecionStore = useDetectionStore();
    return { detecionStore, checkbox, noRecords };
  },
  components: {
    DetectionsActionsDropdown,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
