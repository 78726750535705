<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-medium text-lg">Articles</h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- News table -->
      <table
        v-if="newsStore.news.length > 0"
        class="items-center w-full bg-transparent border-collapse"
      >
        <thead>
          <tr class="border-b border-t">
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              <img
                :src="checkbox"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <th
              class="text-[#999999] px-6 flex items-center align-middle py-3 uppercase whitespace-nowrap font-medium text-left"
            >
              ID Article
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Titre de l’article
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Date de publication
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Vues
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Auteur
            </th>

            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Status
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Action
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(article, index) in newsStore.news"
            :key="index"
            class="border-b"
          >
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              <img
                :src="checkbox"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <td
              class="align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              #123112
            </td>
            <td
              class="align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              <div class="flex items-center">
                {{ article.title }}
              </div>
            </td>
            <td
              class="align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ article.createdAt.substring(0, 10) }}
            </td>
            <td
              class="align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              0
            </td>
            <td
              class="align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ article.author }}
            </td>

            <td
              class="align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              <div
                v-if="!article.isPaused"
                class="rounded-xl text-center px-2 py-1 font-medium text-primary border-primary border"
              >
                ACTIF
              </div>
              <div
                v-if="article.isPaused"
                class="rounded-xl text-center px-2 py-1 font-medium text-[#C79141] border-[#C79141] border"
              >
                PAUSE
              </div>
            </td>

            <td
              class="align-middle border-l-0 border-r-0 whitespace-nowrap text-right"
            >
              <news-actions-dropdown
                :isPaused="article.isPaused"
                :newsId="article.id"
                :editedNews="article"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="flex flex-col justify-center items-center py-10">
        <img :src="noRecords" alt="noRecords" class="w-2/6" />
        <div class="text-xl text-[#747373]">Aucun record pour le moment !</div>
      </div>
    </div>
    <div
      v-if="newsStore.news.length > 0"
      class="flex justify-around w-full py-6"
    >
      <div>
        <button
          @click="newsStore.previousPage"
          :disabled="newsStore.currentPage === 1"
          class="border text-primary px-3 py-2 rounded-md"
        >
          Précédente
        </button>
      </div>
      <div class="text-primary flex justify-center items-center">
        Page {{ newsStore.currentPage }} sur
        {{ newsStore.totalPages }}
      </div>
      <div>
        <button
          @click="newsStore.nextPage"
          :disabled="newsStore.currentPage === newsStore.totalPages"
          class="border text-primary px-3 py-2 rounded-md"
        >
          Prochaine
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import NewsActionsDropdown from "@/components/dropdowns/NewsActionsDropdown.vue";
import checkbox from "@/assets/icons/cards/checkbox.svg";
import noRecords from "@/assets/images/noRecords.svg";
import { useNewsStore } from "@/store/news";
export default {
  async mounted() {
    await this.newsStore.getNews();
  },
  data() {
    return {};
  },
  setup() {
    const newsStore = useNewsStore();
    return {
      checkbox,
      noRecords,
      newsStore,
    };
  },
  components: {
    NewsActionsDropdown,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
