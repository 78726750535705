<template>
  <div class="modal-mask">
    <div class="modal-wrapper rounded-xl">
      <div class="modal-container rounded-xl p-10">
        <div class="float-right -mt-5 -mr-5">
          <button @click="$emit('close')">
            <img :src="exitIcon" alt="exit" />
          </button>
        </div>
        <div class="text-center font-medium text-black my-8">
          <slot name="body">
            Vous voulez vraiment bloquer cet utilisateur?</slot
          >
        </div>
        <div class="flex justify-evenly">
          <button class="btn-outline" @click="$emit('close')">Annuler</button>
          <button class="btn" @click="blockOther()">Bloquer</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import exitIcon from "@/assets/icons/cards/exit.svg";
import { useOthersStore } from "@/store/others";
export default {
  props: ["otherId"],
  setup() {
    const othersStore = useOthersStore();
    return {
      othersStore,
      exitIcon,
    };
  },
  methods: {
    async blockOther() {
      this.$emit("close");
      this.$swal({
        html: "<img width='100' class='mx-auto' height='100' src='/img/loading.a9a09ae4.gif'>",
        buttons: false,
        closeOnClickOutside: false,
        showConfirmButton: false,
      });

      await this.othersStore.blockOther(this.otherId);
      if (this.othersStore.isFail) {
        this.$swal({
          icon: "error",
          title: "quelque chose s'est mal passé",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        this.$swal({
          icon: "success",
          title: `utilisateur bloqué avec succés`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      const dropdowns = document.querySelectorAll(".user-actions");
      // Add click event listener to each dropdown component
      dropdowns.forEach(function (dropdown) {
        dropdowns.forEach(() => {
          dropdown.children[1].classList.add("hidden");
        });
      });
    },
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.8s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
