<template>
  <div class="permissions-filter flex">
    <div
      @click="plansStore.setPlan('player')"
      :class="
        plansStore.selectedPlan == 'player'
          ? 'text-white bg-primary font-semibold'
          : 'text-primary'
      "
      class="cursor-pointer flex justify-center items-center m-1 py-2 mx-2 px-6 rounded-full text-gray-700 border border-primary"
    >
      <div class="text-xl leading-none max-w-full flex-initial">Joueurs</div>
    </div>
    <div
      @click="plansStore.setPlan('agent')"
      :class="
        plansStore.selectedPlan == 'agent'
          ? 'text-white bg-primary font-semibold'
          : 'text-primary'
      "
      class="cursor-pointer flex justify-center items-center m-1 py-2 mx-2 px-6 rounded-full text-gray-700 border border-primary"
    >
      <div class="text-xl leading-none max-w-full flex-initial">Agents</div>
    </div>
    <div
      @click="plansStore.setPlan('other')"
      :class="
        plansStore.selectedPlan == 'other'
          ? 'text-white bg-primary font-semibold'
          : 'text-primary'
      "
      class="cursor-pointer flex justify-center items-center m-1 py-2 mx-2 px-6 rounded-full text-gray-700 border border-primary"
    >
      <div class="text-xl leading-none max-w-full flex-initial">Autres</div>
    </div>
  </div>
</template>

<script>
import { useModeratorsStore } from "@/store/moderators";
import { usePlansStore } from "@/store/plans";

export default {
  setup() {
    const moderatorsStore = useModeratorsStore();
    const plansStore = usePlansStore();
    return { moderatorsStore, plansStore };
  },
};
</script>
