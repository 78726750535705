<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
  >
    <div class="flex justify-between">
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-medium text-lg">Tous les agents</h3>
          </div>
        </div>
      </div>
      <div class="flex">
        <filter-agent-date />
        <filter-agent-nationality />
        <filter-agent-abonmment />
        <filter-agent-sport />
      </div>
    </div>
    <div class="block w-full overflow-x-auto border rounded-lg border-b-0">
      <!-- News table -->
      <table
        v-if="agentsStore.agents.length > 0"
        class="items-center w-full bg-transparent border-collapse"
      >
        <thead>
          <tr class="border-b">
            <th
              class="text-[#999999] h-10 w-10 align-middle py-1 lg:py-3 uppercase whitespace-nowrap font-medium text-left"
            >
              <img
                src="/img/checkbox.e6903039.svg"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <th
              class="text-[#999999] px-6 flex items-center align-middle py-3 uppercase border-l-0 border-b-0 border-r-0 font-medium text-left"
            >
              ID Agent
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Agent
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Nom de <br />
              l’organisme
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Téléphone
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Nationnalité
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Localisation
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Date d’inscription
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Abonnement
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Sport
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Status
            </th>
            <th
              class="text-[#999999] px-6 align-middle border border-solid py-3 uppercase border-l-0 border-r-0 font-medium text-left"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(agent, index) in agentsStore.agents"
            :key="index"
            class="border-b"
          >
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              <img
                src="/img/checkbox.e6903039.svg"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <td class="p-4">#{{ agent.id }}</td>
            <td class="p-4">
              <div class="flex items-center">
                <img
                  v-if="agent.profile_picture"
                  :src="api + agent.profile_picture"
                  alt="person"
                  class="mr-2 rounded-full w-10 h-10"
                />
                <img
                  v-if="!agent.profile_picture"
                  :src="person"
                  alt="person"
                  class="mr-2"
                />
                {{ agent.first_name }} {{ agent.last_name }}
              </div>
            </td>
            <td class="p-4">{{ agent.society }}</td>
            <td class="p-4">{{ agent.phone_number }}</td>
            <td class="p-4">{{ agent.nationality }}</td>
            <td class="p-4">
              <a
                :href="`https://www.google.com/maps?q=${agent.latitude},${agent.longitude}`"
                target="_blank"
                class="font-semibold"
                >Lien</a
              >
            </td>
            <td class="p-4">{{ agent.createdAt.substring(0, 10) }}</td>

            <td class="p-4">{{ agent.planName }}</td>
            <td class="p-4">
              <div
                v-if="agent.sport == 'football'"
                class="text-black bg-[#CEFFE8] text-center px-1 py-1 rounded-md my-2"
              >
                Football
              </div>
              <div
                v-if="agent.sport == 'Basketball'"
                class="text-black bg-[#CEFFE8] text-center px-1 py-1 rounded-md my-2"
              >
                Basketball
              </div>
            </td>
            <td
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              <div
                v-if="!agent.is_blocked"
                class="rounded-xl text-center px-2 py-1 font-medium text-primary border-primary border"
              >
                ACTIF
              </div>
              <div
                v-if="agent.is_blocked"
                class="rounded-xl text-center px-2 py-1 font-medium text-[#C79141] border-[#C79141] border"
              >
                BLOQUÉ
              </div>
            </td>
            <td class="text-right">
              <agents-actions-dropdown
                :is_blocked="agent.is_blocked"
                :agentId="agent.id"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="flex flex-col justify-center items-center py-10">
        <img :src="noRecords" alt="noRecords" class="w-2/6" />
        <div class="text-xl text-[#747373]">Aucun record pour le moment !</div>
      </div>
    </div>
  </div>
</template>
<script>
import AgentsActionsDropdown from "@/components/dropdowns/AgentsActionsDropdown.vue";
import checkbox from "@/assets/icons/cards/checkbox.svg";
import person from "@/assets/icons/cards/person.svg";
import noRecords from "@/assets/images/noRecords.svg";
import FilterAgentNationality from "../dropdowns/filters/agents/FilterAgentNationality.vue";
import FilterAgentAbonmment from "../dropdowns/filters/agents/FilterAgentAbonmment.vue";
import FilterAgentSport from "../dropdowns/filters/agents/FilterAgentSport.vue";
import FilterAgentDate from "../dropdowns/filters/agents/FilterAgentDate.vue";
import { useAgentsStore } from "@/store/agents";

export default {
  components: {
    AgentsActionsDropdown,
    FilterAgentNationality,
    FilterAgentAbonmment,
    FilterAgentSport,
    FilterAgentDate,
  },
  data() {
    return {};
  },
  mounted() {
    this.agentsStore.getAgents();
  },
  setup() {
    const api = process.env.VUE_APP_API_BASE;
    const agentsStore = useAgentsStore();
    return {
      agentsStore,
      checkbox,
      person,
      noRecords,
      api,
    };
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
