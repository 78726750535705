<template>
  <!-- Header -->
  <div
    class="relative bg-[url('@/assets/images/dashboardHeader.png')] md:pt-28 pb-32 pt-12 dashboard-header"
  >
    <div class="mb-4d px-4 md:px-14">
      <h1 class="text-white 2xl:text-6xl text-5xl font-semibold">
        Bienvenue à votre tableau de bord!
      </h1>
      <h1 class="text-white text-3xl font-medium">
        Veuillez voir les statistiques de cette semaine.
      </h1>
    </div>
    <div class="lg:py-8 xl:py-4"></div>
    <div class="px-4 md:px-2 mx-auto w-full">
      <div>
        <!-- Card stats -->
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 xl:my-2 2xl:w-2/12 px-4">
            <card-stats
              :statSubtitle="stats.playerCountCurrentWeek"
              statTitle="Joueurs"
              :statIconColor="getStatIconColor(stats.playerIncreased)"
              :statIconName="stats.playerIncreased ? 'up' : 'down'"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:my-2 2xl:w-2/12 px-4">
            <card-stats
              :statSubtitle="stats.clubCountCurrentWeek"
              statTitle="Clubs"
              :statIconColor="getStatIconColor(stats.clubIncreased)"
              :statIconName="stats.clubIncreased ? 'up' : 'down'"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:my-2 2xl:w-2/12 px-4">
            <card-stats
              :statSubtitle="stats.agentCountCurrentWeek"
              statTitle="Agents"
              :statIconColor="getStatIconColor(stats.agentIncreased)"
              :statIconName="stats.agentIncreased ? 'up' : 'down'"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:my-2 2xl:w-2/12 px-4">
            <card-stats
              :statSubtitle="stats.otherCountCurrentWeek"
              statTitle="Autres"
              :statIconColor="getStatIconColor(stats.otherIncreased)"
              :statIconName="stats.otherIncreased ? 'up' : 'down'"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:my-2 2xl:w-2/12 px-4">
            <card-stats
              :statSubtitle="stats.totalCountCurrentWeek"
              statTitle="Total"
              :statIconColor="getStatIconColor(stats.totalIncreased)"
              :statIconName="stats.totalIncreased ? 'up' : 'down'"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:my-2 2xl:w-2/12 px-4 2xl:px-0">
            <card-stats
              :statSubtitle="stats.totalSubscriptionCount"
              statTitle="Abonnées"
              :statIconColor="getStatIconColor(stats.subscriptionIncreased)"
              :statIconName="stats.subscriptionIncreased ? 'up' : 'down'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import CardStats from "@/components/cards/CardStats.vue";
import { useStatsStore } from "@/store/stats";

export default {
  components: {
    CardStats,
  },
  setup() {
    const statsStore = useStatsStore();

    onMounted(() => {
      // Fetch the stats when the component mounts
      statsStore.fetchStats();
    });

    const getStatIconColor = (increased) => {
      return increased ? "bg-green-100" : "bg-red-100";
    };

    return {
      stats: statsStore.$state,
      getStatIconColor,
    };
  },
};
</script>

<style scoped>
.dashboard-header {
  background-size: contain;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1300px) {
  .dashboard-header {
    background-size: cover;
  }
}
</style>
