<template>
  <div>
    <loader-modal v-if="detectionStore.isLoadingModal" />
    <div class="flex flex-wrap mt-4">
      <div class="w-full px-4">
        <div class="my-6 flex flex-row-reverse mt-24">
          <button
            @click="toggleDetectionEditor"
            class="btn flex items-center cursor-pointer"
            v-if="!detectionStore.isShowEditor"
          >
            <img
              :src="newDetection"
              alt="newArticle"
              class="mr-2 font-medium"
            />
            Nouvelle Détection</button
          ><button
            @click="toggleDetectionEditor"
            v-if="detectionStore.isShowEditor"
            class="btn flex items-center cursor-pointer"
          >
            Retourner au liste
          </button>
        </div>
        <detection-editor v-if="detectionStore.isShowEditor" />
        <detection-table v-if="!detectionStore.isShowEditor" />
      </div>
    </div>
  </div>
</template>
<script>
import DetectionTable from "@/components/tables/DetectionTable.vue";
import newDetection from "@/assets/icons/cards/newArticle.svg";
import DetectionEditor from "@/components/editors/DetectionEditor.vue";
import { useDetectionStore } from "@/store/detection";
import LoaderModal from "@/components/modals/LoaderModal.vue";

export default {
  name: "dashboard-page",
  components: {
    DetectionTable,
    DetectionEditor,
    LoaderModal,
  },
  setup() {
    const detectionStore = useDetectionStore();
    return {
      newDetection,
      detectionStore,
    };
  },

  methods: {
    toggleDetectionEditor() {
      this.detectionStore.isShowEditor = !this.detectionStore.isShowEditor;
    },
  },
};
</script>
