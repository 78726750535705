<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.46002 2.34701C8.74002 0.928015 10.746 0.0150146 13 0.0150146C16.866 0.0150146 20 2.70101 20 6.01501C20 8.00401 18.87 9.76701 17.132 10.859C17.0887 10.8855 17.0447 10.9109 17 10.935V14.985C17 15.1841 16.9406 15.3787 16.8293 15.5438C16.718 15.7089 16.56 15.837 16.3755 15.9118C16.191 15.9866 15.9883 16.0045 15.7935 15.9634C15.5987 15.9223 15.4206 15.824 15.282 15.681L14.142 14.507C15.211 13.243 15.84 11.691 15.84 10.014C15.84 5.94701 12.142 2.61901 7.46002 2.34701Z"
      :fill="color"
    />
    <path
      d="M8.385 15.901L4.718 19.681C4.57942 19.824 4.40132 19.9223 4.20651 19.9634C4.0117 20.0045 3.80905 19.9866 3.62452 19.9118C3.43999 19.837 3.28197 19.7089 3.17071 19.5438C3.05945 19.3787 3.00001 19.1841 3 18.985V14.935C2.95533 14.9109 2.91131 14.8855 2.868 14.859C1.129 13.767 0 12.004 0 10.015C0 6.70101 3.134 4.01501 7 4.01501C10.866 4.01501 14 6.70101 14 10.015C14 12.741 11.879 15.043 8.974 15.773C8.77931 15.8229 8.58284 15.8656 8.385 15.901Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
<style></style>
