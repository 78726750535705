<template>
  <div></div>
</template>
<script>
import { useDetectionStore } from "../../../store/detection";
export default {
  setup() {
    const detectionStore = useDetectionStore();
    const { addError, addSuccess, errorMessage } = useDetectionStore();
    return {
      detectionStore,
      addError,
      addSuccess,
      errorMessage,
    };
  },
  mounted() {
    if (this.detectionStore.addError) {
      this.$swal({
        icon: "error",
        title: this.errorMessage,
        showConfirmButton: true,
        timer: 1500,
        willClose: () => {
          this.detectionStore.addError = false;
          this.detectionStore.addSuccess = false;
        },
      });
    }
  },
};
</script>
