<template>
  <div class="flex flex-wrap mt-4">
    <loader-modal v-if="authStore.isLoading" />
    <edit-profile-success v-if="authStore.isSuccessEdit" />
    <edit-profile-fail v-if="authStore.isFailEdit" />
    <sucess-modal v-if="mods.isSuccess" />
    <fail-modal v-if="mods.isFail" />
    <div class="w-full px-4">
      <div class="mt-24">
        <card-edit-profile />
        <moderators-table />
      </div>
    </div>
  </div>
</template>
<script>
import CardEditProfile from "@/components/cards/CardEditProfile.vue";
import LoaderModal from "@/components/modals/LoaderModal.vue";
import ModeratorsTable from "@/components/tables/ModeratorsTable.vue";
import EditProfileSuccess from "@/components/modals/editProfile/EditProfileSuccess.vue";
import EditProfileFail from "@/components/modals/editProfile/EditProfileFail.vue";
import SucessModal from "@/components/modals/mods/SucessModal.vue";
import FailModal from "@/components/modals/mods/FailModal.vue";

import { useAuthStore } from "@/store/auth";
import { useModeratorsStore } from "@/store/moderators";
export default {
  setup() {
    const authStore = useAuthStore();
    const mods = useModeratorsStore();
    return { authStore, mods };
  },
  components: {
    CardEditProfile,
    ModeratorsTable,
    LoaderModal,
    EditProfileSuccess,
    EditProfileFail,
    SucessModal,
    FailModal,
  },
};
</script>
<style></style>
