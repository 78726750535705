<template>
  <div>
    <div class="flex flex-wrap mt-4" v-if="playersStore.singlePlayer">
      <div class="w-full px-4">
        <div class="my-6 flex flex-row-reverse mt-24"></div>
        <div>
          <h1 class="flex font-medium text-xl">
            <router-link to="/admin/joueurs">Joueurs</router-link>
            <img class="mx-2" :src="separator" alt="separtor" />
            <p>{{
              playersStore.singlePlayer.first_name +
              " " +
              playersStore.singlePlayer.last_name
            }}</p>
          </h1>
          <div>
            <img :src="playersStore.singlePlayer?.profile_picture
                  ? `${api}` + playersStore.singlePlayer?.profile_picture
                  : playerPic
                " alt="playerPic" class="rouneded-lg my-6" width="250" height="250" />
          </div>
          <div class="flex my-6 font-medium text-black player-card py-4 px-2 rounded-md">
            <img :src="sportTitle" alt="sport" class="mx-2" /> Sport :
            <span class="font-normal text-[#000] opacity-40 ml-1">{{ playersStore.singlePlayer.playerProfile.sport
            }}</span>
          </div>
          <div v-if="playersStore.singlePlayer" class="my-6 font-medium text-black player-card py-4 px-2 rounded-md">
            <div class="flex border-b w-fit py-3">
              <img :src="profile" alt="sport" class="mx-2" /> Informations
              personnelles :
            </div>

            <div class="px-2 py-5">
              <p class="my-2">
                Nom complet:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  playersStore.singlePlayer.first_name +
                  " " +
                  playersStore.singlePlayer.last_name
                }}</span>
              </p>
              <p class="my-2">
                Nationnalité:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{ playersStore.singlePlayer.nationality }}</span>
              </p>
              <p class="my-2">
                Localisation:
                <span class="font-normal text-[#000] opacity-40 ml-1"> <a
                    :href="`https://www.google.com/maps?q=${playersStore.singlePlayer.latitude},${playersStore.singlePlayer.longitude}`"
                    target="_blank" class="font-semibold">Lien</a></span>
              </p>
              <p class="my-2">
                Téléphone:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  playersStore.singlePlayer.phone_number
                }}</span>
              </p>
              <p class="my-2">
                Sexe:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  playersStore.singlePlayer.gender == "M" ? "Homme" : "Femme"
                }}</span>
              </p>
              <p class="my-2">
                Date de naissance:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{ playersStore.singlePlayer?.playerProfile.birthday
                }}</span>
              </p>
              <p class="my-2">
                Taille:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{ playersStore.singlePlayer?.playerProfile.height
                }} CM</span>
              </p>
              <p class="my-2">
                Poids:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{ playersStore.singlePlayer?.playerProfile.weight
                }} KG</span>
              </p>
            </div>
          </div>
          <div v-if="playersStore.singlePlayer?.playerProfile"
            class="my-6 font-medium text-black player-card py-4 px-2 rounded-md">
            <div class="flex border-b w-fit py-3">
              <img :src="proInfo" alt="sport" class="mx-2" /> Informations
              professionnelles :
            </div>

            <div class="px-2 py-5">
              <p class="my-2">
                Poste principal:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  playersStore.singlePlayer?.playerProfile.main_position }}</span>
              </p>
              <p class="my-2">
                Poste secondaire:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  playersStore.singlePlayer?.playerProfile.secondary_position }}</span>
              </p>
              <p class="my-2">
                {{ playersStore.singlePlayer?.playerProfile.sport == "football" ? "Pied" : "Main" }} {{
                  playersStore.singlePlayer?.playerProfile.sport == "football" ? "fort" : "forte" }}:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  playersStore.singlePlayer?.playerProfile.strongHandOrFoot }}</span>
              </p>
              <p class="my-2">
                Disponibilité:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  playersStore.singlePlayer?.playerProfile.availability
                  == "immediate" ? "Oui" : "Non" }}</span>
              </p>
              <p class="my-2">
                Agent:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{ playersStore.singlePlayer?.playerProfile.agent ?
                  "Oui" : "Non" }}</span>
              </p>
            </div>
          </div>

          <div v-if="playersStore.singlePlayer?.playerCareer"
            class="my-6 font-medium text-black player-card py-4 px-2 rounded-md">
            <div class="flex border-b w-fit py-3">
              <img :src="proInfo" alt="sport" class="mx-2" />Carrière :
            </div>

            <div class="px-2 py-5">
              <p class="my-2">
                Poste joué:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  playersStore.singlePlayer.playerCareer.position
                }}</span>
              </p>
              <p class="my-2">
                Club:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  playersStore.singlePlayer.playerCareer.club
                }}</span>
              </p>
              <p class="my-2">
                Date début:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  playersStore.singlePlayer.playerCareer.start_date
                }}</span>
              </p>
              <p class="my-2">
                Date fin:
                <span class="font-normal text-[#000] opacity-40 ml-1">{{
                  playersStore.singlePlayer.playerCareer.end_date
                }}</span>
              </p>


              <p class="my-2">
                Experience:
              <ul>
                <li class="my-1" v-for="exp in playersStore.singlePlayer.playerCareer.experience" :key="exp">
                  • {{ exp }}
                </li>
              </ul>
              </p>
            </div>
          </div>
        </div>
        <div><button class="btn my-4 mb-10" @click="handleContact">Contacter</button></div>
      </div>
    </div>
  </div>
</template>
<script>
import cv from "@/assets/icons/cards/cv.svg";
import career from "@/assets/icons/cards/career.svg";
import proInfo from "@/assets/icons/cards/proInfo.svg";
import profile from "@/assets/icons/cards/profile.svg";
import sportTitle from "@/assets/icons/cards/sportTitle.svg";
import separator from "@/assets/icons/cards/separator.svg";
import pdf from "@/assets/icons/cards/pdf.svg";
import playerPic from "@/assets/images/player.png";
import { usePlayersStore } from "@/store/players";
import { useChatStore } from "@/store/chat";
import router from "@/router";
export default {
  name: "dashboard-page",
  async mounted() {
    await this.playersStore.getOnePlayer(this.$route.params.id);
  },
  methods: {
    async handleContact() {
      await this.chatStore.getChats();
      const result = this.chatStore.chats.find((chat) => { return chat.receiver.id == this.playersStore.singlePlayer.id && chat.receiverRole == "player" })
      if (result) {
        this.chatStore.setCurrentChat({
          ...result
        });
      } else {
        await this.chatStore.createChat(this.playersStore.singlePlayer.id, "player")
        this.chatStore.setCurrentChat(
          this.chatStore.newChat
        );
      }
      router.push("/admin/messagerie")
    }
  },
  setup() {
    const playersStore = usePlayersStore();
    const chatStore = useChatStore();
    const api = process.env.VUE_APP_API_BASE;
    return {
      proInfo,
      cv,
      career,
      profile,
      sportTitle,
      pdf,
      playerPic,
      separator,
      playersStore,
      api,
      chatStore
    };
  },
  data() {
    return {};
  },


};
</script>
<style>
.player-card {
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 1px 15px rgba(0, 0, 0, 0.1));
}
</style>
