<template>
  <div class="timeFilter-filter">
    <a
      class="text-blueGray-500 block"
      href="#"
      v-on:click="toggleDropdown($event)"
    >
      <button
        ref="btnDropdownRef"
        class="flex justify-between items-center text-gray-700 px-2 leading-tight focus:outline-none rounded-md w-full py-3"
      >
        <p class="mx-4">{{ statsStore.selectedTime.name }}</p>
        <img
          :src="filterModClosed"
          v-if="!dropdownPopoverShow"
          alt="filterModClosed"
          class="mr-2"
        />
        <img
          :src="filterModOpen"
          v-if="dropdownPopoverShow"
          alt="filterModOpen"
          class="mr-2"
        />
      </button>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left list-none text-left rounded shadow-lg min-w-[100px] w-full"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <div
        @click="togglePermission(index)"
        v-for="(permission, index) in timeFilter"
        :key="index"
        :class="permission.isFilter ? 'bg-blue-100' : ''"
        class="px-2 border-b py-3 cursor-pointer flex items-center"
      >
        <img
          v-if="permission.isFilter"
          :src="permissionCheck"
          class="mr-2"
          alt="permissionCheck"
        />
        {{ permission.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import filterModClosed from "@/assets/icons/cards/filterModClosed.svg";
import filterModOpen from "@/assets/icons/cards/filterModOpen.svg";
import permissionCheck from "@/assets/icons/cards/permissionCheck.svg";
import { useStatsStore } from "@/store/stats";
export default {
  data() {
    return {
      dropdownPopoverShow: false,
      timeFilter: [
        { name: "Cette semaine", isFilter: true, value: 1 },
        { name: "Ce mois", isFilter: false, value: 2 },
        { name: "Cette Année", isFilter: false, value: 3 },
      ],
      popperInstance: null, // Store the Popper instance
    };
  },
  setup() {
    const statsStore = useStatsStore();
    return { statsStore, filterModOpen, filterModClosed, permissionCheck };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      this.$nextTick(() => {
        if (this.dropdownPopoverShow) {
          this.dropdownPopoverShow = false;
          // Destroy the existing Popper instance (if it exists)
          if (this.popperInstance) {
            this.popperInstance.destroy();
            this.popperInstance = null;
          }
        } else {
          const dropdowns = document.querySelectorAll(".career-filter");
          // Add click event listener to each dropdown component
          dropdowns.forEach(function (dropdown) {
            dropdowns.forEach(() => {
              dropdown.children[1].classList.add("hidden");
            });
          });
          this.$refs.popoverDropdownRef.classList.remove("hidden");

          this.dropdownPopoverShow = true;

          // Create a new Popper instance and store it
          this.popperInstance = createPopper(
            this.$refs.popoverDropdownRef,
            this.$refs.btnDropdownRef,
            {
              placement: "bottom-end",
            }
          );
        }
      });
    },
    async togglePermission(index) {
      this.timeFilter[index].isFilter = true;
      this.statsStore.selectedTime = this.timeFilter[index];
      await this.statsStore.getRevenueByTime();

      const filteredTime = this.timeFilter.filter((item, itemIndex) => {
        return index != itemIndex;
      });
      filteredTime.map((item) => {
        item.isFilter = false;
      });
      this.dropdownPopoverShow = false;
      // Destroy the Popper instance when toggling permissions
      if (this.popperInstance) {
        this.popperInstance.destroy();
        this.popperInstance = null;
      }
    },
  },
};
</script>
