<template>
  <!-- Header -->
  <div class="relative md:pt-24 mb-20 pt-10 dashboard-header">
    <div class="lg:py-8 xl:py-4"></div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>
