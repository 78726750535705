<template>
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.75C12.7625 0.75 15 2.9875 15 5.75C15 8.5125 12.7625 10.75 10 10.75C7.2375 10.75 5 8.5125 5 5.75C5 2.9875 7.2375 0.75 10 0.75ZM16.2612 14.3575C15.6423 14.1099 14.9935 14.5927 14.9045 15.2535C14.751 16.3938 14.3743 17.9374 13.5155 19.6693C13.1167 20.4734 12.0037 20.2442 11.8552 19.359L11.25 15.75L11.7848 14.6803C12.0957 14.0587 11.693 13.3156 10.999 13.278C10.6706 13.2601 10.3373 13.25 10 13.25C9.66267 13.25 9.3294 13.2601 9.00101 13.278C8.30702 13.3156 7.90434 14.0587 8.21515 14.6803L8.75 15.75L8.14477 19.359C7.99631 20.2442 6.88326 20.4734 6.4845 19.6693C5.62612 17.9381 5.24936 16.3952 5.09568 15.255C5.00658 14.5938 4.35695 14.1109 3.73784 14.3595C1.45954 15.274 0 16.6532 0 18.25V21.25C0 22.3546 0.895431 23.25 2 23.25H18C19.1046 23.25 20 22.3546 20 21.25V18.25C20 16.6521 18.5474 15.272 16.2612 14.3575Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
};
</script>
<style></style>
