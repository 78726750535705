<template>
  <div class="max-w-[1728px] lg:mx-auto">
    <side-bar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <header-stats v-if="$route.path == '/admin/dashboard'" />
      <header-stats-news v-if="$route.path == '/admin/news'" />
      <div
        class="px-4 md:px-5 mx-auto w-full"
        :class="
          $route.path == '/admin/dashboard' || $route.path == '/admin/news'
            ? '-m-24'
            : ''
        "
      >
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/navbars/AdminNavbar.vue";
import SideBar from "@/components/sidebars/SideBar.vue";
import HeaderStats from "@/components/headers/HeaderStats.vue";
import HeaderStatsNews from "@/components/headers/HeaderStatsNews.vue";
export default {
  name: "admin-layout",
  components: {
    AdminNavbar,
    SideBar,
    HeaderStats,
    HeaderStatsNews,
  },
};
</script>
