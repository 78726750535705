<template>
  <div class="user-actions">
    <BlockUserModal
      :userId="userId"
      :userRole="userRole"
      v-if="showBlockModal"
      @close="showBlockModal = false"
    >
    </BlockUserModal>
    <UnBlockUserModal
      :userId="userId"
      :userRole="userRole"
      v-if="showUnblockModal"
      @close="showUnblockModal = false"
    >
    </UnBlockUserModal>
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex justify-center">
        <img :src="actions" alt="actions" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
        :to="`/admin/${userRole}s/${userId}`"
      >
        <img :src="consult" class="mr-2" alt="block" />Consulter</router-link
      >
      <a
        href="javascript:void(0);"
        v-if="!isBlocked"
        @click="showBlockModal = true"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="block" class="mr-2" alt="block" />

        Bloquer </a
      ><a
        href="javascript:void(0);"
        v-if="isBlocked"
        @click="showUnblockModal = true"
        class="text-sm py-2 px-4 font-normal flex w-full items-center hover:bg-slate-200"
      >
        <img :src="block" class="mr-2" alt="block" />

        Débloquer
      </a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import actions from "@/assets/icons/cards/threeDots.svg";
import consult from "@/assets/icons/cards/consult.svg";
import block from "@/assets/icons/cards/block.svg";
import BlockUserModal from "../modals/stats/BlockUserModal.vue";
import UnBlockUserModal from "../modals/stats/UnBlockUserModal.vue";

export default {
  props: ["isBlocked", "userId", "userRole"],
  components: { BlockUserModal, UnBlockUserModal },
  data() {
    return {
      dropdownPopoverShow: false,
      showBlockModal: false,
      showUnblockModal: false,
    };
  },
  setup() {
    return {
      actions,
      block,
      consult,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        const dropdowns = document.querySelectorAll(".user-actions");
        // Add click event listener to each dropdown component
        dropdowns.forEach(function (dropdown) {
          dropdowns.forEach(() => {
            dropdown.children[1].classList.add("hidden");
          });
        });
        this.$refs.popoverDropdownRef.classList.remove("hidden");

        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
