<template>
  <div></div>
</template>
<script>
import router from "@/router";
import { useDetectionStore } from "../../../store/detection";
export default {
  setup() {
    const detectionStore = useDetectionStore();
    return { detectionStore };
  },
  mounted() {
    if (this.detectionStore.isUpdateError) {
      this.$swal({
        icon: "error",
        title: "Quelque chose s'est mal passé",
        showConfirmButton: false,
        timer: 1500,
        willClose: () => {
          this.detectionStore.isUpdateSuccess = false;
          this.detectionStore.isUpdateError = false;
        },
      });
      setTimeout(() => {
        router.push("/admin/detection");
      }, 1600);
    }
  },
};
</script>
