<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-medium text-lg">Nouveaux utilisateurs</h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Clients table -->
      <table
        v-if="statsStore.lastestUsers.length > 0"
        class="items-center w-full bg-transparent border-collapse"
      >
        <thead>
          <tr class="border-b">
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              <img
                :src="checkbox"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <th
              class="px-6 text-[#999999] flex items-center align-middle border border-solid py-3 uppercase border-l-0 border-b-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              ID Utilisateur
            </th>
            <th
              class="px-6 text-[#999999] align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Utilisateur
            </th>
            <th
              class="px-6 text-[#999999] align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Téléphone
            </th>
            <th
              class="px-6 text-[#999999] align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Nationnalité
            </th>
            <th
              class="px-6 text-[#999999] align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Date d’inscription
            </th>
            <th
              class="px-6 text-[#999999] align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Abonnement
            </th>
            <th
              class="px-6 text-[#999999] align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Fonction
            </th>
            <th
              class="px-6 text-[#999999] align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Status
            </th>
            <th
              class="px-6 text-[#999999] align-middle border border-solid py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in statsStore.lastestUsers"
            :key="index"
            class="border-b"
          >
            <!-- ... table row content ... -->
            <th
              class="text-[#999999] h-10 w-10 align-middle border border-solid py-1 lg:py-3 uppercase border-l-0 border-r-0 whitespace-nowrap font-medium text-left"
            >
              <img
                :src="checkbox"
                class="float-right"
                height="20"
                width="20"
                alt="checkbox"
              />
              <p class="invisible">icon</p>
            </th>
            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ user.data.id }}
              <!-- Assuming id is part of the user object -->
            </td>
            <td
              class="px-2 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 break-words whitespace-normal"
            >
              <div class="flex items-center break-words">
                <img
                  v-if="user.data.profile_picture"
                  :src="api + user.data.profile_picture"
                  alt="person"
                  class="mr-2 rounded-full w-10 h-10"
                />
                <img
                  v-if="!user.data.profile_picture"
                  :src="person"
                  alt="person"
                  class="mr-2"
                />
                {{ user.data.first_name }} {{ user.data.last_name }}
              </div>
            </td>
            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ user.data.phone_number }}
            </td>
            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ user.data.nationality }}
            </td>
            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ user.data.createdAt.substring(0, 10) }}
              <!-- Assuming createdAt is the date format -->
            </td>
            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ user.data?.plans ? user.data?.plans[0].type : "free" }}
            </td>
            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              {{ user.role == "player" ? "Joueur" : null }}
              {{ user.role == "agent" ? "Agent" : null }}
              {{ user.role == "club" ? "Club" : null }}
              {{ user.role == "other" ? "Autre" : null }}
            </td>
            <td
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
            >
              <div
                v-if="!user.data.is_blocked"
                class="rounded-xl text-center px-2 py-1 font-medium text-primary border-primary border"
              >
                ACTIF
              </div>
              <div
                v-if="user.data.is_blocked"
                class="rounded-xl text-center px-2 py-1 font-medium text-[#C79141] border-[#C79141] border"
              >
                BLOQUÉ
              </div>
            </td>

            <td
              class="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-4 text-right"
            >
              <dashboard-actions-dropdown
                :userId="user.data.id"
                :isBlocked="user.data.is_blocked"
                :userRole="user.role == 'player' ? 'joueur' : user.role"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div v-else class="flex flex-col justify-center items-center py-10">
        <img :src="noRecords" alt="noRecords" class="w-2/6" />
        <div class="text-xl text-[#747373]">Aucun record pour le moment !</div>
      </div>
    </div>
    <div class="flex justify-around w-full py-6">
      <div>
        <button
          @click="statsStore.getLastestUsers(statsStore.currentPage - 1)"
          :disabled="statsStore.currentPage === 1"
          class="border text-primary px-3 py-2 rounded-md"
        >
          Précédente
        </button>
      </div>
      <div class="text-primary flex justify-center items-center">
        Page {{ statsStore.currentPage }} sur {{ statsStore.totalPages }}
      </div>
      <div>
        <button
          @click="statsStore.getLastestUsers(statsStore.currentPage + 1)"
          :disabled="statsStore.currentPage === statsStore.totalPages"
          class="border text-primary px-3 py-2 rounded-md"
        >
          Prochaine
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardActionsDropdown from "@/components/dropdowns/DashboardActionsDropdown.vue";
import checkbox from "@/assets/icons/cards/checkbox.svg";
import person from "@/assets/icons/cards/person.svg";
import noRecords from "@/assets/images/noRecords.svg";
import { useStatsStore } from "@/store/stats";
export default {
  async mounted() {
    await this.statsStore.getLastestUsers(this.statsStore.currentPage);
    console.log(this.statsStore.lastestUsers);
  },

  setup() {
    const statsStore = useStatsStore();
    const api = process.env.VUE_APP_API_BASE;
    return {
      checkbox,
      noRecords,
      person,
      statsStore,
      api,
    };
  },
  components: {
    DashboardActionsDropdown,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
