<template>
  <div class="md:flex flex-row h-screen antialiased text-gray-800">
    <div class="flex flex-col h-full w-full bg-white md:px-4 py-6">
      <PrivateDisscussion />
    </div>
    <div class="flex flex-row w-96 flex-shrink-0">
      <MessagesList />
    </div>
  </div>
</template>
<script>
import { useChatStore } from "@/store/chat";
import MessagesList from "./MessagesList.vue";
import PrivateDisscussion from "./PrivateDisscussion.vue";
export default {
  components: { PrivateDisscussion, MessagesList },

  setup() {
    const chatStore = useChatStore();
    return {
      chatStore,
    };
  },
};
</script>
<style></style>
